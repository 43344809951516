import { Box, Checkbox, FormControlLabel, List, Typography } from "@material-ui/core";
import { FC } from "react";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { useStyles } from "../styles";
import { defaultNavLinkList } from "constants/defaultValues";
import { setNavLinks, setNavSubLinks } from "actions/settingsAction";
import { useDispatch } from "react-redux";
import { OnChangeEvent } from "types/general";

interface PropsType {}

const NavigationMenuSettings: FC<PropsType> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userData } = useTypeSelectors((state) => state.auth);
  const { activeNavLinkList } = useTypeSelectors((state) => state.settings);

  return (
    <List className={classes.list} component="div" disablePadding>
      {defaultNavLinkList
        ?.filter((iten) => iten.permissionKey.includes(userData.role))
        ?.map((link) => (
          <Box>
            <FormControlLabel
              key={link.id}
              onChange={(e: OnChangeEvent) => dispatch(setNavLinks(link.id, e.target.checked, link))}
              checked={activeNavLinkList?.some((linkData) => linkData.id === link.id)}
              control={<Checkbox />}
              label={<Typography style={{ marginRight: "10px" }}>{link.name}</Typography>}
            />
            <Box className={classes.subList}>
              {link?.subList
                ?.filter((iten) => iten.permissionKey.includes(userData.role))
                ?.map((subLink) => (
                  <FormControlLabel
                    key={subLink.id}
                    onChange={(e: OnChangeEvent) =>
                      dispatch(setNavSubLinks(link.id, subLink.id, e.target.checked, subLink))
                    }
                    checked={activeNavLinkList?.some((someParentLink) =>
                      someParentLink.subList?.some((item) => item.id === subLink.id),
                    )}
                    control={<Checkbox />}
                    label={<Typography style={{ marginRight: "10px" }}>{subLink.name}</Typography>}
                  />
                ))}
            </Box>
          </Box>
        ))}
    </List>
  );
};

export default NavigationMenuSettings;
