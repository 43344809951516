import { FC, useState } from "react";
import Menu from "@material-ui/icons/Menu";
import { IconButton } from "@material-ui/core";
import { useStyles } from "./styles";
import { MobileMenuDrawer } from "components/drawers/MobileMenuDrawer";

interface MobileBurgerButtonProps {}

export const MobileBurgerButton: FC<MobileBurgerButtonProps> = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton className={classes.menuButton} onClick={() => setOpen(!open)}>
        <Menu />
      </IconButton>
      <MobileMenuDrawer isOpen={open} setIsOpen={() => setOpen(!open)} />
    </>
  );
};
