export const FLEET_UTIL_TABLE_HEADERS_COLUMN = [
  { field: "small", id: "van_number", label: "Van Number", colSpan: 1 },
  { field: "small", id: "ownership", label: "Ownership", colSpan: 1 },
  { field: "small", id: "counter", label: "Counter", colSpan: 1 },
];

export const HIGH_SHIFT_TABLE_HEADERS_COLUMN = [
  { field: "small", id: "driver", label: "Driver", colSpan: 1 },
  { field: "small", id: "full_name", label: "Full Name", colSpan: 1 },
  { field: "small", id: "counter", label: "Counter", colSpan: 1 },
];

export const RABBIT_REPORTS_LIST = [
  { field: "small", id: "serial_number", label: "Rabbit Serial Number", colSpan: 1 },
  { field: "small", id: "count", label: "Count", colSpan: 1 },
]

export const DOLLY_REPORTS_LIST = [
  { field: "small", id: "serial_number", label: "Rabbit Serial Number", colSpan: 1 },
  { field: "small", id: "count", label: "Count", colSpan: 1 },
]
