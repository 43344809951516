import { Box, TablePagination } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { useStyles } from "./styles";
import { useEffect } from "react";
import { getAllFleetsData, setFleetFilters } from "actions/daAndFleetActions";
import { ModalWindow } from "components/templateComponents/modalWindow";
import { ChangeFleetStatusDialog } from "components/modals/modalContent/ChangeDaFleetStatusDialog";

export const FleetHead = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { fleetData } = useTypeSelectors((state) => state.daAndFleets);

  useEffect(() => {
    dispatch(
      getAllFleetsData(
        fleetData?.fleetListFilters?.pageNumber + 1,
        fleetData?.fleetListFilters?.rowsPerPage,
        fleetData?.fleetListFilters?.ordering,
      ),
    );
  }, [
    fleetData?.fleetListFilters?.rowsPerPage,
    fleetData?.fleetListFilters?.pageNumber,
    fleetData?.fleetListFilters?.ordering,
    dispatch,
  ]);

  const returnFleetName = (id: number) => {
    const van = fleetData?.fleetList?.find((item) => item.id === id);
    return ` ${van?.number}`;
  };

  const handleChangePagination = (event: unknown, value: number) => {
    dispatch(setFleetFilters({ pageNumber: Number(value) }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setFleetFilters({ rowsPerPage: Number(event.target.value) }));
  };

  return <Box className={classes.pageHead}>
    <Box className={classes.headBox}>
        <ModalWindow
          modal_name="fleet-status-change-modal"
          modalTitle="Change Fleet status"
          modalDescription={`Status will be changed for the following fleets: ${fleetData?.idListForUpdate?.map((fleetId) => returnFleetName(fleetId))}`}
          isDisabled={!fleetData?.idListForUpdate?.length}
        >
          <ChangeFleetStatusDialog />
        </ModalWindow>
      </Box>
      <TablePagination
        page={fleetData?.fleetListFilters?.pageNumber}
        rowsPerPage={fleetData?.fleetListFilters?.rowsPerPage}
        count={fleetData?.fleetListCount}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePagination}
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
      />
  </Box>;
};
