import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { EMPTY } from "constants/constants";
import { DA_TABLE_HEADERS_COLUMN } from "./tableData";
import { DaTableRow } from "./DaTableRow";
import { TableSortHead } from "components/tables/tableSortHead";
import { setDaFilters, setDaTablePagginationPage } from "actions/daAndFleetActions";
import { DriverData } from "types/DaAndFleet";

export const DaListTable = () => {
  const classes = useStyles();
  const { driverData } = useTypeSelectors((state) => state.daAndFleets);

  return (
    <Box className={classes.tableRoot}>
      {!driverData.driverList.length ? (
        <Typography variant="h1" className={classes.emptyTable}>
          {EMPTY}
        </Typography>
      ) : (
        <>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table stickyHeader>
              <TableSortHead 
                tableHeadersList={DA_TABLE_HEADERS_COLUMN}
                pagginationAction={setDaTablePagginationPage}
                sortAction={setDaFilters} 
                filters={driverData?.driverListFilters}
              />

              <TableBody>
                  {driverData?.driverList
                    ?.map((el:DriverData, index:number) => (
                      <DaTableRow 
                        key={`${index}${el.id}`}
                        daData={el}
                      />
                    ))
                  }
                </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};
