export const TABLE_SIDE_ROWS = [
  { id: "standard-cargo", label: "Standard Cargo - 10 Hrs", role: 0, vans: [1,5], hour: null},
  { id: "flex-cargo-9", label: "Flex Cargo - 9 Hrs", role: 8, vans: [1,5], hour: 9},
  { id: "flex-cargo-8", label: "Flex Cargo - 8 Hrs", role: 8, vans: [1,5], hour: 8},
  { id: "flex-cargo-7", label: "Flex Cargo - 7 Hrs", role: 8, vans: [1,5], hour: 7},
  { id: "flex-cargo-6", label: "Flex Cargo - 6 Hrs", role: 8, vans: [1,5], hour: 6},
  { id: "flex-cargo-5", label: "Flex Cargo - 5 Hrs", role: 8, vans: [1,5], hour: 5},
  { id: "flex-cargo-4", label: "Flex Cargo - 4 Hrs", role: 8, vans: [1,5], hour: 4},
  { id: "flex-cargo-3", label: "Flex Cargo - 3 Hrs", role: 8, vans: [1,5], hour: 3},
  { id: "flex-cargo-2", label: "Flex Cargo - 2 Hrs", role: 8, vans: [1,5], hour: 2},
  { id: "flex-cargo-1", label: "Flex Cargo - 1 Hrs", role: 8, vans: [1,5], hour: 1},

  { id: "standard-large", label: "Standard Large - 10 Hrs", role: 0, vans: [2], hour: null},
  { id: "flex-large-9", label: "Flex Large - 9 Hrs", role: 8, vans: [2], hour: 9},
  { id: "flex-large-8", label: "Flex Large - 8 Hrs", role: 8, vans: [2], hour: 8},
  { id: "flex-large-7", label: "Flex Large - 7 Hrs", role: 8, vans: [2], hour: 7},
  { id: "flex-large-6", label: "Flex Large - 6 Hrs", role: 8, vans: [2], hour: 6},
  { id: "flex-large-5", label: "Flex Large - 5 Hrs", role: 8, vans: [2], hour: 5},
  { id: "flex-large-4", label: "Flex Large - 4 Hrs", role: 8, vans: [2], hour: 4},
  { id: "flex-large-3", label: "Flex Large - 3 Hrs", role: 8, vans: [2], hour: 3},
  { id: "flex-large-2", label: "Flex Large - 2 Hrs", role: 8, vans: [2], hour: 2},
  { id: "flex-large-1", label: "Flex Large - 1 Hr", role: 8, vans: [2], hour: 1},

  { id: "standard-cube", label: "Standard Cube - 10 Hrs", role: 0, vans: [3], hour: null},
  { id: "flex-cube-9", label: "Flex Cube - 9 Hrs", role: 8, vans: [3], hour: 9},
  { id: "flex-cube-8", label: "Flex Cube - 8 Hrs", role: 8, vans: [3], hour: 8},
  { id: "flex-cube-7", label: "Flex Cube - 7 Hrs", role: 8, vans: [3], hour: 7},
  { id: "flex-cube-6", label: "Flex Cube - 6 Hrs", role: 8, vans: [3], hour: 6},
  { id: "flex-cube-5", label: "Flex Cube - 5 Hrs", role: 8, vans: [3], hour: 5},
  { id: "flex-cube-4", label: "Flex Cube - 4 Hrs", role: 8, vans: [3], hour: 4},
  { id: "flex-cube-3", label: "Flex Cube - 3 Hrs", role: 8, vans: [3], hour: 3},
  { id: "flex-cube-2", label: "Flex Cube - 2 Hrs", role: 8, vans: [3], hour: 2},
  { id: "flex-cube-1", label: "Flex Cube - 1 Hr", role: 8, vans: [3], hour: 1},

  { id: "standard-medium", label: "StandardMediume - 10 Hrs", role: 0, vans: [6], hour: null},
  { id: "flex-medium-9", label: "Flex Medium - 9 Hrs", role: 8, vans: [6], hour: 9},
  { id: "flex-medium-8", label: "Flex Medium - 8 Hrs", role: 8, vans: [6], hour: 8},
  { id: "flex-medium-7", label: "Flex Medium - 7 Hrs", role: 8, vans: [6], hour: 7},
  { id: "flex-medium-6", label: "Flex Medium - 6 Hrs", role: 8, vans: [6], hour: 6},
  { id: "flex-medium-5", label: "Flex Medium - 5 Hrs", role: 8, vans: [6], hour: 5},
  { id: "flex-medium-4", label: "Flex Medium - 4 Hrs", role: 8, vans: [6], hour: 4},
  { id: "flex-medium-3", label: "Flex Medium - 3 Hrs", role: 8, vans: [6], hour: 3},
  { id: "flex-medium-2", label: "Flex Medium - 2 Hrs", role: 8, vans: [6], hour: 2},
  { id: "flex-medium-1", label: "Flex Medium - 1 Hr", role: 8, vans: [6], hour: 1},
  
  { id: "planned-routes", label: "Planned Routes", role: null, vans: [], hour: null},
  { id: "actual-routes", label: " Actual Routes", roles: [0, 8], vans: [1,2,5,6], hour: null},
  { id: "difference-routes", label: "% Difference Routes ", roles: [0, 8], vans: [], hour: null},

  { id: "da-training", label: "DA Training", role: 4, vans: [1,2,5,6, null], hour: null},
  { id: "cargo-cancelled", label: "Standard Cargo - Cancellation", role: 7, vans: [1,5], hour: null},
  { id: "medium-cancelled", label: "Standard Medium - Cancellation", role: 7, vans: [6], hour: null},
  { id: "cube-cancelled", label: "Standard Cube - Cancellation", role: 7, vans: [3], hour: null},
  { id: "large-cancelled", label: "Standard Large - Cancellation", role: 7, vans: [2], hour: null},
];