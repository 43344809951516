import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "constants/constants";

export const useStyles = makeStyles((theme) => ({
  buttonClass: {
    fontSize: 15,
    padding: theme.spacing(1, 3),
  },
  badge: {
    "& .MuiBadge-badge": {
      top: 5,
      right: 5,
    }
  },
  actionButton: {
    padding: theme.spacing(1.5, 2),
    borderRadius: 100,
    width: "50%",
    fontSize: 16,
  },
  menuButton: {
    color: Colors.WHITE,
    display: "none"
  },
  "@media (max-width: 750px)": {
    menuButton: {
      display: "block"
    },
    },
}));
