import { FC, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Box, Typography, Avatar, TextField, Paper, Grid } from "@material-ui/core";
import { InfoBox } from "components/elements/InfoBox";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { UserDataRequestEdit } from "actions/authAction";
import { UserData } from "types/User";
import { useStyles } from "./style";
import moment from "moment";
import Person from "@material-ui/icons/Person"

interface PropsType {}

const Account: FC<PropsType> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userData } = useTypeSelectors((state) => state.auth);

  const {
    date_joined,
    first_name,
    last_login,
    last_name,
    knet_user,
    transporter_id,
    training_delivered,
    email,
  } = userData;

  const { agencies, fleet_groups } = getStationInfo(userData);

  const setInitialData = (userData: UserData) => {
    setData({
      first_name: userData?.first_name || "",
      last_name: userData?.last_name || "",
      personal_email: userData?.personal_email || "",
      mobile_number: userData?.mobile_number || "",
      whatsapp_mobile_number: userData?.whatsapp_mobile_number || "",
      driver_license: userData?.driver_license || "",
      date_of_birth: userData?.date_of_birth || "",
      driver_license_expiry: userData?.driver_license_expiry || "",
    });
  };

  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    date_of_birth: "",
    personal_email: "",
    whatsapp_mobile_number: "",
    driver_license: "",
    driver_license_expiry: "",
  });
  const inputValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submit = () => dispatch(UserDataRequestEdit(data));

  useEffect(() => {
    setInitialData(userData);
  }, [userData]);

  return (
    <Box data-testid="testAccount" className={classes.root}>
      <Typography className={classes.pageTitle} variant="h3" color="primary">
        Your Profile information
      </Typography>

      <Grid className={classes.container} container spacing={3}>
        <Grid item sm={12} lg={6} xl={4}>
          <Paper className={classes.infoBox}>
            <Box className={classes.accountInfoBox}>
              <Avatar className={classes.avatar}>
                <Person className={classes.avatarIcon}/>
              </Avatar>
              <Box className={classes.account}>
                <Typography variant="h5">
                  {first_name} {last_name}
                </Typography>
                <Typography variant="h6">{userData.role_name}</Typography>
                <Typography variant="body2">
                  Last login was {moment(last_login).format("LT, DD.MM.YYYY")}
                </Typography>
              </Box>
            </Box>
            <InfoBox label={"MYCU email"} value={email} />
            <InfoBox label={"Date joined"} value={moment(date_joined).format("MMMM Do, yyyy")} />
            <InfoBox label={"Transporter ID"} value={transporter_id} />
            <InfoBox label={"Knet User"} value={knet_user} />
            <InfoBox label={"Fleet group"} value={fleet_groups} />
            <InfoBox label={"Agency name"} value={agencies} />
            <InfoBox label={"Training delivered"} value={training_delivered ? "Yes" : "No"} />
          </Paper>
        </Grid>
        <Grid item sm={12} lg={6} xl={4}>
          <Paper className={classes.infoBox}>
            <Box className={classes.tableBody}>
              <Box className={classes.dataBoxString}>
                <TextField
                  label="First name"
                  name="first_name"
                  className={classes.textFIeld}
                  value={data.first_name}
                  onChange={inputValueChange}
                />
              </Box>
              <Box className={classes.dataBoxString}>
                <TextField
                  label="Last name"
                  name="last_name"
                  value={data.last_name}
                  className={classes.textFIeld}
                  onChange={inputValueChange}
                />
              </Box>
              <Box className={classes.dataBoxString}>
                <TextField
                  label="Mobile number"
                  name="mobile_number"
                  value={data.mobile_number}
                  className={classes.textFIeld}
                  onChange={inputValueChange}
                />
              </Box>
              <Box className={classes.dataBoxString}>
                <TextField
                  label="Date of birth"
                  type="date"
                  name="date_of_birth"
                  value={data.date_of_birth}
                  className={classes.textFIeld}
                  onChange={inputValueChange}
                />
              </Box>
              <Box className={classes.dataBoxString}>
                <TextField
                  label="Personal email"
                  name="personal_email"
                  value={data.personal_email}
                  className={classes.textFIeld}
                  onChange={inputValueChange}
                />
              </Box>
              <Box className={classes.dataBoxString}>
                <TextField
                  label="Whatsapp number"
                  name="whatsapp_mobile_number"
                  value={data.whatsapp_mobile_number}
                  className={classes.textFIeld}
                  onChange={inputValueChange}
                />
              </Box>
              <Box className={classes.dataBoxString}>
                <TextField
                  label="Driver license"
                  name="driver_license"
                  value={data.driver_license}
                  className={classes.textFIeld}
                  onChange={inputValueChange}
                />
              </Box>
              <Box className={classes.dataBoxString}>
                <TextField
                  label="Driver license expiry"
                  type="date"
                  name="driver_license_expiry"
                  defaultValue={data.driver_license_expiry}
                  value={data.driver_license_expiry}
                  className={classes.textFIeld}
                  onChange={inputValueChange}
                />
              </Box>
              <Box className={classes.buttonBox}>
                <Button
                  className={classes.button}
                  onClick={submit}
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Account;

const getStationInfo = (user: UserData) => {
  const agencies = new Set();
  const fleet_groups = new Set();
  if (user.station_spots.length) {
    user.station_spots.forEach((station) => {
      agencies.add(station.agency.name);
      station.fleet_groups?.forEach((group) => {
        fleet_groups.add(group?.name);
      });
    });
  }
  return {
    agencies: Array.from(agencies).join(", "),
    fleet_groups: Array.from(fleet_groups).join(", "),
  };
};
