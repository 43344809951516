import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  titleBox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "10px",
    boxSizing: "border-box",
  },
  titleSettings: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  filterBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    padding: theme.spacing(2),
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  button: {
    margin: theme.spacing(0.5),
    marginLeft: "0px",
    background: "#fff",
    textTransform: "none",
  },
  buttonActive: {
    background: "#205885",
    margin: theme.spacing(0.5),
    marginLeft: "0px",
    color: "#fff",
    textTransform: "none",
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  mainChartBox: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    boxSizing: "border-box",
  },
  leftButton: {
    boxSizing: "border-box",
    borderRadius: "25px 0 0 25px",
    borderRight: "none",
  },
  rightButton: {
    boxSizing: "border-box",
    borderRadius: "0 25px 25px 0",
    borderLeft: "none",
  },
  midButton: {
    boxSizing: "border-box",
    borderRadius: "0",
  },
  paper: {
    boxShadow: "none",
    borderRadius: 15,
    padding: theme.spacing(2),
    borderBottom: "#e8e9ee 2px solid",
  },
  "@media (max-width: 550px)": {
    titleSettings: {
      flexDirection: "column",
    },
    filterBox: {
      flexDirection: "column",
    },
  },
}));
