import { FC } from "react";
import { Box, Paper } from "@material-ui/core";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { useStyles } from "./styles";
import { FleetHead } from "./FleetHead";
import { FleetListTable } from "./FleetListTable";
import Loader from "router/components/Loader";

const FleetsPage: FC = () => {
  const classes = useStyles();
  const { loadingData } = useTypeSelectors((state) => state.daAndFleets);

  return (
    <Box className={classes.root} id="Table">
      <Loader isLoading={loadingData} />
      <Paper className={classes.paper}>
        <FleetHead />
      </Paper>
      <FleetListTable />
    </Box>
  );
};

export default FleetsPage;
