import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";
import { EMPTY } from "constants/constants";
import { useStyles } from "./styles";
import { FC } from "react";
import { HIGH_SHIFT_TABLE_HEADERS_COLUMN } from "./tableColumns";

interface PropsType {
  loading: boolean;
  currentData: any[];
}

export const HignDAShiftTable: FC<PropsType> = ({loading, currentData}) => {
  const classes = useStyles();

  return (
    <Box className={classes.tableRoot}>
      {!currentData?.length || loading ? (
        <Typography variant="h1" className={classes.emptyTable}>
          {EMPTY}
        </Typography>
      ) : (
        <>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {HIGH_SHIFT_TABLE_HEADERS_COLUMN.map((el: any) => (
                    <TableCell
                      colSpan={el.colSpan}
                      className={`${classes.tableCell} ${classes.tableHeadWhiteCell}`}
                    >
                      <Typography className={classes.tableHeadTypography}>{el.label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody className={classes.tableBody}>
                {currentData?.map((el: any) => (
                  <TableRow hover>
                    <TableCell colSpan={1} className={`${classes.tableCell}`}>
                      {el.driver}
                    </TableCell>
                    <TableCell colSpan={1} className={`${classes.tableCell}`}>
                      {el.name}
                    </TableCell>
                    <TableCell colSpan={1} className={`${classes.tableCell}`}>
                      {el.counter}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};
