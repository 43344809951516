import { useTypeSelectors } from "hooks/useTypeSelectors";

import { MissingDeliveriesChart } from "./PerformanceCharts/MissingDeliveriesChart";
import { DARWorstChart } from "./PerformanceCharts/DARWorstChart";
import { TotalWeeklyChart } from "./PerformanceCharts/TotalWeeklyChart";
import { RolesChart } from "./PerformanceCharts/RolesChart";
import { ScoreTrendChart } from "./PerformanceCharts/ScoreTrendChart";
import { HighPerformersChart } from "./PerformanceCharts/HighPerformersChart";
import FleetUtilizationContent from "./PerformanceContent/FleetUtilizationContent";
import HighDAShiftsContent from "./PerformanceContent/HighDAShiftsContent";
import RabbitReportsContent from "./PerformanceContent/RabbitReportsContent";
import DollyReportsContent from "./PerformanceContent/DollyReportsContent";

export const RenderMainScoreboard = () => {
  const { perfomanceSettings } = useTypeSelectors((state) => state.perfomanceScores);
  const currentStation = perfomanceSettings?.station || "1";

  switch (perfomanceSettings?.currentScorecard) {
    case "TL_PERFORMANCE":
      return <HighPerformersChart station={currentStation} />;
    case "HL_DA_SHIFTS":
      return <HighDAShiftsContent station={currentStation} />;
    case "ROLES":
      return <RolesChart station={currentStation} />;
    case "DA_SCORE_TREND":
      return <ScoreTrendChart station={currentStation} />;
    case "TL_VANS":
      return <FleetUtilizationContent station={currentStation} />;
    case "MISSING_DELIVERIES":
      return <MissingDeliveriesChart station={currentStation} />;
    case "RABBIT_REPORTS":
      return <RabbitReportsContent station={currentStation} />;
    case "DOLLY_REPORTS":
      return <DollyReportsContent station={currentStation} />;
    case "WORST_OF_DAR":
      return <DARWorstChart station={currentStation} />;
    case "PERFORMANCE_SCORECARD":
      return <TotalWeeklyChart station={currentStation} />;
  }
};
