import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "constants/constants";

export const useTableStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    gap: "10px",
  },
  body: {
    boxShadow: "none",
    borderRadius: 15,
    overflow: "auto",
    maxHeight: "calc(100vh - 180px)",
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.background.default,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.light,
      borderRadius: "5px",
    },
  },
  emptyTable: {
    width: "100%",
    textAlign: "center",
    boxSizing: "border-box",
    padding: theme.spacing(1),
  },
  fontWeight: {
    fontWeight: "bold",
    fontSize: "18px",
  },
  headBox: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    boxShadow: "none",
    borderRadius: 15,
    borderBottom: "#e8e9ee 2px solid",
  },
  filters: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "center",
    gap: "10px",
  },
  BoxIcon: {
    padding: theme.spacing(1),
    gap: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  tableWhiteCell: {
    background: Colors.WHITE,
  },
  "@media (max-width: 700px)": {
    filters: {
      flexDirection: "column",
    },
    headBox: {
      flexDirection: "column",
    }
  },
}));

export const useRowStyles = makeStyles((theme) => ({
  blueCell: {
    color: Colors.BLUE,
    width: "100%",
  },
  greenCell: {
    textAlign: "center",
    whiteSpace: "nowrap",
    color: Colors.GREEN,
    width: "100%",
  },
  orangeCell: {
    color: Colors.YELLOW,
    width: "100%",
  },
  redCell: {
    color: Colors.RED,
    width: "100%",
  },
  greenAlert: {
    "&.MuiAlert-standardSuccess": {
      background: "#cefad0",
    },
  },
  blueAlert: {
    "&.MuiAlert-standardInfo": {
      background: "#e2f1ff",
    },
  },
  redAlert: {
    "&.MuiAlert-standardError": {
      background: "#ffe3e6",
    },
  },
  orangeAlert: {
    "&.MuiAlert-standardWarning": {
      background: "#f7f5bc",
    },
  },
}));
