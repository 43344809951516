import api, { apiErrorHandler } from "api";
import { PerfomanceConstants } from "constants/constants";
import { RootState } from "reducers/rootReducer";
import { Dispatch } from "redux";
import { PerfomanceSettingsType } from "types/Performance";

export const getPerfonaceData =
  (station?: string | undefined, week?: number, year?: number, search?: string) =>
  async (dispatch: Dispatch) => {
    const onError = () => dispatch({ type: PerfomanceConstants.PERFORMANCE_ERROR });
    dispatch({ type: PerfomanceConstants.PERFORMANCE_LOADING });
    api
      .getReliabilityScores({ station, week, year, search })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: PerfomanceConstants.RELIABILITY_DATA_SUCCESS,
            payload: res.data,
          });
        }
      })
      .catch(apiErrorHandler(onError));
  };

export const perfomanceUpdateSettings = (data: PerfomanceSettingsType) => (dispatch: Dispatch) => {
  dispatch({
    type: PerfomanceConstants.PERFORMANCE_UPDATE_SETTINGS,
    payload: data,
  });
};

export const PerfomanceSetStation =
  (data: string, dispatchType: string) => (dispatch: Dispatch) => {
    dispatch({
      type: dispatchType,
      payload: data,
    });
  };

export const PerfomanceSetDate =
  (data: { day?: string; week?: string; month?: string; year?: string }) =>
  (dispatch: Dispatch, getStore: () => RootState) => {
    const { perfomanceSettings } = getStore()?.perfomanceScores;
    dispatch({
      type: PerfomanceConstants.PERFORMANCE_SET_DAY,
      payload: { ...perfomanceSettings?.date, ...data },
    });
  };

export const PerfomanceSetSelectType =
  (data: string, dispatchType: string) => (dispatch: Dispatch) => {
    dispatch({
      type: dispatchType,
      payload: data,
    });
  };
