import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "constants/constants";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "10px",
    borderBottom: "none",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
    boxSizing: "border-box",
    gap: "10px",
    justifyContent: "space-between",
    boxShadow: "none",
    borderRadius: 15,
    borderBottom: "#e8e9ee 2px solid",
  },
  falconHead: {
    display: "flex",
    alignItems: "center",
  },
  buttonBox: {
    display: "flex"
  },
  leftButton: {
    boxSizing: "border-box",
    borderRadius: "25px 0 0 25px",
    borderRight: "none",
  },
  rightButton: {
    boxSizing: "border-box",
    borderRadius: "0 25px 25px 0",
    borderLeft: "none",
  },
  tableContainer: {
    boxShadow: "none",
    borderRadius: 15,
    border: "none",
  },
  tableCell: {
    padding: theme.spacing(2),
    cursor: "default",
    textAlign: "center",
  },
  dateLabelBox: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    justifyContent: "center",
  },
  frameBorderTop: {
    borderTop: `3px solid ${Colors.GRAY}`
  },
  frameBorderBottom: {
    borderBottom: `3px solid ${Colors.GRAY}`
  },
  greenAlert: {
    background: "#cefad0",
  },
  redAlert: {
    background: "#ffe3e6",
  },
  greenIcon: {
    backgroundColor: "#92fc97",
    "&:hover": { backgroundColor: "#83e687" },
  },
  redIcon: {
    backgroundColor: "#f7c1c5",
    "&:hover": { backgroundColor: "#e6b1b4" },
  },
  blueIcon: {
    backgroundColor: "#bddfff",
    "&:hover": { backgroundColor: "#a8c6e3" },
  },
  grayIcon: {
    backgroundColor: "#f5f2f2",
    "&:hover": { backgroundColor: "#e8e8e8" },
  },
  input: {
    width:theme.spacing(10),
    "& .MuiTextField-input": {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
    },
  },
  "@media (max-width: 850px)": {
    falconHead: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    buttonBox: {
      marginBottom: theme.spacing(1),
    }
  },
}));
