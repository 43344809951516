import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "./styles";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { UserDataShort } from "types/User";
import { DaAndFleetConstants, fleetStatusConstants } from "constants/constants";
import { addOrRemoveIdFromUpdateList, FleetDataRequestEdit } from "actions/daAndFleetActions";
import { OnChangeEvent } from "types/general";
import { FleetData } from "types/DaAndFleet";
import moment from "moment";

interface FleetTableRowProps {
  fData: FleetData;
  superUsers: UserDataShort[];
}

export const FleetTableRow: FC<FleetTableRowProps> = ({ fData, superUsers }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { userData } = useTypeSelectors((state) => state.auth);
  const { fleetData } = useTypeSelectors((state) => state.daAndFleets);
  const [statusValue, setStatusValue] = useState<number | null>(0);

  useEffect(()=>{
    if (fData.available) {
      setStatusValue(1)
    }
    if (fData.is_out_of_service) {
      setStatusValue(2)
    }
    if (fData.is_off_boarded) {
      setStatusValue(3)
    }
  },[fData.available, fData.is_off_boarded, fData.is_out_of_service])

  const supervisor = superUsers?.find(
    (item: UserDataShort) => item?.id === fData?.van_group?.supervisor,
  );

  return (
    <TableRow data-testid="testDaRow" hover>
      <TableCell>
        <Checkbox
          key={fData?.id}
          name="blockNotify"
          checked={fleetData?.idListForUpdate?.includes(fData?.id)}
          onChange={() =>
            dispatch(
              addOrRemoveIdFromUpdateList(
                DaAndFleetConstants.DA_FEET_SET_FLEET_ID_LIST,
                fData?.id,
                fleetData,
              ),
            )
          }
        />
      </TableCell>
      <TableCell className={`${classes.tableCell}`}>
        <Typography>{fData?.id}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell}`}>
        <Typography>{fData?.number}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell}`}>
        <Typography>{fData?.van_group?.name}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell}`}>
        <Typography>{fData?.fleet_size?.title}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell}`}>
        <Typography>
          {supervisor ? `${supervisor?.first_name} ${supervisor?.last_name}` : ""}
        </Typography>
      </TableCell>
      <TableCell style={{ minWidth: 60 }} className={`${classes.tableCell}`}>
        <FormControl style={{ display: "flex", alignContent: "center" }} fullWidth>
          <Select
            inputProps={{ "data-testid": "actRowStatusInput" }}
            onChange={(e: OnChangeEvent) => {
              setStatusValue(e?.target?.value);
              dispatch(
                FleetDataRequestEdit(
                  fData.id,
                  e?.target?.value,
                ),
              );
            }}
            name="status"
            value={statusValue}
            defaultValue={statusValue}
            placeholder="status"
          >
            {fleetStatusConstants?.map(({ value, option }: { value: number; option: string }) => (
              <MenuItem key={`action${option}`} value={value}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell className={`${classes.tableCell}`}>
        <Typography>{fData?.last_status_change
            ? moment(fData?.last_status_change).utcOffset(200).format("DD-MM-YYYY")
            : "---"}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell}`}>
        <Typography>
          {
            userData?.station_spots.find((item) => item?.station?.id === fData?.station)?.station
              .title
          }
        </Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell}`}>
        <Typography>{fData?.van_group?.description}</Typography>
      </TableCell>
    </TableRow>
  );
};
