import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "constants/constants";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "10px",
    borderBottom: "none",
  },
  paper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    boxSizing: "border-box",
    gap: "10px",
    justifyContent: "space-between",
    boxShadow: "none",
    borderRadius: 15,
    borderBottom: "#e8e9ee 2px solid",
  },
  pageHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tableRoot: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxHeight: "calc(100vh - 180px)",
  },
  tableContainer: {
    boxShadow: "none",
    borderRadius: 15,
  },
  listReportRow: {
    "&:hover": {
      background: Colors.GRAY,
    },
  },
  tableCellBig: {
    minWidth: "170px",
    fontSize: "18px",
    textAlign: "center",
  },
  tableCellSmall: {
    minWidth: "150px",
    fontSize: "18px",
    textAlign: "center",
  },
  emptyTable: {
    marginTop: theme.spacing(2),
    alignSelf: "center",
  },
  fontWeight: {
    fontWeight: 700,
    fontSize: "18px",
  },
  tableCell: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  tableCellTypography: {
    fontSize: 16,
    fontWeight: 600,
  },
  tableBodyCell: {
    padding: theme.spacing(2),
  },
  sortCell: {
    display: "flex",
    gap: 8,
    alignItems: "center",
  },
  typeButtons: {
    display: "flex",
  },
  leftButton: {
    boxSizing: "border-box",
    borderRadius: "25px 0 0 25px",
  },
  rightButton: {
    boxSizing: "border-box",
    borderRadius: "0 25px 25px 0",
  },
  headBox: {
    display: "flex",
    gap: 12,
    alignItems: "center",
  },
}));
