import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";
import { EMPTY } from "constants/constants";
import { useStyles } from "./styles";
import { FC } from "react";
import { FLEET_UTIL_TABLE_HEADERS_COLUMN } from "./tableColumns";

interface PropsType {
  loading: boolean;
  currentData:
    | { van__number: number; counter: number; van__van_group__fleet_ownership: number | null }[]
    | undefined;
}

export const FleetUtilTable: FC<PropsType> = ({ loading, currentData }) => {
  const classes = useStyles();

  const checkVanOwner = (val: number | null) => {
    switch (val) {
      case null:
        return "Unregister";
      case 1:
        return "Rental";
      case 2:
        return "Owned";
    }
  };

  return (
    <Box className={classes.tableRoot}>
      {!currentData?.length || loading ? (
        <Typography variant="h1" className={classes.emptyTable}>
          {EMPTY}
        </Typography>
      ) : (
        <>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {FLEET_UTIL_TABLE_HEADERS_COLUMN.map((el: any) => (
                    <TableCell
                      colSpan={el.colSpan}
                      className={`${classes.tableCell} ${classes.tableHeadWhiteCell}`}
                    >
                      <Typography className={classes.tableHeadTypography}>{el.label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody className={classes.tableBody}>
                {currentData?.map((el: any) => (
                  <TableRow hover>
                    <TableCell colSpan={1} className={`${classes.tableCell}`}>
                      {el.van__number}
                    </TableCell>
                    <TableCell colSpan={1} className={`${classes.tableCell}`}>
                      {checkVanOwner(el.van__van_group__fleet_ownership)}
                    </TableCell>
                    <TableCell colSpan={1} className={`${classes.tableCell}`}>
                      {el.counter}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};
