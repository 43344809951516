import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { EMPTY } from "constants/constants";
import { FLEET_TABLE_HEADERS_COLUMN } from "./tableData";
import { FleetTableRow } from "./FleetTableRow";
import api from "api";
import { useEffect, useState } from "react";
import { UserDataShort } from "types/User";
import { TableSortHead } from "components/tables/tableSortHead";
import { setFleetFilters, setFleetTablePagginationPage } from "actions/daAndFleetActions";
import { FleetData } from "types/DaAndFleet";

export const FleetListTable = () => {
  const classes = useStyles();

  const { fleetData } = useTypeSelectors((state) => state.daAndFleets);

  const [superUserList, setSuperUserList] = useState<UserDataShort[]>([]);

  useEffect(()=>{
    api
      .getSuperUsersList()
      .then((res) => {
        if (res.data) {
          setSuperUserList(res.data.results);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  },[])

  return (
    <Box className={classes.tableRoot}>
      {!fleetData.fleetList.length ? (
        <Typography variant="h1" className={classes.emptyTable}>
          {EMPTY}
        </Typography>
      ) : (
        <>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table stickyHeader>
              <TableSortHead 
                tableHeadersList={FLEET_TABLE_HEADERS_COLUMN}
                pagginationAction={setFleetTablePagginationPage}
                sortAction={setFleetFilters} 
                filters={fleetData?.fleetListFilters}
              />
              <TableBody>
                  {fleetData?.fleetList
                    ?.map((el: FleetData, index:number) => (
                      <FleetTableRow 
                        key={`${index}${el.id}`}
                        fData={el}
                        superUsers={superUserList}
                      />
                    ))
                  }
                </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};
