import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "10px",
    borderBottom: "none",
  },
}));
