import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { FC } from "react";
import SettingsCollapseButton from "./SettingsCollapseButton";
import NavigationMenuSettings from "./SettingsLists/NavigationMenuSettings";

interface PropsType {}

const SettingsPage: FC<PropsType> = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography variant="h1">Settings</Typography>
      <Box>
        <SettingsCollapseButton buttonName={"Navigation Menu Settings"}>
          <NavigationMenuSettings />
        </SettingsCollapseButton>
      </Box>
    </Box>
  );
};

export default SettingsPage;
