import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "constants/constants";

export const useDialogStyles = makeStyles((theme) => ({
  dialogActions: {
    "&.MuiDialogActions-root": {
      padding: "32px 24px",
      gap: "20px",
    },
  },
  actionButton: {
    padding: theme.spacing(1.5, 2),
    borderRadius: 100,
    width: "50%",
    fontSize: 16,
  },
  modelContentDefaults: {
    minHeight: 200,
    minWidth: 500,
    display: "flex",
    flexDirection: "column",
  },
  falconContent: {
    minWidth: 400,
    display: "flex",
    flexDirection: "column",
  },
  matchForm: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  "@media (max-width: 550px)": {
    modelContentDefaults: {
      boxSizing: "border-box",
      minWidth: "100%",
    },
    falconContent: {
      minWidth: "100%",
    }
  },
  errorMsg: {
    color: Colors.RED
  }
}));
