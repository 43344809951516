import { FC, useState } from "react";
import { DialogContent, DialogContentText, DialogActions, FormControl, MenuItem, Select } from "@material-ui/core";
import { Colors, daStatusConstants, fleetStatusConstants } from "constants/constants";
import { useDispatch } from "react-redux";
import { closeModal } from "actions/modalActions";
import { useDialogStyles } from "../styles";
import { DialogButton } from "components/buttons/DialogButton";
import { OnChangeEvent } from "types/general";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { updateMultipleFleetStatusAction, updateMultipleUsersStatusAction } from "actions/daAndFleetActions";

interface ChangeDaFleetStatusDialogProps {}

export const ChangeDaStatusDialog: FC<ChangeDaFleetStatusDialogProps> = () => {
  const classes = useDialogStyles();
  const dispatch = useDispatch();
  const [statusValue, setStatusValue] = useState<number>(1);
  const { driverData } = useTypeSelectors((state) => state.daAndFleets);

  const onClose = () => dispatch(closeModal());

  const handleSubmit = () => {
    onClose();
    dispatch(updateMultipleUsersStatusAction(
      statusValue,
      driverData?.idListForUpdate
    ))
  };

  return (
    <>
    <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            minWidth: 300,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControl style={{ display: "flex", alignContent: "center" }} fullWidth>
          <Select
            onChange={(e: OnChangeEvent) => {
              setStatusValue(e?.target?.value);
            }}
            name="status"
            value={statusValue}
            defaultValue={statusValue}
            placeholder="status"
          >
            {daStatusConstants?.map(({ value, option }: { value: number; option: string }) => (
              <MenuItem key={`action${option}`} value={value}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <DialogButton
          testId={""}
          buttonName={"Cancel"}
          onClick={onClose}
          customStyle={{ background: Colors.GRAY }}
        />
        <DialogButton
          testId={""}
          buttonName={"Submit"}
          onClick={handleSubmit}
          customStyle={{ background: Colors.BLUE, color: "#fff" }}
        />
      </DialogActions>
    </>
  );
};

export const ChangeFleetStatusDialog: FC<ChangeDaFleetStatusDialogProps> = () => {
  const classes = useDialogStyles();
  const dispatch = useDispatch();
  const [statusValue, setStatusValue] = useState<number>(1);
  const { fleetData } = useTypeSelectors((state) => state.daAndFleets);

  const onClose = () => dispatch(closeModal());

  const handleSubmit = () => {
    onClose();
    dispatch(updateMultipleFleetStatusAction(statusValue, fleetData?.idListForUpdate));
  };

  return (
    <>
    <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            minWidth: 300,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControl style={{ display: "flex", alignContent: "center" }} fullWidth>
          <Select
            onChange={(e: OnChangeEvent) => {
              setStatusValue(e?.target?.value);
            }}
            name="status"
            value={statusValue}
            defaultValue={statusValue}
            placeholder="status"
          >
            {fleetStatusConstants?.map(({ value, option }: { value: number; option: string }) => (
              <MenuItem key={`action${option}`} value={value}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <DialogButton
          testId={""}
          buttonName={"Cancel"}
          onClick={onClose}
          customStyle={{ background: Colors.GRAY }}
        />
        <DialogButton
          testId={""}
          buttonName={"Submit"}
          onClick={handleSubmit}
          customStyle={{ background: Colors.BLUE, color: "#fff" }}
        />
      </DialogActions>
    </>
  );
};
