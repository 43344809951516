import { Button, Collapse, Typography } from "@material-ui/core";
import { FC, ReactNode, useState } from "react";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

interface PropsType {
  children: ReactNode;
  buttonName: string;
}

const SettingsCollapseButton: FC<PropsType> = ({ children, buttonName }) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <>
      <Button
        fullWidth
        color="primary"
        variant={"outlined"}
        onClick={() => setIsActive(!isActive)}
        disableElevation
      >
        <Typography variant="body2" style={{ marginLeft: 10, fontSize: 18 }}>
          {buttonName}
        </Typography>
        {isActive ? <ExpandLess /> : <ExpandMore />}
      </Button>
      <Collapse
        style={{
          width: "100%",
        }}
        in={isActive}
        timeout="auto"
        unmountOnExit
      >
        {children}
      </Collapse>
    </>
  );
};

export default SettingsCollapseButton;
