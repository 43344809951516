import { FC, useEffect, useRef, useState, ChangeEvent, FocusEvent, useCallback } from "react";
import {
  Checkbox,
  IconButton,
  Select,
  TableCell,
  TableRow,
  TextField,
  MenuItem,
  CircularProgress,
  FormControl,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import Alert, { Color } from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";
import { DuplicateVansDialog, NoVansDialog } from "components/modals/modalContent/ShiftDialogs";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { roleDa, waveDa } from "mocks/roleDA";
import { inputNumberValidate, inputNumberValidateOnPaste } from "utils/formats";
import { getTimeSincePost } from "utils/filters";
import { ReportRecord, DriverData, FleetSizeType, DollyNumber, RabbitNumber } from "types/Report";
import { useDispatch } from "react-redux";
import { changeSerialNumber, getReportAction } from "actions/daDailyReportsAction";
import { useReportStyles } from "./styles";
import { useNav } from "hooks/useNav";
import api from "api";
import { daShiftStatusConstants } from "constants/constants";
import { ModalWindow } from "components/templateComponents/modalWindow";
import { openModal } from "actions/modalActions";
import { OnBlurEvent } from "types/general";
import { useDaStatusHighlight } from "hooks/getDaStatusHighlight";
import { sortByRabbit } from "utils/helpers";

interface ReportRowProps {
  reportRecord: ReportRecord;
  index: number;
  onDelete: (id: number) => void;
  onUpdate: (id: number, data: ReportRecord) => void;
}

type ReportRowValue = string | number | undefined | null;

export const ReportRow: FC<ReportRowProps> = ({ reportRecord, index, onDelete, onUpdate }) => {
  const classes = useReportStyles();
  const dispatch = useDispatch();
  const recordId = reportRecord.id;
  const reportId = useNav().pageParams?.id;
  const ref = useRef<HTMLInputElement | null>(null);

  const { userData } = useTypeSelectors((state) => state.auth);
  const { currentReport, currentReportDate, currentReportStation, hasSecondDriver, fleetSizes } =
    useTypeSelectors((state) => state.dasBoard);
  const [rowData, setRowData] = useState<ReportRecord>(reportRecord);
  const [duplicateVansData, setDuplicateVansData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState<DriverData[]>([]);
  const [dollyNumbers, setDollyNumbers] = useState<DollyNumber[]>([]);
  const [rabbitNumbers, setRabbitNumbers] = useState<RabbitNumber[]>([]);
  const [isSecondRabbitActive, setSecondRabbitActive] = useState<boolean>(false);
  const [isSecondDollyActive, setSecondDollyActive] = useState<boolean>(false);
  const [currentDriver, setCurrentDriver] = useState<DriverData | undefined>();
  const [disabledByDate, setDisabledByDate] = useState(getTimeSincePost(currentReportDate));
  const [driverVanAssignmentId, setDriverVanAssignmentId] = useState(rowData.assignments?.[0]?.id);
  // const [codValue, setCodValue] = useState(rowData.cod);
  const renderTextTransporterId =
    currentReportStation?.id === 1 || currentReportStation?.id === 2 ? "Pending the ID" : "NR";
  const conditionForDisabledEdit = userData?.can_create_old_shifts ? false : disabledByDate;

  const driverHighlight = useDaStatusHighlight(rowData?.da_status);
  const secondDriverHighlight = useDaStatusHighlight(rowData?.second_da_status);

  useEffect(() => {
    if (currentReportDate) {
      setDisabledByDate(getTimeSincePost(currentReportDate));
    }
  }, [currentReportDate]);

  const filterUsedDolly = (num_id: number | null) =>
    !currentReport.some(
      (record) => num_id === record.dolly_number?.id || num_id === record.second_dolly_number?.id,
    ) ||
    num_id === rowData.dolly_number?.id ||
    num_id === rowData.second_dolly_number?.id;

  const filterUsedRabbit = (num_id: number | null) =>
    !currentReport.some(
      (record) => num_id === record.rabbit_number?.id || num_id === record.second_rabbit_number?.id,
    ) ||
    num_id === rowData.rabbit_number?.id ||
    num_id === rowData.second_rabbit_number?.id;

  const handleDeleteRow = () => onDelete(recordId);

  const handleUpdateRow = useCallback(
    (data: any) => {
      setRowData({ ...rowData, ...data });
      api
        .updateReportRecord(recordId, data)
        .then((res) => {
          if (res.data) {
            onUpdate(recordId, res.data);
            setRowData({ ...rowData, ...res.data });
          }
        })
        .catch((err) => {
          onUpdate(recordId, rowData);
          setRowData(rowData);
          if (ref.current !== null) ref.current.value = "";
          if (err?.response?.data?.detail) {
            setDuplicateVansData(err?.response?.data?.detail);
            dispatch(openModal(`duplicate-vans-modal-${recordId}`));
          }
        });
    },
    [dispatch, onUpdate, recordId, rowData],
  );

  const handleClearDrivers = () => {
    setDrivers([]);
    setLoading(false);
  };

  const handleClearRabbit = () => {
    setRabbitNumbers([]);
    setLoading(false);
  };

  const handleClearDolly = () => {
    setDollyNumbers([]);
    setLoading(false);
  };

  const handleGetDrivers = async () => {
    setLoading(true);
    api
      .getAvailableDrivers({
        date: currentReportDate,
        station: currentReportStation?.id,
        available: true,
      })
      .then((res) => {
        if (res?.data?.results) {
          setDrivers(res.data.results);
          setLoading(false);
        }
      })
      .catch(() => {
        handleClearDrivers();
      });
  };

  const handleGetRabbit = async () => {
    setLoading(true);
    api
      .getRabbitNumbers({
        page_size: 1000,
        station: currentReportStation?.id,
        status: 1,
      })
      .then((res) => {
        if (res?.data?.results) {
          setRabbitNumbers(res.data.results);
          setLoading(false);
        }
      })
      .catch(() => {
        handleClearRabbit();
      });
  };

  const handleGetDolly = async () => {
    setLoading(true);
    api
      .getDollyNumbers({
        page_size: 1000,
        station: currentReportStation?.id,
        status: 1,
      })
      .then((res) => {
        if (res?.data?.results) {
          setDollyNumbers(res.data.results);
          setLoading(false);
        }
      })
      .catch(() => {
        handleClearDolly();
      });
  };

  const handleChangeFleetSize = async (event: ChangeEvent<{ value: any }>) => {
    const size = fleetSizes.find((el) => el.id === +event.target.value);
    if (rowData?.role === 7) {
      handleUpdateRow({ cancelled_fleet_size: event.target.value });
    }
    if (size && rowData?.role !== 7) {
      await api
        .setSizeToAssignment(driverVanAssignmentId, {
          date: currentReportDate,
          station: currentReportStation?.id,
          fleet_size: size?.id,
        })
        .then((res) => {
          if (res?.data) setRowData({ ...rowData, van: { ...res.data, fleet_size: size } });
        })
        .catch(() => {
          dispatch(openModal(`no-vans-modal-${recordId}`));
          setRowData({ ...rowData, van: null });
        });
      // GET UPDATED DATA FROM BACK
      if (reportId) dispatch(getReportAction(reportId, false));
    }
  };

  const handleChangeDriver = async (event: ChangeEvent<{ value: any }>) => {
    const driverId = event.target.value;
    if (recordId) {
      if (driverId) {
        const currentDriver = drivers.find((el: DriverData) => el.id === +driverId);
        handleClearDrivers();
        await api.setDriverToAssignment({ record: recordId, driver: driverId }).then((res) => {
          if (res.data?.id) {
            setRowData({ ...rowData, driver: driverId, van: null });
            setDriverVanAssignmentId(res.data?.id);
            setCurrentDriver(currentDriver);
          }
        });
      } else if (driverId === 0 && driverVanAssignmentId) {
        await api.deleteAssignment(driverVanAssignmentId).then(() => {
          setRowData({ ...rowData, driver: driverId, van: null });
          setDriverVanAssignmentId(0);
          setCurrentDriver(undefined);
        });
      } else {
        setRowData({ ...rowData, driver: driverId, van: null });
        setDriverVanAssignmentId(0);
        setCurrentDriver(undefined);
      }
      handleUpdateRow({ da_status: 0 });
    }
    // GET UPDATED DATA FROM BACK
    if (reportId) dispatch(getReportAction(reportId, false));
  };

  const handleChangeSecondDriver = (event: ChangeEvent<{ value: unknown }>) => {
    if (event.target.value !== rowData?.second_driver?.id) {
      handleUpdateRow({
        second_driver_id: event.target.value === 0 ? null : event.target.value,
        second_da_status: 0,
      });
    }
  };

  const handleChangeRole = (event: ChangeEvent<{ value: unknown }>) => {
    if (event.target.value !== rowData?.role) {
      handleUpdateRow({ role: event.target.value });
    }
  };

  const handleChangeDaStatus = (event: ChangeEvent<{ value: unknown }>) => {
    if (event.target.value !== rowData?.da_status) {
      handleUpdateRow({ da_status: event.target.value });
    }
  };

  const handleChangeSecondDaStatus = (event: ChangeEvent<{ value: unknown }>) => {
    if (event.target.value !== rowData?.second_da_status) {
      handleUpdateRow({ second_da_status: event.target.value });
    }
  };

  const handleChangeWave = (event: ChangeEvent<{ value: unknown }>) => {
    if (event.target.value !== rowData?.wave_time) {
      handleUpdateRow({ wave_time: event.target.value });
    }
  };

  const handleChangeCheckBox = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as keyof ReportRecord;
    if (event.target.value !== rowData?.[name]) {
      handleUpdateRow({ [event.target.name]: event.target.checked });
    }
  };

  const inputTimeValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as keyof ReportRecord;
    if (event.target.value !== rowData?.[name]) {
      handleUpdateRow({ [event.target.name]: event.target.value });
    }
  };

  const inputValueBlur = (event: FocusEvent<HTMLInputElement>) => {
    const name = event.target.name as keyof ReportRecord;
    if (event.target.value === "" && rowData?.[name] === null) return;
    if (event.target.value !== rowData?.[name]) {
      handleUpdateRow({ [event.target.name]: event.target.value });
    }
  };

  const handleUpdateSerialNumber = (report_id: number, data: {
    rabbit_number?: number;
    second_rabbit_number?: number;
    dolly_number?: number;
    second_dolly_number?: number;
  }) => {
    api.updateSerialNumber({
      record_id: recordId,
      ...data,
    }).then((res) => {
      if (res?.data) {
        const updatedData = {
          ...(data.rabbit_number 
            ? { rabbit_number: rabbitNumbers.find((item)=> item.id === data?.rabbit_number)} 
            : data?.rabbit_number === 0
              ? {rabbit_number: null}
              : null),
        ...(data.second_rabbit_number 
            ? { second_rabbit_number: rabbitNumbers.find((item)=> item.id === data?.second_rabbit_number)} 
            : data?.second_rabbit_number === 0
              ? {second_rabbit_number: null}
              : null),
        ...(data.dolly_number 
            ? { dolly_number: dollyNumbers.find((item)=> item.id === data?.dolly_number)} 
            : data?.dolly_number === 0
              ? {dolly_number: null}
              : null),
        ...(data.second_dolly_number 
            ? { second_dolly_number: dollyNumbers.find((item)=> item.id === data?.second_dolly_number)} 
            : data?.second_dolly_number === 0
              ? {second_dolly_number: null}
              : null),
        }
      setRowData({
        ...rowData,
        ...updatedData,
      })
      dispatch(changeSerialNumber(report_id, updatedData))
    }
    }).catch(()=>{
      onUpdate(recordId, rowData);
      setRowData(rowData);
    });
  }

  const inputNumberValueChange = useCallback(
    (name: keyof ReportRecord, value: ReportRowValue) => {
      if (value === "" && rowData?.[name] === null) return;
      if (value === "" && rowData?.[name] !== null) {
        handleUpdateRow({ [name]: null });
        return;
      }
      if (+value! !== rowData?.[name]) {
        handleUpdateRow({ [name]: +value! });
        return;
      }
    },
    [rowData, handleUpdateRow],
  );

  const rescueValueChange = (value: ReportRowValue) => {
    if (value === "" && rowData?.rescue_given === null) return;
    if (value === "" && rowData?.rescue_given !== null) {
      handleUpdateRow({ rescue_given: null, rescue: false });
      return;
    }
    if (value !== rowData?.rescue_given) {
      handleUpdateRow({ rescue_given: +value!, rescue: Boolean(+value!) });
      return;
    }
  };

  // const handleChangeFleetRole = (event: ChangeEvent<{ value: unknown }>) => {
  //   if (event.target.value !== rowData?.fleet_role) {
  //     handleUpdateRow({ fleet_role: event.target.value });
  //   }
  // };

  const driver = rowData.driver?.id ? rowData.driver : currentDriver;

  const driverLabel = driver?.id ? (
    <MenuItem key={driver?.id} value={driver?.id}>
      {driver?.first_name} {driver?.last_name}
    </MenuItem>
  ) : (
    <MenuItem key={0} value={0}>
      Unassigned driver
    </MenuItem>
  );

  const driverSecondLabel = rowData?.second_driver?.id ? (
    <MenuItem key={rowData.second_driver?.id} value={rowData.second_driver?.id}>
      {rowData.second_driver?.first_name} {rowData.second_driver?.last_name}
    </MenuItem>
  ) : (
    <MenuItem key={0} value={0}>
      Unassigned driver
    </MenuItem>
  );

  const rabbitLabel = rowData?.rabbit_number?.id ? (
    <MenuItem key={rowData.rabbit_number?.id} value={rowData.rabbit_number?.id}>
      {rowData.rabbit_number?.serial_number}
    </MenuItem>
  ) : (
    <MenuItem key={0} value={0}>
      ---
    </MenuItem>
  );

  const secondRabbitLabel = rowData?.second_rabbit_number?.id ? (
    <MenuItem key={rowData.second_rabbit_number?.id} value={rowData.second_rabbit_number?.id}>
      {rowData.second_rabbit_number?.serial_number}
    </MenuItem>
  ) : (
    <MenuItem key={0} value={0}>
      ---
    </MenuItem>
  );

  const dollyLabel = rowData?.dolly_number?.id ? (
    <MenuItem key={rowData?.dolly_number?.id} value={rowData?.dolly_number?.id}>
      {rowData.dolly_number?.serial_number}
    </MenuItem>
  ) : (
    <MenuItem key={0} value={0}>
      ---
    </MenuItem>
  );

  const secondDollyLabel = rowData?.second_dolly_number?.id ? (
    <MenuItem key={rowData?.second_dolly_number?.id} value={rowData?.second_dolly_number?.id}>
      {rowData.second_dolly_number?.serial_number}
    </MenuItem>
  ) : (
    <MenuItem key={0} value={0}>
      ---
    </MenuItem>
  );

  const NO_FLEET_SIZE = "no data";

  const renderValues = {
    index: index + 1,
    van_group: rowData?.van?.van_group?.name || rowData.van?.van_group_name,
    van_number: rowData.van?.number,
    fleet_size: rowData.van?.fleet_size?.id
      ? rowData.van?.fleet_size?.id
      : rowData.cancelled_fleet_size
        ? rowData.cancelled_fleet_size
        : NO_FLEET_SIZE,
    badge: driver?.badge !== "none" ? driver?.badge : "None",
    transporter_id:
      driver?.transporter_id !== "none" ? driver?.transporter_id : renderTextTransporterId,
  };

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => inputNumberValueChange("cod", codValue), 500);
  //   return () => clearTimeout(timeoutId);
  // }, [codValue, inputNumberValueChange]);

  return (
    <TableRow className={classes.tableRow}>
      <ModalWindow
        noButton
        modal_name={`no-vans-modal-${recordId}`}
        modalTitle={"No Free Vans"}
        modalText={`
          There are no free vans for current driver with such fleet size. 
          Try select this driver again with another fleet size or connect
          with administrator!
        `}
      >
        <NoVansDialog />
      </ModalWindow>

      <ModalWindow
        noButton
        modal_name={`duplicate-vans-modal-${recordId}`}
        modalTitle={"Duplicate Vans"}
        modalText={duplicateVansData?.message ? duplicateVansData?.message : duplicateVansData}
      >
        <DuplicateVansDialog data={duplicateVansData} />
      </ModalWindow>
      <TableCell
        className={`${classes.tableCellSmall} ${classes.tableCellAlign}`}
        style={{ width: 50 }}
      >
        <IconButton
          disabled={conditionForDisabledEdit}
          onClick={handleDeleteRow}
          style={{ padding: "5px" }}
        >
          <CloseIcon color={conditionForDisabledEdit ? "disabled" : "secondary"} />
        </IconButton>
      </TableCell>
      <TableCell
        className={`${classes.tableCellSmall} ${classes.tableCellAlign}`}
        style={{ width: 50 }}
      >
        {renderValues.index}
      </TableCell>
      <TableCell className={`${classes.tableCellBig} ${classes.tableCellAlign}`}>
        {renderValues.van_group}
      </TableCell>
      <TableCell className={`${classes.tableCellSmall} ${classes.tableCellAlign}`}>
        {renderValues.van_number || (
          <TextField
            name="fleet_number"
            inputProps={{ style: { textAlign: "center" } }}
            defaultValue={rowData.fleet_number || ""}
            onBlur={(e: OnBlurEvent) => inputNumberValueChange("fleet_number", e.target.value)}
            className={classes.input}
            disabled={conditionForDisabledEdit || Boolean(renderValues?.van_number)}
            onKeyPress={inputNumberValidate}
            onPaste={inputNumberValidateOnPaste}
            inputRef={ref}
          />
        )}
      </TableCell>
      <TableCell className={classes.tableCellBig}>
        <Select
          className={classes.selectFieldBig}
          label="Fleet Size"
          name="size"
          disabled={conditionForDisabledEdit || (!rowData.driver && rowData.role !== 7)}
          value={renderValues.fleet_size}
          onChange={handleChangeFleetSize}
        >
          {renderValues.fleet_size === NO_FLEET_SIZE && (
            <MenuItem key={0} value={NO_FLEET_SIZE} selected disabled>
              No selection
            </MenuItem>
          )}

          {fleetSizes.length > 0
            ? fleetSizes
                .sort((a, b) => [1, 6, 3, 2].indexOf(a.id) - [1, 6, 3, 2].indexOf(b.id))
                .filter((item) => item.id !== 4 && item.id !== 5)
                .map((el: FleetSizeType) => (
                  <MenuItem key={el.id} value={el.id}>
                    {el.title}
                  </MenuItem>
                ))
            : null}
        </Select>
      </TableCell>
      {/* <TableCell className={classes.tableCellBig}>
        <Select
          className={classes.selectFieldBig}
          label="Fleet Role"
          name="fleet_role"
          disabled={conditionForDisabledEdit}
          value={rowData.fleet_role}
          onChange={handleChangeFleetRole}
        >
          {roleFleet.map((el: { name: string; value: number; id: number }) => (
            <MenuItem key={el.id} value={el.value}>
              {el.name}
            </MenuItem>
          ))}
        </Select>
      </TableCell> */}
      <TableCell className={classes.tableCellBig}>
        <Select
          className={classes.selectFieldBig}
          label="Role"
          name="role"
          disabled={conditionForDisabledEdit}
          value={rowData.role}
          onChange={handleChangeRole}
        >
          {roleDa.map((el: { name: string; value: number; id: number }) => (
            <MenuItem key={el.id} value={el.value}>
              {el.name}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell className={`${classes.tableCellSmall} ${classes.tableCellAlign}`}>
        {rowData?.role === 8 ? (
          <TextField
            name="role_hours"
            inputProps={{ style: { textAlign: "center" } }}
            defaultValue={rowData.role_hours || ""}
            onBlur={(e: OnBlurEvent) => inputNumberValueChange("role_hours", e.target.value)}
            className={classes.input}
            disabled={conditionForDisabledEdit || rowData.role !== 8}
            onKeyPress={inputNumberValidate}
            onPaste={inputNumberValidateOnPaste}
            inputRef={ref}
          />
        ) : (
          <Typography>-</Typography>
        )}
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="route"
          defaultValue={rowData.route}
          disabled={conditionForDisabledEdit}
          onBlur={inputValueBlur}
        />
      </TableCell>
      <TableCell className={classes.tableCellBig}>
        <Select
          className={classes.selectFieldBig}
          label="Wave"
          name="wave_time"
          disabled={conditionForDisabledEdit}
          value={rowData.wave_time}
          onChange={handleChangeWave}
        >
          {rowData.wave_time === null && (
            <MenuItem key={0} value={NO_FLEET_SIZE} selected disabled>
              -
            </MenuItem>
          )}

          {waveDa.map((el) => (
            <MenuItem key={el.id} value={el.value}>
              {el.name}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell className={classes.tableCellBig}>{renderValues.badge}</TableCell>
      <TableCell className={classes.tableCellBigSticky}>
        <Select
          key="select_da_1"
          className={classes.selectFieldBig}
          label="Select DA"
          disabled={conditionForDisabledEdit}
          value={rowData.driver?.id || rowData.driver || currentDriver?.id || 0}
          onOpen={handleGetDrivers}
          onChange={handleChangeDriver}
          onClose={handleClearDrivers}
        >
          <MenuItem key={0} value={0}>
            Unassigned driver
          </MenuItem>
          {drivers.length === 0 ? driverLabel : null}
          {loading ? (
            <MenuItem key="loading" disabled>
              <CircularProgress />
            </MenuItem>
          ) : (
            drivers.map((el: DriverData) => (
              <MenuItem key={el.id} value={el.id}>
                {el.first_name} {el.last_name}
              </MenuItem>
            ))
          )}
        </Select>
      </TableCell>
      <TableCell className={`${classes.tableCellBig} ${classes.tableCellAlign}`}>
        {renderValues.transporter_id}
      </TableCell>
      <TableCell className={`${classes.tableCellBig} ${classes.tableCellAlign}`}>
        <Alert
          icon={false}
          severity={driverHighlight.severity as Color}
          className={driverHighlight.alertClassName}
          style={{
            padding: "0 5px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <FormControl style={{ display: "flex", alignContent: "center" }} fullWidth>
            <Select
              className={driverHighlight.className}
              onChange={handleChangeDaStatus}
              name="status"
              value={rowData?.da_status}
              defaultValue={rowData?.da_status}
              placeholder="status"
              disabled={conditionForDisabledEdit}
            >
              {daShiftStatusConstants?.map(
                ({ value, option }: { value: number; option: string }) => (
                  <MenuItem key={`action${option}`} value={value}>
                    {option}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
        </Alert>
      </TableCell>

      {hasSecondDriver && (
        <>
          <TableCell className={classes.tableCellBig}>
            <Select
              key="select_da_2"
              className={classes.selectFieldBig}
              label="Select DA 2"
              disabled={conditionForDisabledEdit}
              value={rowData?.second_driver?.id || 0}
              onOpen={handleGetDrivers}
              onChange={handleChangeSecondDriver}
              onClose={handleClearDrivers}
            >
              <MenuItem key={0} value={0}>
                Unassigned driver
              </MenuItem>
              {drivers.length === 0 ? driverSecondLabel : null}
              {loading ? (
                <MenuItem key="loading" disabled>
                  <CircularProgress />
                </MenuItem>
              ) : (
                drivers.map((el: DriverData) => (
                  <MenuItem key={el.id} value={el.id}>
                    {el.first_name} {el.last_name}
                  </MenuItem>
                ))
              )}
            </Select>
          </TableCell>
          <TableCell className={`${classes.tableCellBig} ${classes.tableCellAlign}`}>
            <Alert
              icon={false}
              severity={secondDriverHighlight.severity as Color}
              className={secondDriverHighlight.alertClassName}
              style={{
                padding: "0 5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FormControl style={{ display: "flex", alignContent: "center" }} fullWidth>
                <Select
                  className={secondDriverHighlight.className}
                  onChange={handleChangeSecondDaStatus}
                  name="status"
                  value={rowData?.second_da_status}
                  defaultValue={rowData?.second_da_status}
                  placeholder="status"
                  disabled={conditionForDisabledEdit}
                >
                  {daShiftStatusConstants?.map(
                    ({ value, option }: { value: number; option: string }) => (
                      <MenuItem key={`action${option}`} value={value}>
                        {option}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </Alert>
          </TableCell>
        </>
      )}

      <TableCell className={classes.tableCellComment}>
        <TextField
          name="actual_DA"
          disabled={conditionForDisabledEdit}
          defaultValue={rowData.actual_DA}
          onBlur={inputValueBlur}
        />
      </TableCell>

      <TableCell className={`${classes.tableCellBig} ${classes.tableCellLarge}`}>
        <Select
          fullWidth
          className={classes.selectFieldSerialNumberWide}
          label="DA Rabbit"
          name="da-rabbit"
          onOpen={handleGetRabbit}
          disabled={conditionForDisabledEdit}
          value={rowData?.rabbit_number?.id || 0}
          onChange={(e) =>
            handleUpdateSerialNumber(rowData.id, { rabbit_number: Number(e.target.value) })
          }
        >
          <MenuItem value={0}>---</MenuItem>
          {!rabbitNumbers.length ? rabbitLabel : null}
          {loading ? (
            <MenuItem key="loading" disabled>
              <CircularProgress />
            </MenuItem>
          ) : (
            rabbitNumbers
              .filter((el: RabbitNumber) => filterUsedRabbit(el.id))
              .filter((el: RabbitNumber) => el.id !== rowData.second_rabbit_number?.id)
              .sort((a, b) => sortByRabbit(a, b))
              .map((el: RabbitNumber) => (
                <MenuItem key={el.id} value={el.id}>
                  {el.serial_number}
                </MenuItem>
              ))
          )}
        </Select>
        {!isSecondRabbitActive && !rowData?.second_rabbit_number ? (
          <IconButton
            color="primary"
            onClick={() => setSecondRabbitActive(!isSecondRabbitActive)}
            disabled={conditionForDisabledEdit}
          >
            <Add />
          </IconButton>
        ) : (
          <Select
            fullWidth
            className={classes.selectFieldSerialNumberWide}
            label="DA Rabbit"
            name="second-da-rabbit"
            onOpen={handleGetRabbit}
            disabled={conditionForDisabledEdit}
            value={rowData?.second_rabbit_number?.id || 0}
            onChange={(e) =>
              handleUpdateSerialNumber(rowData.id, { second_rabbit_number: Number(e.target.value) })
            }
          >
            <MenuItem value={0}>---</MenuItem>
            {!rabbitNumbers.length ? secondRabbitLabel : null}
            {loading ? (
              <MenuItem key="loading" disabled>
                <CircularProgress />
              </MenuItem>
            ) : (
              rabbitNumbers
                .filter((el: RabbitNumber) => filterUsedRabbit(el.id))
                .filter((el: RabbitNumber) => el.id !== rowData.rabbit_number?.id)
                .sort((a, b) => sortByRabbit(a, b))
                .map((el: RabbitNumber) => (
                  <MenuItem key={el.id} value={el.id}>
                    {el.serial_number}
                  </MenuItem>
                ))
            )}
          </Select>
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={rowData.returned_rabbit}
              onChange={handleChangeCheckBox}
              name="returned_rabbit"
              disabled={conditionForDisabledEdit}
            />
          }
          labelPlacement={"top"}
          label="Returned?"
        />
      </TableCell>
      <TableCell className={`${classes.tableCellBig} ${classes.tableCellWide}`}>
        <Select
          fullWidth
          className={classes.selectFieldSerialNumber}
          label="DA Dolly"
          name="da-dolly"
          onOpen={handleGetDolly}
          disabled={conditionForDisabledEdit}
          value={rowData?.dolly_number?.id || 0}
          onChange={(e) =>
            handleUpdateSerialNumber(rowData.id, { dolly_number: Number(e.target.value) })
          }
        >
          <MenuItem value={0}>---</MenuItem>
          {!dollyNumbers.length ? dollyLabel : null}
          {loading ? (
            <MenuItem key="loading" disabled>
              <CircularProgress />
            </MenuItem>
          ) : (
            dollyNumbers
              .filter((el: DollyNumber) => filterUsedDolly(el.id))
              .filter((el: DollyNumber) => el.id !== rowData.second_dolly_number?.id)
              .sort(
                (a: DollyNumber, b: DollyNumber) =>
                  Number(a.serial_number) - Number(b.serial_number),
              )
              .map((el: DollyNumber) => (
                <MenuItem key={el.id} value={el.id}>
                  {el.serial_number}
                </MenuItem>
              ))
          )}
        </Select>
        {!isSecondDollyActive && !rowData?.second_dolly_number ? (
          <IconButton
            color="primary"
            onClick={() => setSecondDollyActive(!isSecondDollyActive)}
            disabled={conditionForDisabledEdit}
          >
            <Add />
          </IconButton>
        ) : (
          <Select
            fullWidth
            className={classes.selectFieldSerialNumber}
            label="DA Dolly"
            name="second-da-dolly"
            onOpen={handleGetDolly}
            disabled={conditionForDisabledEdit}
            value={rowData?.second_dolly_number?.id || 0}
            onChange={(e) =>
              handleUpdateSerialNumber(rowData.id, { second_dolly_number: Number(e.target.value) })
            }
          >
            <MenuItem value={0}>---</MenuItem>
            {!dollyNumbers.length ? secondDollyLabel : null}
            {loading ? (
              <MenuItem key="loading" disabled>
                <CircularProgress />
              </MenuItem>
            ) : (
              dollyNumbers
                .filter((el: DollyNumber) => filterUsedDolly(el.id))
                .filter((el: DollyNumber) => el.id !== rowData.dolly_number?.id)
                .sort(
                  (a: DollyNumber, b: DollyNumber) =>
                    Number(a.serial_number) - Number(b.serial_number),
                )
                .map((el: DollyNumber) => (
                  <MenuItem key={el.id} value={el.id}>
                    {el.serial_number}
                  </MenuItem>
                ))
            )}
          </Select>
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={rowData.returned_dolly}
              onChange={handleChangeCheckBox}
              name="returned_dolly"
              disabled={conditionForDisabledEdit}
            />
          }
          labelPlacement={"top"}
          label="Returned?"
        />
      </TableCell>
      {/* <TableCell className={classes.tableCellSmall}>
        <TextField
          name="rabbit"
          defaultValue={rowData.rabbit}
          disabled={conditionForDisabledEdit}
          onBlur={inputValueBlur}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="dolly_serial_number"
          disabled={conditionForDisabledEdit}
          defaultValue={rowData.dolly_serial_number}
          onBlur={inputValueBlur}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <Checkbox
          checked={rowData.rabbit_checklist}
          onChange={handleChangeCheckBox}
          name="rabbit_checklist"
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <Checkbox
          checked={rowData.dolly}
          onChange={handleChangeCheckBox}
          name="dolly"
          disabled={conditionForDisabledEdit}
        />
      </TableCell> */}
      <TableCell className={classes.tableCellSmall}>
        <Checkbox
          checked={rowData.ontime_attendance}
          onChange={handleChangeCheckBox}
          name="ontime_attendance"
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <Checkbox
          checked={rowData.launchpad_support}
          onChange={handleChangeCheckBox}
          name="launchpad_support"
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <Checkbox
          checked={rowData.morning_checklist}
          onChange={handleChangeCheckBox}
          name="morning_checklist"
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <Checkbox checked={rowData.rescue} name="rescue" disabled />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <Checkbox
          checked={rowData.violation}
          onChange={handleChangeCheckBox}
          name="violation"
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      {/* <TableCell className={classes.tableCellSmall}>
        <Checkbox
          checked={rowData.defects}
          onChange={handleChangeCheckBox}
          name="defects"
          disabled={conditionForDisabledEdit}
        />
      </TableCell> */}
      <TableCell className={classes.tableCellSmall}>
        <TextField
          className={classes.input}
          name="number_of_stops"
          defaultValue={rowData.number_of_stops}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("number_of_stops", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="number_of_packages"
          defaultValue={rowData.number_of_packages}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("number_of_packages", e.target.value)}
          className={classes.input}
          disabled={conditionForDisabledEdit}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
        />
      </TableCell>
      {/* <TableCell className={classes.tableCellSmall}>
        <TextField
          name="first_stop_time"
          type="time"
          defaultValue="00:00"
          value={rowData.first_stop_time}
          onChange={inputTimeValueChange}
          disabled={conditionForDisabledEdit}
        />
      </TableCell> */}
      {/* <TableCell className={classes.tableCellSmall}>
        <TextField
          name="planned_end_time"
          type="time"
          defaultValue="00:00"
          value={rowData.planned_end_time}
          onChange={inputTimeValueChange}
          disabled={conditionForDisabledEdit}
        />
      </TableCell> */}
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="actual_end_time"
          type="time"
          defaultValue="00:00"
          value={rowData.actual_end_time}
          onChange={inputTimeValueChange}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="rescue_given"
          defaultValue={rowData.rescue_given}
          onBlur={(e: OnBlurEvent) => rescueValueChange(e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="rescue_taken"
          defaultValue={rowData.rescue_taken}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("rescue_taken", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="uta"
          defaultValue={rowData.uta}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("uta", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="utl"
          defaultValue={rowData.utl}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("utl", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="nsl"
          defaultValue={rowData.nsl}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("nsl", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="bc"
          defaultValue={rowData.bc}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("bc", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="missing"
          defaultValue={rowData.missing}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("missing", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="damage"
          defaultValue={rowData.damage}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("damage", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="fdd"
          defaultValue={rowData.fdd}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("fdd", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="missort"
          defaultValue={rowData.missort}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("missort", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="oc"
          defaultValue={rowData.oc}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("oc", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        <TextField
          name="others"
          defaultValue={rowData.others}
          onBlur={(e: OnBlurEvent) => inputNumberValueChange("others", e.target.value)}
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          className={classes.input}
          disabled={conditionForDisabledEdit}
        />
      </TableCell>
      {/* <TableCell
        className={classes.tableCellSmall}
        style={{ background: "#e0e0e0", borderLeft: `1px solid ${Colors.MAIN}` }}
      >
        <NumericFormat
          name="cod"
          thousandSeparator={true}
          value={codValue}
          className={classes.input}
          prefix={"$"}
          disabled={conditionForDisabledEdit}
          customInput={TextField}
          onValueChange={(values, sourceInfo) => {
            setCodValue(values.floatValue);
          }}
        />
      </TableCell> */}
    </TableRow>
  );
};
