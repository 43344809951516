import { DISPATCHER, MANAGER, SUPERADMIN, SUPERVISOR } from "constants/constants";

export const defaultUserData = {
  autocreated: false,
  date_joined: "",
  date_of_birth: "",
  email: "",
  first_name: "",
  id: 0,
  is_staff: false,
  is_superuser: false,
  last_login: "",
  last_name: "",
  mobile_number: "",
  role: 0,
  role_name: "",
  training_delivered: false,
  transporter_id: "",
  van_group: {
    description: "",
    id: 0,
    name: "",
    supervisor: 0,
  },
  personal_email: "",
  whatsapp_mobile_number: "",
  driver_license: "",
  driver_license_expiry: "",
  knet_user: "",
  agency_name: {
    description: "",
    id: 0,
    name: "",
  },
  station_spots: [],
  can_create_old_shifts: false,
};

export const defaultNavLinkList = [
  {
    id: "dashboard-main",
    name: "Dashboard",
    permissionKey: [SUPERVISOR, MANAGER, SUPERADMIN],
    subList: [
      {
        id: "dashboard",
        name: "Dasboard",
        permissionKey: [SUPERVISOR, MANAGER, SUPERADMIN],
      },
      {
        id: "falcon",
        name: "Falcon",
        permissionKey: [SUPERADMIN],
      },
    ],
  },
  {
    id: "trends-main",
    name: "Trends",
    permissionKey: [MANAGER, SUPERADMIN, SUPERVISOR],
    subList: [
      {
        id: "route-ternds",
        name: "Route Trends",
        permissionKey: [MANAGER, SUPERADMIN, SUPERVISOR],
      },
      {
        id: "deliveries-trends",
        name: "Deliveries Trends",
        permissionKey: [MANAGER, SUPERADMIN, SUPERVISOR],
      },
      {
        id: "returns-trends",
        name: "Returns Trends",
        permissionKey: [MANAGER, SUPERADMIN, SUPERVISOR],
      },
      {
        id: "missing-trends",
        name: "Missing Trends",
        permissionKey: [MANAGER, SUPERADMIN, SUPERVISOR],
      },
    ],
  },
  {
    id: "shift-tracker",
    name: "Shift Tracker",
    permissionKey: [DISPATCHER, MANAGER, SUPERADMIN, SUPERVISOR],
    subList: [],
  },
  {
    id: "amzn-reports-main",
    name: "AMZN Reports",
    permissionKey: [DISPATCHER, MANAGER, SUPERADMIN, SUPERVISOR],
    subList: [
      {
        id: "perfomance",
        name: "Perfomance",
        permissionKey: [DISPATCHER, MANAGER, SUPERADMIN, SUPERVISOR],
      },
      {
        id: "scoreboard",
        name: "Scoreboard",
        permissionKey: [DISPATCHER, MANAGER, SUPERADMIN, SUPERVISOR],
      },
      {
        id: "check-amzn",
        name: "Check AMZN",
        permissionKey: [DISPATCHER, MANAGER, SUPERADMIN, SUPERVISOR],
      },
      {
        id: "act-amzn",
        name: "Act AMZN",
        permissionKey: [DISPATCHER, MANAGER, SUPERADMIN, SUPERVISOR],
      },
    ],
  },
  {
    id: "pulse",
    name: "Pulse",
    permissionKey: [MANAGER, SUPERADMIN, SUPERVISOR],
    subList: [],
  },
  {
    id: "import",
    name: "Import",
    permissionKey: [SUPERVISOR, MANAGER, SUPERADMIN],
    subList: [],
  },
  {
    id: "setup",
    name: "Setup",
    permissionKey: [SUPERADMIN, MANAGER],
    subList: [
      {
        id: "da-setup",
        name: "DA status",
        permissionKey: [MANAGER, SUPERADMIN],
      },
      {
        id: "fleet-setup",
        name: "Fleet status",
        permissionKey: [MANAGER, SUPERADMIN],
      },
    ],
  },
];
