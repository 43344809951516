import { Grid, Paper } from "@material-ui/core";
import { FC, useState, useEffect } from "react";
import { useStyles } from "../styles";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { handleGetDollyReports } from "../PerformanceCharts/requests";
import { DollyReportsTable } from "../PerfomanceTables/DollyReportsTable";
import { DollyReportsChart } from "../PerformanceCharts/DollyReportsChart";
import { PerformaceSeiralNumber } from "types/Performance";

interface PropsType {
  station: string;
}

const DollyReportsContent: FC<PropsType> = ({ station }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const { perfomanceSettings } = useTypeSelectors((state) => state.perfomanceScores);

  const { date, selectType } = perfomanceSettings;

  useEffect(() => {
    const params = {
      station,
      ...(selectType === "month" && { month: +date?.month, year: +date?.year }),
      ...(selectType === "week" && { week: +date?.week, year: +date?.year }),
      ...(selectType === "daily" && { day: date?.day }),
    };

    handleGetDollyReports(params, setRequestData, setLoading);
  }, [station, date?.day, date?.year, date?.month, date?.week, selectType]);

  const filteredData = requestData
    ?.sort((a: PerformaceSeiralNumber, b: PerformaceSeiralNumber) => b?.count - a?.count)
    ?.filter((item: PerformaceSeiralNumber) => item.serial_number !== null);

  return (
    <>
      <Grid className={classes.gridItem} item sm={12} lg={6} xl={12}>
        <Paper className={classes.graphPaper}>
          <DollyReportsChart loading={loading} currentData={filteredData} />
        </Paper>
      </Grid>
      <Grid className={classes.gridItem} item sm={12} lg={6} xl={12}>
        <Paper className={classes.graphPaper}>
          <DollyReportsTable loading={loading} currentData={filteredData} />
        </Paper>
      </Grid>
    </>
  );
};

export default DollyReportsContent;
