export const DA_TABLE_HEADERS_COLUMN = [
  { field: "small", id: "checkbox", label: ""},
  { field: "small", id: "id", label: "ID", hasSortLabel: true },
  { field: "small", id: "name", label: "Full Name" },
  { field: "small", id: "transporter_id", label: "Transporter ID", hasSortLabel: true },
  { field: "small", id: "driver_license", label: "Driver License", hasSortLabel: true},
  { field: "small", id: "badge", label: "Badge", hasSortLabel: true },
  { field: "small", id: "email", label: "Email", hasSortLabel: true },
  { field: "small", id: "is_active", label: "Status", hasSortLabel: true },
  { field: "small", id: "last_status_change", label: "Last Status Change", hasSortLabel: true },
  { field: "small", id: "station_spots", label: "Station Spots", hasSortLabel: true },
];

export const FLEET_TABLE_HEADERS_COLUMN = [
  { field: "small", id: "checkbox", label: ""},
  { field: "small", id: "id", label: "ID", hasSortLabel: true },
  { field: "small", id: "number", label: "Fleet Number", hasSortLabel: true },
  { field: "small", id: "van_group", label: "Fleet Name", hasSortLabel: true },
  { field: "small", id: "fleet_size", label: "Fleet Size", hasSortLabel: true },
  { field: "small", id: "supervisor", label: "Supervisor" },
  { field: "small", id: "available", label: "Status", hasSortLabel: true },
  { field: "small", id: "last_status_change", label: "Last Status Change", hasSortLabel: true },
  { field: "small", id: "station", label: "Station", hasSortLabel: true },
  { field: "small", id: "type", label: "Type" },
];
