import { useHistory, useParams } from "react-router";

export const useNav = () => {
  const history = useHistory();
  const id = Number(useParams<{ id?: string }>()?.id) || null;
  const slug = String(useParams<{ slug?: string }>()?.slug) || null;

  return {
    pageParams: { id, slug },
    goBack: () => history.goBack(),
    viewReports: () => history.push(`/das-reports`),
    viewReport: (id: number) => history.push(`/das-reports/${id}`),
    viewAccount: () => history.push(`/account`),
    viewDashboard: () => history.push(`/dashboard`),
    viewDashboardTrends: (slug: string) => history.push(`/dashboard/${slug}`),
    viewImport: () => history.push(`/import`),
    viewScorecard: () => history.push(`/scorecard`),
    viewAmznScoreboard: () => history.push(`/amzn-scoreboard`),
    viewCheck: () => history.push(`/check`),
    viewCurrentWeekCheck: (id: number) => history.push(`/check/${id}`),
    viewAct: () => history.push(`/act`),
    viewIkeaCheck: () => history.push(`/check-ikea`),
    viewIkeaAct: () => history.push(`/act-ikea`),
    viewCurrentWeekIkeaCheck: (id: number) => history.push(`/check-ikea/${id}`),
    viewGraphs: () => history.push(`/graphs`),
    viewPerfomance: () => history.push(`/performance`),
    viewFalcon: () => history.push(`/falcon`),
    viewSettings: () => history.push(`/settings`),
    viewDaAndFleets: () => history.push(`/da-and-fleets`),
    viewDas: () => history.push(`/da-status`),
    viewFleets: () => history.push(`/fleet-status`),
  };
};
