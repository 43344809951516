import { FC, useState } from "react";
import {
  DialogContent,
  DialogActions,
  Typography,
  FormControl,
  Button,
  TextField,
  FormLabel,
  Box,
  IconButton,
} from "@material-ui/core";
import api from "api";
import PdfIcon from "icon/pdfIcon.png";
import ClearIcon from "@material-ui/icons/Clear";
import { ellipsis } from "utils/formats";
import { closeModal } from "actions/modalActions";
import { useDispatch } from "react-redux";
import { useDialogStyles } from "../styles";
import { OnChangeEvent } from "types/general";
import { customToast } from "hooks/toast";
import { DialogButton } from "components/buttons/DialogButton";

interface UploadFileDialogContentProps {}

export const UploadFileDialog: FC<UploadFileDialogContentProps> = () => {
  const classes = useDialogStyles();
  const [file, setFile] = useState<File | null>(null);
  const [page, setPage] = useState<number>(0);
  const dispatch = useDispatch();

  const onClose = () => dispatch(closeModal());

  const handleClose = () => {
    setFile(null);
    setPage(0);
    onClose();
  };

  const handleSubmit = (file: File | null, page_numbers: number) => {
    if (file != null) {
      handleClose();
      const request: any = new FormData();
      request.append("pdf_file", file);
      request.append("page_numbers", page_numbers);
      api.uploadPdf(request).then((res) => {
        if (res.data) {
          customToast("success", "PDF file was uploaded!");
        }
      });
    }
  };

  const onClear = () => {
    setFile(null);
  };

  return (
    <>
      <DialogContent
        data-testid="uploadFileDialog"
        className={classes.modelContentDefaults}
        style={{ justifyContent: "space-between" }}
      >
        <FormLabel htmlFor="btn-upload">
          <input
            id="btn-upload"
            name="btn-upload"
            style={{ display: "none" }}
            type="file"
            accept="application/pdf"
            onChange={(e) => {
              if (e?.target?.files?.[0]) {
                setFile(e?.target?.files?.[0]);
              }
            }}
          />
          <Button
            fullWidth
            className="btn-choose"
            component="span"
            color="primary"
            variant="contained"
          >
            Choose File
          </Button>
        </FormLabel>
        {file ? <FileCard file={file} onClear={onClear} /> : null}
        <FormControl style={{ marginTop: 10, width: "100%" }}>
          <TextField
            fullWidth
            name="page_number"
            type="number"
            value={page}
            onChange={(e: OnChangeEvent) => setPage(Number(e.target.value))}
            label={"Page number"}
            placeholder="Enter the page where table starts"
            InputProps={{ inputProps: { min: 0 } }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions data-testid="uploadFileDialogActions" className={classes.dialogActions}>
        <DialogButton
          testId={"cancelUploadFileDialogeButton"}
          buttonName={"Cancel"}
          onClick={onClose}
          variant="contained"
          color="secondary"
        />
        <DialogButton
          testId={"uploadUploadFileDialogButton"}
          buttonName={"Upload"}
          onClick={() => handleSubmit(file, page)}
          variant="contained"
          color="primary"
          disabled={file === null || !page}
        />
      </DialogActions>
    </>
  );
};

const FileCard = ({ file, onClear }: { file: File; onClear: () => void }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img src={PdfIcon} height="40" alt="logo" />
        <Box sx={{ mx: 2 }}>
          <Typography>
            {ellipsis(file.name)} ({Number(file.size / 1000000).toFixed(2)} MB)
          </Typography>
        </Box>
      </Box>
      <IconButton onClick={onClear}>
        <ClearIcon />
      </IconButton>
    </Box>
  );
};
