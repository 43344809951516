import { Box, Grid, Paper } from "@material-ui/core";
import { FC } from "react";
import { useStyles } from "./styles";
import { RenderMainScoreboard } from "./RenderMainScoreBoard";
import { PerfomaceHead } from "./PerformanceHead";
import { ReliabilityChart } from "./PerformanceCharts/ReliabilityChart";
import { useTypeSelectors } from "hooks/useTypeSelectors";

interface PropsType {}

const PerfomancePage: FC<PropsType> = () => {
  const classes = useStyles();
  const { perfomanceSettings } = useTypeSelectors((state) => state.perfomanceScores);

  return (
    <Box data-testid="testPerfomancePage" className={classes.root}>
      <Paper className={classes.paper}>
        <PerfomaceHead />
      </Paper>
      <Grid container spacing={2} className={classes.container}>
        {perfomanceSettings?.currentScorecard === "TL_PERFORMANCE" ||
        perfomanceSettings?.currentScorecard === "ROLES" ||
        perfomanceSettings?.currentScorecard === "DA_SCORE_TREND" ||
        perfomanceSettings?.currentScorecard === "MISSING_DELIVERIES" ||
        perfomanceSettings?.currentScorecard === "CASH_ON_DELIVERY" ||
        perfomanceSettings?.currentScorecard === "WORST_OF_DAR" ||
        perfomanceSettings?.currentScorecard === "PERFORMANCE_SCORECARD" ? (
          <Grid className={classes.gridItem} item sm={12} lg={6} xl={10}>
            <Paper className={classes.graphPaper}>
              <Box className={classes.performersChartBox}>{RenderMainScoreboard()}</Box>
            </Paper>
          </Grid>
        ) : (
          RenderMainScoreboard()
        )}
        {perfomanceSettings?.currentScorecard === "PERFORMANCE_SCORECARD" && (
          <Grid className={classes.gridItem} item sm={12} lg={6} xl={12}>
            <Paper className={classes.graphPaper}>
              <ReliabilityChart />
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PerfomancePage;
