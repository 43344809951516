import { SettingsConstants } from "constants/constants";
import { Dispatch } from "redux";
import { RootState } from "reducers/rootReducer";

export const setNavLinks =
  (linkId: string, status: boolean, linkData: any) =>
  (dispatch: Dispatch, getStore: () => RootState) => {
    const { activeNavLinkList } = getStore()?.settings;

    dispatch({
      type: SettingsConstants.SETTINGS_EDIT_ACTIVE_NAVIGATION_MENU,
      payload: status
        ? [...activeNavLinkList, linkData]
        : activeNavLinkList.filter((item) => item.id !== linkId),
    });
  };

export const setNavSubLinks =
  (parentLinkId: string, linkId: string, status: boolean, linkData: any) =>
  (dispatch: Dispatch, getStore: () => RootState) => {
    const { activeNavLinkList } = getStore()?.settings;

    dispatch({
      type: SettingsConstants.SETTINGS_EDIT_ACTIVE_NAVIGATION_MENU,
      payload: activeNavLinkList?.map((item) =>
        item.id === parentLinkId
          ? {
              ...item,
              subList: status
                ? [...item.subList, linkData]
                : item.subList.filter((item) => item.id !== linkId),
            }
          : item,
      ),
    });
  };
