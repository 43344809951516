import { FC } from "react";
import { Box, Paper } from "@material-ui/core";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { DaHead } from "./DaHead";
import { useStyles } from "./styles";
import { DaListTable } from "./DaListTable";
import Loader from "router/components/Loader";

const DaAndFleetsPage: FC = () => {
  const classes = useStyles();
  const { loadingData } = useTypeSelectors((state) => state.daAndFleets);

  return (
    <Box className={classes.root} id="Table">
      <Loader isLoading={loadingData} />
      <Paper className={classes.paper}>
        <DaHead />
      </Paper>
      <DaListTable />
    </Box>
  );
};

export default DaAndFleetsPage;
