import { RabbitNumber } from "types/Report";

export const getDateNumber = (type:string|undefined, value:string) => {    
    switch (type) {
        case "month":
            return value.split("-")[1]
        case "week":
            return value.split("-W")[1]
        default:
            return value;
    }
}

export const sortByRabbit = (a: RabbitNumber, b: RabbitNumber) => {
    let strA = a.serial_number.match(/\d+/);
    let strB = b.serial_number.match(/\d+/);
    return strA && strB ? parseInt(strA[0]) - parseInt(strB[0]) : 0;
};