import api, { apiErrorHandler } from "api";
import { FalconConstants } from "constants/constants";
import { RootState } from "reducers/rootReducer";
import { Dispatch } from "redux";
import { FalconCommentData } from "types/Falcon";

export const getFalconData =
  (params: { station?: string; year?: number; month?: number; week?: number }) =>
  (dispatch: Dispatch) => {
    const onError = () => dispatch({ type: FalconConstants.FALCON_TABLE_GET_DATA_ERROR });
    dispatch({ type: FalconConstants.FALCON_TABLE_GET_DATA_LOADING });
    api
      .getFalconData(params)
      .then((res) => {
        if (res?.data) {
          dispatch({
            type: FalconConstants.FALCON_TABLE_GET_DATA_SUCCESS,
            payload: {
              dateList: res?.data.date_list,
              standartList: res?.data.standard_list,
              planList: res?.data.plan_list,
              commentList: res?.data.comment_list,
            },
          });
        }
      })
      .catch(apiErrorHandler(onError));
  };

export const falconEditPlan =
  (date: string | number | undefined, routes: number) => (dispatch: Dispatch, getStore: () => RootState) => {
    const { falconFilters } = getStore()?.falconTable;
    const onError = () => dispatch({ type: FalconConstants.FALCON_TABLE_GET_DATA_ERROR });
    dispatch({ type: FalconConstants.FALCON_TABLE_GET_DATA_LOADING });
    api
      .createOrUpdateReportPlan({
        date,
        routes,
        station: falconFilters.station,
      })
      .then((res) => {
        if (res?.data) {
          dispatch({ type: FalconConstants.FALCON_TABLE_DISABLE_LOADING });
        }
      })
      .catch(apiErrorHandler(onError));
  };

export const falconEditComment =
  (date: string | number | undefined, comment: string, matchStatus: number | undefined) =>
  (dispatch: Dispatch, getStore: () => RootState) => {
    const { falconData, falconFilters } = getStore()?.falconTable;
    const onError = () => dispatch({ type: FalconConstants.FALCON_TABLE_GET_DATA_ERROR });
    dispatch({ type: FalconConstants.FALCON_TABLE_GET_DATA_LOADING });
    api
      .createOrUpdateReportComment({
        date,
        comment,
        matchStatus: matchStatus,
        station: falconFilters.station,
      })
      .then((res) => {
        if (res?.data?.data[0]) {
          const res_is_created = res?.data?.created;
          const res_report_date = JSON.parse(res?.data?.data)[0].fields.report_date.split("T")[0];
          const res_report_comment = JSON.parse(res?.data?.data)[0].fields.report_comment;
          const res_is_match = JSON.parse(res?.data?.data)[0].fields.is_match;

          const updatedCommentList = falconData.commentList.map((item: FalconCommentData) =>
            item?.report_date === res_report_date
              ? { report_date: item?.report_date, report_comment: res_report_comment, is_match: res_is_match }
              : item,
          );
          
          dispatch({
            type: FalconConstants.FALCON_TABLE_UPDATE_COMMENTS,
            payload: res_is_created
              ? [
                  { report_date: res_report_date, report_comment: res_report_comment },
                  ...falconData.commentList,
                ]
              : updatedCommentList,
          });
        }
      })
      .catch(apiErrorHandler(onError));
  };

export const falconSetStation =
  (station?: string) => (dispatch: Dispatch, getStore: () => RootState) => {
    const { falconFilters } = getStore()?.falconTable;
    dispatch({
      type: FalconConstants.FALCON_PAGE_CHANGE_FILTERS,
      payload: { ...falconFilters, station: station },
    });
  };

export const falconSetDate =
  (data: { week?: string; month?: string; year?: string }) =>
  (dispatch: Dispatch, getStore: () => RootState) => {
    const { falconFilters } = getStore()?.falconTable;
    dispatch({
      type: FalconConstants.FALCON_PAGE_CHANGE_DATE_FILTERS,
      payload: { ...falconFilters?.date, ...data },
    });
  };

export const falconSetType = (type: string) => (dispatch: Dispatch) => {
  dispatch({
    type: FalconConstants.FALCON_PAGE_CHANGE_TYPE,
    payload: type,
  });
};
