import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "constants/constants";

export const useStyles = makeStyles((theme) => ({
  tableRoot: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    overflow: "auto",
  },
  tableContainer: {
    boxShadow: "none",
    borderRadius: 15,
    border: "none",
    height: 0,
    minHeight: "100%",
  },
  tableBody: {
    height: 0,
    minHeight: "100%",
  },
  emptyTable: {
    marginTop: theme.spacing(2),
    alignSelf: "center",
  },
  tableCell: {
    cursor: "default",
    textAlign: "center",
    padding: theme.spacing(1.5),
  },
  tableHeadWhiteCell: {
    background: Colors.WHITE,
  },
  tableHeadTypography: {
    fontSize: 16,
    fontWeight: 600,
  },
  "@media (max-width: 1280px)": {
    tableRoot: {
      height: 400,
      overflow: "auto",
    },
  },
}));
