import { FC } from "react";
import { Colors } from "constants/constants";
import { Box } from "@material-ui/core";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  BarElement,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { PerformaceSeiralNumber } from "types/Performance";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  BarElement,
  LineController,
  BarController,
);

interface DollyReportsChartProps {
  loading: boolean;
  currentData: PerformaceSeiralNumber[];
}

export const DollyReportsChart: FC<DollyReportsChartProps> = ({ loading, currentData }) => {
  const labels = currentData
    .map((el: { serial_number: string; count: number }) => el.serial_number)
    .reverse();

  const dataChart = {
    labels,
    datasets: [
      {
        type: "bar" as const,
        label: "Counter",
        data: currentData
          .map((el: { serial_number: string; count: number }, index) => (el.count ? el.count : 0))
          .reverse(),
        backgroundColor: Colors.PASTEL_BLUE,
        minBarLength: 3,
        barThickness: 15,
        borderRadius: 25,
      },
    ],
  };

  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        boxSizing: "border-box",
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {!loading && (
        //@ts-ignore
        <Chart options={options} data={dataChart} />
      )}
    </Box>
  );
};

const options = {
  responsive: true,
  scales: {
    y: {
      title: {
        display: false,
        text: "Value",
      },
      min: 0,
      ticks: {
        color: `${Colors.MAIN}`,
        font: {
          weight: "700",
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: `${Colors.MAIN}`,
        font: {
          weight: "700",
        },
      },
    },
  },
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: true,
      text: "Dolly Reports by Serial Number",
    },
  },
};
