import { TableRow, TableCell } from "@material-ui/core";
import { FC } from "react";
import { RenderFalconCell } from "./RenderFalconCell";
import { useStyles } from "./styles";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import {
  FalconDataLists,
  FalconPlanData,
  FalconTableRow,
  FalconStandardData,
  DateLabelType,
} from "types/Falcon";

interface FalconRowProps {
  tableRow: FalconTableRow;
  headColumns: DateLabelType[];
}

export const FalconRow: FC<FalconRowProps> = ({ tableRow, headColumns }) => {
  const classes = useStyles();
  const { falconData, falconFilters } = useTypeSelectors((state) => state.falconTable);

  const dateType =
    falconFilters.selectType === "week" ? "report__report_date" : "report__week_number";
  const planDateType = falconFilters.selectType === "week" ? "report_date" : "week_number";

  return (
    <TableRow key={`${tableRow.id}`} hover>
      <TableCell
        className={`${tableRow?.id === "planned-routes" && classes.frameBorderTop} ${tableRow?.id === "difference-routes" && classes.frameBorderBottom}`}
        style={{
          fontWeight: 700,
          fontSize: 16,
        }}
        colSpan={2}
      >
        {tableRow.label}
      </TableCell>
      {headColumns?.map((headColumn: DateLabelType) =>
        RenderFalconCell(
          classes,
          tableRow,
          falconData.standardList.filter((item: any) => item[dateType] === headColumn.date),
          falconData.planList.filter((item: any) => item[planDateType] === headColumn.date),
          headColumn?.date,
        ),
      )}
      <TableCell
        className={`${classes.tableCell} ${tableRow?.id === "planned-routes" && classes.frameBorderTop} ${tableRow?.id === "difference-routes" && classes.frameBorderBottom}`}
        colSpan={2}
      >
        {getTotalScore(tableRow, falconData)}
      </TableCell>
    </TableRow>
  );
};

const getTotalScore = (row: FalconTableRow, data: FalconDataLists) => {
  switch (row?.id) {
    case "standard-cargo":
    case "standard-large":
    case "standard-cube":
    case "standard-medium":
    case "da-training":
    case "flex-cargo-9":
    case "flex-cargo-8":
    case "flex-cargo-7":
    case "flex-cargo-6":
    case "flex-cargo-5":
    case "flex-cargo-4":
    case "flex-cargo-3":
    case "flex-cargo-2":
    case "flex-cargo-1":
    case "flex-cube-9":
    case "flex-cube-8":
    case "flex-cube-7":
    case "flex-cube-6":
    case "flex-cube-5":
    case "flex-cube-4":
    case "flex-cube-3":
    case "flex-cube-2":
    case "flex-cube-1":
    case "flex-large-9":
    case "flex-large-8":
    case "flex-large-7":
    case "flex-large-6":
    case "flex-large-5":
    case "flex-large-4":
    case "flex-large-3":
    case "flex-large-2":
    case "flex-large-1":
    case "flex-medium-9":
    case "flex-medium-8":
    case "flex-medium-7":
    case "flex-medium-6":
    case "flex-medium-5":
    case "flex-medium-4":
    case "flex-medium-3":
    case "flex-medium-2":
    case "flex-medium-1":
      return data.standardList
        .filter(
          (item: FalconStandardData) =>
            item?.role === row.role &&
            row?.vans?.includes(item?.van__fleet_size) &&
            item?.role_hours === row.hour,
        )
        .reduce((a: number, b: FalconStandardData) => a + b.routes, 0);
    case "planned-routes":
      return data.planList?.reduce((a: number, b: FalconPlanData) => a + b.number_of_routes, 0);
    case "actual-routes":
      return data.standardList
        .filter((item: FalconStandardData) => item.role !== 7 && item.role !== 4)
        .reduce((a: number, b: FalconStandardData) => a + b.routes, 0);
    case "cargo-cancelled":
    case "large-cancelled":
    case "medium-cancelled":
    case "cube-cancelled":
      return data.standardList
        ?.filter(
          (item: FalconStandardData) =>
            row.role === item.role &&
            (row?.vans?.includes(item?.cancelled_fleet_size) ||
              row?.vans?.includes(item?.van__fleet_size)),
        )
        .reduce((a: number, b: FalconStandardData) => a + b.routes, 0);
    default:
      return false;
  }
};
