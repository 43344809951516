import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { dasBoardReducer } from "./dasBoardReducer";
import { amazonReportsReducer } from "./amazonReportsReducer";
import { dashboardReducer } from "./dashboardReducer";
import { weightsDataReducer } from "./weightsDataReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { fleetSettingsReducer } from "./fleetSettingsReducer";
import { scorecardReducer } from "./scorecardReducer";
import { checkReducer } from "./checkReducer";
import { actReducer } from "./actReducer";
import { actIkeaReducer } from "./actIkeaReducer";
import { checkIkeaReducer } from "./checkIkeaReducer";
import { modalReducer } from "./modalReducer";
import { customGraphReducer } from "./customGraphReducer";
import { perfomanceReducer } from "./performanceReducer";
import { budgetReducer } from "./budgetReducer";
import { daAndFleetReducer } from "./daAndFleetReducer";
import { falconReducer } from "./falconReducer";
import { settingsReducer } from "./settingsReducer";

const persistConfigDasBoard = {
  key: "courierDasBoard",
  storage,
  whitelist: ["currentReport", "currentReportStation", "currentReportDate", "dasBoardRowsPerPage"],
};

const persistConfigDashboard = {
  key: "courierDashboard",
  storage,
  whitelist: ["dashboardScoreSettings", "dashboardTableRowsPerPage"],
};

const persistConfigImportPDF = {
  key: "courierImport",
  storage,
  whitelist: ["importPDFTableRowsPerPage", "isAmazonPage"],
};

const persistConfigScorecard = {
  key: "courierScorecard",
  storage,
};

const persistConfigIkeaScorecard = {
  key: "courierIkeaScorecard",
  storage,
};

const persistConfigAct = {
  key: "courierActScorecard",
  storage,
};

const persistConfigActIkea = {
  key: "courierActIkeaScorecard",
  storage,
};

const persistConfigModal = {
  key: "courierModal",
  storage,
};

const persistConfigCustomGraph = {
  key: "courierCustomGraph",
  storage,
};

const persistConfigPerfomance = {
  key: "courierPerfomance",
  storage,
};

const persistConfigBudgets = {
  key: "courierBudgets",
  storage,
};

const persistConfigDaAndFleets = {
  key: "courierDaAndFleets",
  storage,
};

const persistConfigFalcon = {
  key: "courierFalcon",
  storage,
};

const persistConfigSettings = {
  key: "courierSettings",
  storage,
};

export const rootReducer = combineReducers({
  auth: authReducer,
  dasBoard: persistReducer(persistConfigDasBoard, dasBoardReducer),
  amazonReports: persistReducer(persistConfigImportPDF, amazonReportsReducer),
  dashboard: persistReducer(persistConfigDashboard, dashboardReducer),
  weightsData: weightsDataReducer,
  fleetSettings: fleetSettingsReducer,
  scoreCardSettings: persistReducer(persistConfigScorecard, scorecardReducer),
  checkTable: persistReducer(persistConfigScorecard, checkReducer),
  checkIkeaTable: persistReducer(persistConfigIkeaScorecard, checkIkeaReducer),
  actTable: persistReducer(persistConfigAct, actReducer),
  actIkeaTable: persistReducer(persistConfigActIkea, actIkeaReducer),
  modalState: persistReducer(persistConfigModal, modalReducer),
  customGraphsState: persistReducer(persistConfigCustomGraph, customGraphReducer),
  perfomanceScores: persistReducer(persistConfigPerfomance, perfomanceReducer),
  budgetBoard: persistReducer(persistConfigBudgets, budgetReducer),
  daAndFleets: persistReducer(persistConfigDaAndFleets, daAndFleetReducer),
  falconTable: persistReducer(persistConfigFalcon, falconReducer),
  settings: persistReducer(persistConfigSettings, settingsReducer),
});

export type RootState = ReturnType<typeof rootReducer>;
