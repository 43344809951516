import api, { apiErrorHandler } from "api";
import { Dispatch } from "redux";
import { DaAndFleetConstants } from "constants/constants";
import { RootState } from "reducers/rootReducer";
import { customToast } from "hooks/toast";

export const getAllDriversData =
  (
    page?: number,
    page_size?: number,
    ordering?: string,
    search_name?: string,
    search_licence?: string,
  ) =>
  async (dispatch: Dispatch) => {
    const onError = () => dispatch({ type: DaAndFleetConstants.DA_FLEET_ERROR });
    dispatch({ type: DaAndFleetConstants.DA_FLEET_LOADING });

    api
      .getDriverUsersList({
        page,
        page_size,
        search: `${search_name} ${search_licence}`,
        ordering,
      })
      .then((res) => {
        if (res?.data) {
          dispatch({
            type: DaAndFleetConstants.DA_FLEET_GET_ALL_DRIVERS_SUCCESS,
            payload: res?.data,
          });
        }
      })
      .catch(apiErrorHandler(onError));
  };

export const getAllFleetsData =
  (page?: number, page_size?: number, ordering?: string) => async (dispatch: Dispatch) => {
    const onError = () => dispatch({ type: DaAndFleetConstants.DA_FLEET_ERROR });
    dispatch({ type: DaAndFleetConstants.DA_FLEET_LOADING });

    api
      .getAllVans({
        page,
        page_size,
        ordering,
      })
      .then((res) => {
        if (res?.data) {
          dispatch({
            type: DaAndFleetConstants.DA_FLEET_GET_ALL_FLEEETS_SUCCESS,
            payload: res?.data,
          });
        }
      })
      .catch(apiErrorHandler(onError));
  };

export const DriverDataRequestEdit =
  (data: {id: number, status: number}) => (dispatch: Dispatch, getStore: () => RootState) => {
    const { driverData } = getStore()?.daAndFleets;
    api
      .updateUserStatus(data)
      .then((res) => {
        if (res.data) {
          customToast("success", "Driver status was changed!");
          const newData = driverData?.driverList?.map((item) =>
            item.id === data?.id
              ? {
                  ...item,
                  last_status_change: res.data.date,
                  ...(data.status === 1 && { is_active: true, is_rehired: false, is_blacklisted: false, is_inactive: false }),
                  ...(data.status === 2 && { is_active: false, is_rehired: true, is_blacklisted: false, is_inactive: false }),
                  ...(data.status === 3 && { is_active: false, is_rehired: false, is_blacklisted: true, is_inactive: false }),
                  ...(data.status === 4 && { is_active: false, is_rehired: false, is_blacklisted: false, is_inactive: true }),
                }
              : item,
          );
          dispatch({
            type: DaAndFleetConstants.UPDATE_DA_SUCCESS,
            payload: newData,
          });
        }
      })
      .catch(
        apiErrorHandler(() => {
          customToast("error", "Failed to update driver status");
        }),
      );
  };

export const FleetDataRequestEdit =
  (id: number, newStatus: number,) => (dispatch: Dispatch, getStore: () => RootState) => {
    const { fleetData } = getStore()?.daAndFleets;
    api
      .updateFleetStatus({
        status: newStatus,
        id: id,
      })
      .then((res) => {
        if (res.data) {
          customToast("success", "Fleet status was changed!");
          const newData = fleetData?.fleetList?.map((item) =>
            item.id === id
              ? {
                  ...item,
                  last_status_change: res.data.date,
                  ...(newStatus === 1 && {
                    available: true,
                    is_off_boarded: false,
                    is_out_of_service: false,
                  }),
                  ...(newStatus === 2 && {
                    available: false,
                    is_off_boarded: false,
                    is_out_of_service: true,
                  }),
                  ...(newStatus === 3 && {
                    available: false,
                    is_off_boarded: true,
                    is_out_of_service: false,
                  }),
                }
              : item,
          );
          dispatch({
            type: DaAndFleetConstants.UPDATE_FLEET_SUCCESS,
            payload: newData,
          });
        }
      })
      .catch(
        apiErrorHandler(() => {
          customToast("error", "Failed to update fleet status");
        }),
      );
  };

export const updateMultipleUsersStatusAction =
  (newStatus: number, idList: number[]) => async (dispatch: Dispatch, getStore: () => RootState) => {
    const { driverData } = getStore()?.daAndFleets;
    api
      .updateUserStatus({
        status: newStatus,
        id_list: idList,
      })
      .then((res) => {
        customToast("success", "Driver statuses was changed!");
        const newData = driverData.driverList.map((item) =>
          idList.includes(item?.id)
            ? {
                ...item,
                last_status_change: res.data.date,
                ...(newStatus === 1 && {
                  is_active: true,
                  is_rehired: false,
                  is_blacklisted: false,
                  is_inactive: false
                }),
                ...(newStatus === 2 && {
                  is_active: false,
                  is_rehired: true,
                  is_blacklisted: false,
                  is_inactive: false
                }),
                ...(newStatus === 3 && {
                  is_active: false,
                  is_rehired: false,
                  is_blacklisted: true,
                  is_inactive: false
                }),
                ...(newStatus === 4 && {
                  is_active: false,
                  is_rehired: false,
                  is_blacklisted: false,
                  is_inactive: true
                }),
              }
            : item,
        );
        dispatch({
          type: DaAndFleetConstants.UPDATE_DA_SUCCESS,
          payload: newData,
        });
      })
      .catch(
        apiErrorHandler(() => {
          customToast("error", "Failed to update drivers status");
        }),
      );
  };

  export const updateMultipleFleetStatusAction =
  (newStatus: number, idList: number[]) => async (dispatch: Dispatch, getStore: () => RootState) => {
    const { fleetData } = getStore()?.daAndFleets;
    api
      .updateFleetStatus({
        status: newStatus,
        id_list: idList,
      })
      .then((res) => {
        customToast("success", "Fleet statuses was changed!");
        const newData = fleetData.fleetList.map((item) =>
          idList.includes(item?.id)
            ? {
                ...item,
                last_status_change: res.data.date,
                ...(newStatus === 1 && {
                  available: true,
                  is_off_boarded: false,
                  is_out_of_service: false,
                }),
                ...(newStatus === 2 && {
                  available: false,
                  is_off_boarded: false,
                  is_out_of_service: true,
                }),
                ...(newStatus === 3 && {
                  available: false,
                  is_off_boarded: true,
                  is_out_of_service: false,
                }),
              }
            : item,
        );
        dispatch({
          type: DaAndFleetConstants.UPDATE_FLEET_SUCCESS,
          payload: newData,
        });
      })
      .catch(
        apiErrorHandler(() => {
          customToast("error", "Failed to update fleet status");
        }),
      );
  };

export const setDaFilters =
  (data: { [key: string]: number | string }) => async (dispatch: Dispatch) => {
    dispatch({
      type: DaAndFleetConstants.DA_FLEET_DA_SET_FILTERS,
      payload: data,
    });
  };

export const setDaTableRowsPerPage = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: DaAndFleetConstants.DA_FLEET_DA_SET_FILTERS,
    payload: { dataTableRowsPerPage: data },
  });
};

export const setDaTablePagginationPage = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: DaAndFleetConstants.DA_FLEET_DA_SET_FILTERS,
    payload: { pageNumber: data },
  });
};

export const setFleetFilters =
  (data: { [key: string]: number | string }) => async (dispatch: Dispatch) => {
    dispatch({
      type: DaAndFleetConstants.DA_FLEET_FLEET_SET_FILTERS,
      payload: data,
    });
  };

export const setFleetTableRowsPerPage = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: DaAndFleetConstants.DA_FLEET_FLEET_SET_FILTERS,
    payload: { dataTableRowsPerPage: data },
  });
};

export const setFleetTablePagginationPage = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: DaAndFleetConstants.DA_FLEET_FLEET_SET_FILTERS,
    payload: { pageNumber: data },
  });
};

export const addOrRemoveIdFromUpdateList =
  (type: string, id: number, globalStateObject: any) => (dispatch: Dispatch) => {
    let newData = null;

    if (!globalStateObject.idListForUpdate.includes(id)) {
      newData = [...globalStateObject.idListForUpdate, id];
    } else {
      newData = [
        ...globalStateObject.idListForUpdate.filter((item: number) => {
          return item !== id;
        }),
      ];
    }
    dispatch({
      type: type,
      payload: newData,
    });
  };
