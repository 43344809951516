import { FC } from "react";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { useLocation } from "react-router-dom";
import {
  Drawer,
  Typography,
  Button,
  makeStyles,
  IconButton,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { useNav } from "hooks/useNav";
import { CollapseSubMenu } from "components/elements/CollapseSubMenu";
import { DISPATCHER, MANAGER, SUPERADMIN, SUPERVISOR, Colors } from "constants/constants";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BarChartIcon from "@material-ui/icons/BarChart";
import CallToActionIcon from "@material-ui/icons/CallToAction";
import DeveloperBoard from "@material-ui/icons/DeveloperBoard";
import Assessment from "@material-ui/icons/Assessment";
import TableChartIcon from "@material-ui/icons/TableChart";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

interface MobileMenuDrawerType {
  isOpen: boolean;
  setIsOpen: () => void;
}

export const MobileMenuDrawer: FC<MobileMenuDrawerType> = ({ isOpen, setIsOpen }) => {
  const classes = useStyles();
  const params = useLocation();
  const { userData } = useTypeSelectors((state) => state.auth);
  const { activeNavLinkList } = useTypeSelectors((state) => state.settings);

  const {
    viewDashboard,
    viewFalcon,
    viewDashboardTrends,
    viewImport,
    viewReports,
    viewScorecard,
    viewCheck,
    viewAct,
    viewAmznScoreboard,
    viewPerfomance,
  } = useNav();

  const listMenu = [
    {
      id: "dashboard-main",
      Icon: DashboardIcon,
      text: "Dashboard",
      link: viewDashboard,
      permission: [MANAGER, SUPERADMIN, SUPERVISOR],
      pathnames: ["/dashboard"],
      subList: [
        {
          id: "dashboard",
          Icon: DashboardIcon,
          text: "Dashboard",
          link: () => viewDashboard(),
          permission: [MANAGER, SUPERADMIN, SUPERVISOR],
          path: "/dashboard",
        },
        {
          id: "falcon",
          Icon: DashboardIcon,
          text: "Falcon",
          link: () => viewFalcon(),
          permission: [SUPERADMIN],
          path: "/falcon",
        },
      ],
    },
    {
      id: "trends-main",
      Icon: DeveloperBoard,
      text: "Trends",
      link: () => null,
      permission: [MANAGER, SUPERADMIN, SUPERVISOR],
      pathnames: [
        "/dashboard/routes",
        "/dashboard/deliveries",
        "/dashboard/returns",
        "/dashboard/missing",
      ],
      subList: [
        {
          id: "route-ternds",
          Icon: BarChartIcon,
          text: "Routes",
          link: () => viewDashboardTrends("routes"),
          permission: [MANAGER, SUPERADMIN, SUPERVISOR],
          path: "/dashboard/routes",
        },
        {
          id: "deliveries-trends",
          Icon: BarChartIcon,
          text: "Deliveries",
          link: () => viewDashboardTrends("deliveries"),
          permission: [MANAGER, SUPERADMIN, SUPERVISOR],
          path: "/dashboard/deliveries",
        },
        {
          id: "returns-trends",
          Icon: BarChartIcon,
          text: "Returns",
          link: () => viewDashboardTrends("returns"),
          permission: [MANAGER, SUPERADMIN, SUPERVISOR],
          path: "/dashboard/returns",
        },
        {
          id: "missing-trends",
          Icon: BarChartIcon,
          text: "Missing",
          link: () => viewDashboardTrends("missing"),
          permission: [MANAGER, SUPERADMIN, SUPERVISOR],
          path: "/dashboard/missing",
        },
      ],
    },
    {
      id: "shift-tracker",
      Icon: DateRangeOutlinedIcon,
      text: "Shift tracker",
      link: viewReports,
      permission: [DISPATCHER, MANAGER, SUPERADMIN, SUPERVISOR],
      pathnames: ["/das-reports"],
      subList: [],
    },
    {
      id: "amzn-reports-main",
      Icon: DeveloperBoard,
      text: "AMZL reports",
      link: () => null,
      permission: [DISPATCHER, MANAGER, SUPERADMIN, SUPERVISOR],
      pathnames: ["/check", "/act", "/amzn-scoreboard", "/performance"],
      subList: [
        {
          id: "perfomance",
          Icon: BarChartIcon,
          text: "Performance",
          link: viewPerfomance,
          permission: [DISPATCHER, MANAGER, SUPERADMIN, SUPERVISOR],
          path: "/performance",
        },
        {
          id: "scoreboard",
          Icon: Assessment,
          text: "Scoreboard",
          link: viewAmznScoreboard,
          permission: [DISPATCHER, MANAGER, SUPERADMIN, SUPERVISOR],
          path: "/amzn-scoreboard",
        },
        {
          id: "check-amzn",
          Icon: TableChartIcon,
          text: "Check (AMZL)",
          link: viewCheck,
          permission: [DISPATCHER, MANAGER, SUPERADMIN, SUPERVISOR],
          path: "/check",
        },
        {
          id: "act-amzn",
          Icon: CallToActionIcon,
          text: "Act (AMZL)",
          link: viewAct,
          permission: [DISPATCHER, MANAGER, SUPERADMIN, SUPERVISOR],
          path: "/act",
        },
      ],
    },
    {
      id: "pulse",
      Icon: BarChartIcon,
      text: "Pulse",
      link: viewScorecard,
      permission: [MANAGER, SUPERADMIN, SUPERVISOR],
      pathnames: ["/scorecard"],
      subList: [],
    },
    {
      id: "import",
      Icon: PublishOutlinedIcon,
      text: "Import",
      link: viewImport,
      permission: [SUPERVISOR, MANAGER, SUPERADMIN],
      pathnames: ["/import"],
      subList: [],
    },
  ];

  return (
    <Drawer
      className={classes.drawer}
      PaperProps={{ style: { minWidth: "100%" } }}
      anchor="left"
      open={isOpen}
    >
      <DialogContent className={classes.drawerContent}>
        <DialogContentText
          style={{
            minWidth: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IconButton className={classes.expandCollapseMenu} onClick={setIsOpen}>
            <KeyboardArrowLeftIcon />
          </IconButton>
          {listMenu.filter((link:any) => activeNavLinkList.some((item:any) => link.id === item.id)).map(({ Icon, ...el }: any) => {
            const acceptPermission = el.permission.some(
              (element: any) => element === userData.role,
            );
            const isActive = el.pathnames.includes(params?.pathname);

            return acceptPermission ? (
              !el.subList.length ? (
                <Button
                  fullWidth
                  color="primary"
                  variant={isActive ? "contained" : "text"}
                  onClick={() => {
                    setIsOpen();
                    el.link();
                  }}
                  disableElevation
                  className={classes.linkMenu}
                  style={!isOpen ? { justifyContent: "center" } : {}}
                >
                  <Icon style={{ color: "currentColor" }} />
                  {isOpen && (
                    <Typography variant="body2" style={{ marginLeft: 10 }}>
                      {el.text}
                    </Typography>
                  )}
                </Button>
              ) : (
                <>
                  <CollapseSubMenu
                    Icon={Icon}
                    data={el}
                    secondAction={setIsOpen}
                    mainClasses={classes}
                    isOptionActive={isActive}
                    isMenuOpen={isOpen}
                  />
                </>
              )
            ) : null;
          })}
        </DialogContentText>
      </DialogContent>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    display: "none",
  },
  drawerContent: {
    width: "100%",
    padding: 0,
    background: theme.palette.primary.main,
  },
  linkMenu: {
    borderRadius: 0,
    width: "100%",
    height: 64,
    display: "flex",
    justifyContent: "flex-start",
    padding: theme.spacing(3, 4),
    color: Colors.WHITE,
    background: theme.palette.primary.main,
    "&.MuiButton-contained": {
      background: "#015a9c",
    },
    "&.MuiButton-text:hover": {
      background: "#0a3e67",
    },
  },
  buttonDrawerWidth: {
    boxSizing: "border-box",
    bottom: 10,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  expandCollapseMenu: {
    color: Colors.WHITE,
    borderRadius: 0,
    background: theme.palette.primary.main,
    "&:hover": {
      background: "#0a3e67",
    },
  },
  "@media (max-width: 750px)": {
    drawer: {
      display: "block",
    },
  },
  "@media (max-width: 550px)": {
    body: {
      marginTop: 55,
    },
  },
}));
