import { FormControl, TextField, Box, makeStyles } from "@material-ui/core";
import { FC, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { OnChangeEvent } from "types/general";

interface CheckDateSelectorProps {
  type: string;
  start_date: string | undefined;
  end_date: string | undefined;
  setRangeDates: (startDate: string, endDate: string) => void;
  removeRangeDates: () => void;
}

export const DateRangeSelector: FC<CheckDateSelectorProps> = ({
  type,
  start_date,
  end_date,
  setRangeDates,
  removeRangeDates,
}) => {
  const [startDate, setStartDate] = useState<string>(start_date || "");
  const [endDate, setEndDate] = useState<string>(end_date || "");
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(setRangeDates(startDate, endDate));
    } else {
      dispatch(removeRangeDates());
    }
  }, [startDate, endDate, dispatch, removeRangeDates, setRangeDates]);

  return (
    <Box className={classes.selectors}>
      <FormControl fullWidth style={{ height: 50 }}>
        <TextField
          label="From:"
          variant="standard"
          name="date_one"
          type={type}
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={(e: OnChangeEvent) => {
            setStartDate(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth style={{ height: 50 }}>
        <TextField
          label="To:"
          variant="standard"
          name="date_two"
          type={type}
          InputLabelProps={{ shrink: true }}
          value={endDate}
          onChange={(e: OnChangeEvent) => {
            setEndDate(e.target.value);
          }}
        />
      </FormControl>
    </Box>
  );
};

export const useStyles = makeStyles((theme) => ({
  selectors: {
    display: "flex",
    width: 400,
    gap: theme.spacing(2),
    margin: theme.spacing(0, 2),
    "@media (max-width: 800px)": {
      width: "100%",
      marginTop: theme.spacing(2),
    },
    "@media (max-width: 700px)": {
      flexDirection: "column",
    },
  },
}));
