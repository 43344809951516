import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "10px",
    borderBottom: "none",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
    boxSizing: "border-box",
    gap: "10px",
    justifyContent: "space-between",
    boxShadow: "none",
    borderRadius: 15,
    borderBottom: "#e8e9ee 2px solid",
  },
  graphPaper: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    boxSizing: "border-box",
    gap: "10px",
    height: "100%",
    justifyContent: "space-between",
    boxShadow: "none",
    borderRadius: 15,
    borderBottom: "#e8e9ee 2px solid",
  },
  perfomanceHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  filterSection: {
    display: "flex",
    alignItems: "center",
    gap: 24,
  },
  titleSettings: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    flexWrap: "wrap",
  },
  container: {
    display: "flex",
  },
  buttonBox: {
    display: "flex",
  },
  performersChartBox: {
    width: "100%",
    height: `100%`,
    margin: "0 auto",
  },
  formGroupBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
  },
  select: {
    display: "flex",
    minWidth: "150px",
    margin: theme.spacing(1),
  },
  leftButton: {
    boxSizing: "border-box",
    borderRadius: "25px 0 0 25px",
    borderRight: "none",
  },
  rightButton: {
    boxSizing: "border-box",
    borderRadius: "0 25px 25px 0",
    borderLeft: "none",
  },
  midButton: {
    boxSizing: "border-box",
    borderRadius: "0",
  },
  gridItem: {
    maxWidth: "50%",
  },
  "@media (max-width: 1050px)": {
    perfomanceHead: {
      flexDirection: "column",
    },
  },
  "@media (max-width: 850px)": {
    titleSettings: {
      flexDirection: "column",
    },
    container: {
      display: "block",
    },
    gridItem: {
      maxWidth: "100%",
    },
  },
}));
