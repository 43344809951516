import { Box, Button } from "@material-ui/core";
import { FC, useEffect } from "react";
import { useStyles } from "./styles";
import { WeekSelect } from "components/elements/WeekSelect";
import { useDateWeekSelector } from "hooks/useDateWeekSelector";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { MonthSelect } from "components/elements/MonthSelect";
import { useDispatch } from "react-redux";
import {
  falconSetDate,
  falconSetStation,
  falconSetType,
  getFalconData,
} from "actions/falconAction";
import { StationSelect } from "components/elements/StationSelect";

interface PropsType {}

const FalconHead: FC<PropsType> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userData } = useTypeSelectors((state) => state.auth);
  const { falconFilters } = useTypeSelectors((state) => state.falconTable);

  const handleChangeExternalSelectType = (select: string) => dispatch(falconSetType(select));

  const handleChangeWeek = (week: string) => dispatch(falconSetDate({ week }));

  const handleChangeMonth = (month: string) => dispatch(falconSetDate({ month }));

  const handleChangeYear = (year: string) => dispatch(falconSetDate({ year }));

  const handleChangeStation = (station: string) => dispatch(falconSetStation(station));

  const {
    week,
    month,
    year,
    selectType,
    dateCondition,
    dateConditionMonth,
    onWeekReduce,
    onWeekIncrease,
    onMonthReduce,
    onMonthIncrease,
    onTypeExternalChange,
  } = useDateWeekSelector({
    defaultWeek: falconFilters?.date?.week,
    defaultMonth: falconFilters?.date?.month,
    defaultYear: falconFilters?.date?.year,
    defaultSelectType: falconFilters?.selectType,
    onSelectTypeSet: handleChangeExternalSelectType,
    onWeekSet: handleChangeWeek,
    onMonthSet: handleChangeMonth,
    onYearSet: handleChangeYear,
  });

  useEffect(() => {
    const params = {
      station: falconFilters.station,
      year: year,
      ...(selectType === "month" && { month: +month }),
      ...(selectType === "week" && { week: +week }),
    };
    dispatch(getFalconData(params));
  }, [week, month, year, falconFilters.station, selectType, dispatch]);

  return (
    <Box className={classes.falconHead}>
      <Box className={classes.buttonBox}>
        <Button
          variant={selectType === "month" ? "contained" : "outlined"}
          className={classes.leftButton}
          onClick={() => onTypeExternalChange("month")}
        >
          Monthly
        </Button>
        <Button
          variant={selectType === "week" ? "contained" : "outlined"}
          className={classes.rightButton}
          onClick={() => onTypeExternalChange("week")}
        >
          Weekly
        </Button>
      </Box>
      <Box>
        {selectType === "week" ? (
          <WeekSelect
            week={week}
            year={year}
            onNext={onWeekIncrease}
            onPrev={onWeekReduce}
            disabled={dateCondition()}
          />
        ) : (
          <MonthSelect
            month={month}
            year={year}
            onNext={onMonthIncrease}
            onPrev={onMonthReduce}
            disabled={dateConditionMonth()}
          />
        )}
      </Box>
      <StationSelect
        value={falconFilters.station}
        onChange={handleChangeStation}
        stations={userData?.station_spots}
      />
    </Box>
  );
};

export default FalconHead;
