import { Box } from "@material-ui/core";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { handleGetDeliveryTrends } from "./requests";
import { Colors } from "constants/constants";
import { formatNumberType } from "utils/formatNUmber";
import { DeliveriesTrendsData } from "types/DashboardRecord";
import { getTrendsLabel } from "utils/graphLabelUtils";

export const ReturnsTrends = () => {
  const [loading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState([]);

  const { dashboardScoreSettings } = useTypeSelectors((state) => state.dashboard);

  useEffect(() => {
    const params = {
      stations: dashboardScoreSettings?.currentStations?.join("_"),
      ...(dashboardScoreSettings?.currentDateTypeLabelDateType === "month" && {
        month_range:`${dashboardScoreSettings.labelDate?.startDate}_${dashboardScoreSettings.labelDate?.endDate}`
      }),
      ...(dashboardScoreSettings?.currentDateTypeLabelDateType === "week" && {
        week_range: `${dashboardScoreSettings.labelDate?.startDate}_${dashboardScoreSettings.labelDate?.endDate}`,
      }),
      ...(dashboardScoreSettings?.currentDateTypeLabelDateType === "day" && {
        start_date: dashboardScoreSettings.labelDate?.startDate,
        end_date: dashboardScoreSettings.labelDate?.endDate,
      }),
      ...(dashboardScoreSettings?.currentDateTypeLabelDateType === "annual" && {
        year: +Number(dashboardScoreSettings.labelDate?.year),
      }),
    };
    handleGetDeliveryTrends(params, setLoading, setRequestData);
  }, [
    dashboardScoreSettings?.currentDateTypeLabelDateType,
    dashboardScoreSettings?.labelDate?.year,
    dashboardScoreSettings?.labelDate?.startDate,
    dashboardScoreSettings?.labelDate?.endDate,
    dashboardScoreSettings.currentStations,
  ]);

  const options = {
    responsive: true,
    scales: {},
    plugins: {
      legend: {
        position: "top" as const,
        display: true,
      },
      title: {
        display: true,
        text: "Returns Trends",
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            let label = "";
            if (context.parsed.y) {
              label = String(formatNumberType(context.parsed.y));
            }
            return label;
          },
        },
      },
    },
  };

  const labels: (string | undefined)[] = requestData?.map((el: DeliveriesTrendsData) =>
    getTrendsLabel(el, dashboardScoreSettings?.currentDateTypeLabelDateType),
  );

  const dataChart: any = {
    labels,
    datasets: [
      {
        label: "Actual",
        data: requestData?.map((el: DeliveriesTrendsData, index: number) =>
          getTrendsLabel(el, dashboardScoreSettings?.currentDateTypeLabelDateType) === labels[index]
            ? el.returns
            : null,
        ),
        backgroundColor: requestData?.map((el: DeliveriesTrendsData, index: number) =>
          getTrendsLabel(el, dashboardScoreSettings?.currentDateTypeLabelDateType) === labels[index]
            ? Colors.PASTEL_BLUE
            : "#fff",
        ),
        minBarLength: 3,
        ...(dashboardScoreSettings?.currentDateTypeLabelDateType === "month"
          ? {
              barThickness: 25,
              borderColor: Colors.WHITE,
              borderWidth: 2,
            }
          : {
              barThickness: 15,
            }),
      },
    ],
  };

  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        boxSizing: "border-box",
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {!loading && (
        //@ts-ignore
        <Bar options={options} data={dataChart} />
      )}
    </Box>
  );
};
