import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { useStyles } from "./styles";
import FalconHead from "./FalconHead";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { FalconRow } from "./FalconRow";
import { TABLE_SIDE_ROWS } from "./falconTable";
import { DateLabelType, FalconDateData, FalconStandardData, FalconTableRow } from "types/Falcon";
import { allMonthsShort } from "constants/constants";
import { FalconTableHeadCell } from "./FalconTableHeadCell";
import Loader from "router/components/Loader";

interface PropsType {}

const FalconPage: FC<PropsType> = () => {
  const classes = useStyles();
  const { falconFilters, falconData, loadingData } = useTypeSelectors((state) => state.falconTable);

  const [headColumns, setHeadColumns] = useState<DateLabelType[]>([]);

  useEffect(() => {
    switch (falconFilters?.selectType) {
      case "week":
        if (falconData?.dateList[0]?.day) {
          const weekLabels = falconData?.dateList
            .map((item: FalconDateData) => ({ label: item?.day, date: item?.date }));
          setHeadColumns(weekLabels);
        }
        break;
      case "month":
        if (falconData?.dateList[0].week_number) {
          const monthLabels = falconData?.dateList
            .map((item: FalconDateData) => ({ label: "Week", date: item?.week_number }));
          setHeadColumns(monthLabels);
        }
        break;
    }
  }, [falconFilters?.selectType, falconData?.dateList]);

  return (
    <Box className={classes.root}>
      <Loader isLoading={loadingData} />
      <Paper className={classes.paper}>
        <FalconHead />
      </Paper>
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} className={classes.tableCell}>
                <Typography style={{ fontWeight: 700 }}>
                  {falconFilters.selectType === "week"
                    ? `Week ${falconFilters?.date?.week}, ${falconFilters?.date?.year}`
                    : `${allMonthsShort[Number(falconFilters?.date?.month) - 1]}, ${falconFilters?.date?.year}`}
                </Typography>
              </TableCell>
              {headColumns.map((item: DateLabelType) => (
                <FalconTableHeadCell columnData={item} />
              ))}
              <TableCell colSpan={2} className={classes.tableCell}>
                <Typography style={{ fontWeight: 700 }}>
                  {falconFilters.selectType === "week" ? `Week` : `Month `} Total
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {TABLE_SIDE_ROWS?.filter(
              (row: FalconTableRow) =>
                falconData?.standardList?.some(
                  (data: FalconStandardData) =>
                    data?.role === row?.role &&
                    data?.role_hours === row?.hour &&
                    (row?.vans?.includes(data?.van__fleet_size) ||
                      row?.vans?.includes(data?.cancelled_fleet_size)),
                ) ||
                row.id === "planned-routes" ||
                row.id === "actual-routes" ||
                row.id === "difference-routes",
            )?.map((row: FalconTableRow) => <FalconRow tableRow={row} headColumns={headColumns} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FalconPage;
