import { Grid, Paper } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { useStyles } from "../styles";
import { VansChart } from "../PerformanceCharts/VansChart";
import { FleetUtilTable } from "../PerfomanceTables/FleetUtilizationTable";
import { handleGetVans } from "pages/Scorecard/requests";
import { useDateWeekSelector } from "hooks/useDateWeekSelector";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { VansChartData } from "types/Performance";

interface PropsType {
  station: string;
}

const FleetUtilizationContent: FC<PropsType> = ({ station }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [loadingPast, setLoadingPast] = useState(false);
  const [requestData, setRequestData] = useState<VansChartData | null>(null);
  const [requestPastData, setRequestPastData] = useState<VansChartData | null>(null);

  const { perfomanceSettings } = useTypeSelectors((state) => state.perfomanceScores);
  const { date, selectType } = perfomanceSettings;

  const { pastMonthDate, pastWeekDate, pastDayDate } = useDateWeekSelector({
    defaultDate: date?.day,
    defaultWeek: date?.week,
    defaultMonth: date?.month,
  });

  const splitMonthDate = pastMonthDate.split(".");
  const splitWeekDate = pastWeekDate.split(".");
  const splitPastMonth = +splitMonthDate[0];
  const splitPastMonthYear = +splitMonthDate[1];
  const splitPastWeek = +splitWeekDate[0];
  const splitPastWeekYear = +splitWeekDate[1];

  useEffect(() => {
    const params = {
      station,
      ...(selectType === "month" && { month: +date?.month, year: +date?.year }),
      ...(selectType === "week" && { week: +date.week, year: +date?.year }),
    };

    const pastParams = {
      station,
      ...(selectType === "month" && { month: splitPastMonth, year: splitPastMonthYear }),
      ...(selectType === "week" && { week: splitPastWeek, year: splitPastWeekYear }),
    };

    handleGetVans(params, setRequestData, setLoading);
    handleGetVans(pastParams, setRequestPastData, setLoadingPast);
  }, [
    station,
    date?.year,
    date?.month,
    date?.week,
    selectType,
    pastDayDate,
    splitPastMonth,
    splitPastMonthYear,
    splitPastWeek,
    splitPastWeekYear,
    date?.day,
  ]);

  const newArrayData =
    requestData?.vans_data && requestData?.vans_data?.length > 20
      ? requestData?.vans_data.filter((el: { van__number: string | number }, index) =>
          index < 10 || index >= requestData?.vans_data.length - 11 ? el.van__number : null,
        ).reverse()
      : requestData?.vans_data.filter((el: { van__number: string | number }, index) =>
          index < Math.round(requestData?.vans_data?.length / 2) ||
          index >
            requestData?.vans_data?.length - (Math.round(requestData?.vans_data?.length / 2) + 1)
            ? el.van__number
            : null,
        ).reverse();

  const newArrayPastData =
    requestPastData?.vans_data && requestPastData?.vans_data?.length > 20
      ? requestPastData?.vans_data.filter((el: { van__number: string | number }, index) =>
          index < 10 || index >= requestPastData?.vans_data.length - 11 ? el.van__number : null,
        )
      : requestPastData?.vans_data.filter((el: { van__number: string | number }, index) =>
          index < Math.round(requestPastData?.vans_data?.length / 2) ||
          index >
            requestPastData?.vans_data?.length -
              (Math.round(requestPastData?.vans_data?.length / 2) + 1)
            ? el.van__number
            : null,
        );

  return (
    <>
      <Grid className={classes.gridItem} item sm={12} lg={6} xl={12}>
        <Paper className={classes.graphPaper}>
          <VansChart
            loading={loading}
            loadingPast={loadingPast}
            requestData={requestData}
            currentData={newArrayData}
            pastData={newArrayPastData}
          />
        </Paper>
      </Grid>
      <Grid className={classes.gridItem} item sm={12} lg={6} xl={12}>
        <Paper className={classes.graphPaper}>
          <FleetUtilTable loading={loading} currentData={requestData?.vans_data} />
        </Paper>
      </Grid>
    </>
  );
};

export default FleetUtilizationContent;
