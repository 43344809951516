import { Box, Button, FormGroup, MenuItem, Select, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { useStyles } from "./styles";
import { useDateWeekSelector } from "hooks/useDateWeekSelector";
import { StationSelect } from "components/elements/StationSelect";
import { WeekSelect } from "components/elements/WeekSelect";
import { MonthSelect } from "components/elements/MonthSelect";
import {
  PerfomanceSetDate,
  PerfomanceSetSelectType,
  PerfomanceSetStation,
  perfomanceUpdateSettings,
} from "actions/perfomaceActions";
import { PerfomanceConstants } from "constants/constants";
import { YearSelect } from "components/elements/YearSelect";
import { useEffect } from "react";
import { ImportFileButton } from "components/buttons/ImportFileButton";
import { DatePickWithArrow } from "components/elements/DatePickWithArrow";

const perfomanceScorecardList = [
  { title: "Top/Lowest Performers", value: "TL_PERFORMANCE" },
  { title: "High/Lowest DA Shifts", value: "HL_DA_SHIFTS" },
  { title: "Roles", value: "ROLES" },
  { title: "DA Score trend", value: "DA_SCORE_TREND" },
  { title: "Fleet Utilization", value: "TL_VANS" },
  { title: "Missing deliveries by DA", value: "MISSING_DELIVERIES" },
  { title: "Rabbit Reports by Serial Number", value: "RABBIT_REPORTS" },
  { title: "Dolly Reports by Serial Number", value: "DOLLY_REPORTS" },
  { title: "DAR", value: "WORST_OF_DAR" },
  { title: "Performace Scorecard", value: "PERFORMANCE_SCORECARD" },
];

export const PerfomaceHead = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userData } = useTypeSelectors((state) => state.auth);
  const { perfomanceSettings } = useTypeSelectors((state) => state.perfomanceScores);
  const defaultStationCondition = userData?.station_spots.some(
    (el: { station: { title: string; id: number } }) => el.station.id === 1,
  )
    ? 1
    : userData?.station_spots[0]?.station?.id;

  const handleChangeStation = (data: string) =>
    dispatch(PerfomanceSetStation(data, PerfomanceConstants.PERFORMANCE_SET_CURRENT_STATION));

  const handleChangeExternalSelectType = (select: string) =>
    dispatch(PerfomanceSetSelectType(select, PerfomanceConstants.PERFORMANCE_SET_SELECT_TYPE));

  const handleChangeWeek = (week: string) =>
    dispatch(PerfomanceSetDate({ week }));

  const handleChangeMonth = (month: string) =>
    dispatch(PerfomanceSetDate({ month }));

  const handleChangeYear = (year: string) =>
    dispatch(PerfomanceSetDate({ year }));

  const handleChangeDay = (day: string) => 
    dispatch(PerfomanceSetDate({ day }));

  const handleSubmit = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (perfomanceSettings?.currentScorecard === "PERFORMANCE_SCORECARD") {
      onTypeExternalChange("month");
    }
    dispatch(
      perfomanceUpdateSettings({
        ...perfomanceSettings,
        currentScorecard: event.target.value as string,
        selectType: selectType,
        date: {
          day: `${date}`,
          week: `${week}`,
          year: `${year}`,
          month: `${month}`,
        },
      }),
    );
  };

  const {
    date,
    week,
    month,
    year,
    selectType,
    dateCondition,
    dateConditionMonth,
    dateConditionYear,
    onWeekReduce,
    onWeekIncrease,
    onMonthReduce,
    onMonthIncrease,
    onYearIncrease,
    onDateChange,
    onDateChangeByDay,
    onYearReduce,
    onTypeExternalChange,
  } = useDateWeekSelector({
    defaultWeek: perfomanceSettings?.date?.week,
    defaultMonth: perfomanceSettings?.date?.month,
    defaultYear: perfomanceSettings?.date?.year,
    defaultSelectType: perfomanceSettings?.selectType,
    onSelectTypeSet: handleChangeExternalSelectType,
    onWeekSet: handleChangeWeek,
    onMonthSet: handleChangeMonth,
    onYearSet: handleChangeYear,
    onDateSet: handleChangeDay,
  });

  useEffect(() => {
    dispatch(
      PerfomanceSetStation(
        String(defaultStationCondition),
        PerfomanceConstants.PERFORMANCE_SET_CURRENT_STATION,
      ),
    );
  }, [defaultStationCondition, dispatch]);

  return (
    <Box className={classes.perfomanceHead}>
      <ImportFileButton/>
      <Box className={classes.titleSettings}>
        {selectType === "annual" && (
          <YearSelect
            year={year}
            onNext={onYearIncrease}
            onPrev={onYearReduce}
            disabled={dateConditionYear()}
          />
        )}
        {selectType === "month" && (
          <MonthSelect
            month={month}
            year={year}
            onNext={onMonthIncrease}
            onPrev={onMonthReduce}
            disabled={dateConditionMonth()}
          />
        )}
        {selectType === "week" && (
          <WeekSelect
            week={week}
            year={year}
            onNext={onWeekIncrease}
            onPrev={onWeekReduce}
            disabled={dateCondition()}
          />
        )}
        {selectType === "daily" && (
          <DatePickWithArrow
            value={date}
            onChange={onDateChange}
            changeDay={onDateChangeByDay}
            displayWeek
          />
        )}
        <Box className={classes.buttonBox}>
          {perfomanceSettings?.currentScorecard === "PERFORMANCE_SCORECARD" && (
            <Button
              variant={selectType === "annual" ? "contained" : "outlined"}
              className={classes.leftButton}
              onClick={() => onTypeExternalChange("annual")}
            >
              Annual
            </Button>
          )}
          <Button
            variant={selectType === "month" ? "contained" : "outlined"}
            className={
              perfomanceSettings?.currentScorecard === "PERFORMANCE_SCORECARD"
                ? classes.midButton
                : classes.leftButton
            }
            onClick={() => onTypeExternalChange("month")}
          >
            Monthly
          </Button>
          <Button
            variant={selectType === "week" ? "contained" : "outlined"}
            className={classes.midButton}
            onClick={() => onTypeExternalChange("week")}
          >
            Weekly
          </Button>
          <Button
            variant={selectType === "daily" ? "contained" : "outlined"}
            className={classes.rightButton}
            onClick={() => onTypeExternalChange("daily")}
          >
            Daily
          </Button>
        </Box>
        <StationSelect
          value={perfomanceSettings?.station}
          onChange={handleChangeStation}
          stations={userData?.station_spots}
        />
        <FormGroup className={classes.formGroupBox}>
          <Select
            inputProps={{ "data-testid": "reportFilterSelectInputTwo" }}
            label="Active Scorecard"
            value={perfomanceSettings?.currentScorecard}
            onChange={handleSubmit}
            className={classes.select}
            name="scorecard"
          >
            {perfomanceScorecardList.map((el: { title: string; value: string }) => (
              <MenuItem key={el.value} value={el.value}>
                <Typography variant="body2">{el.title}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormGroup>
      </Box>
    </Box>
  );
};
