import { FC, SetStateAction, useEffect, useState } from "react";
import { FormControl, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ActFilterValue, OnChangeEvent } from "types/general";
import { UserDataShort } from "types/User";

interface ActFilterAutoCompleteProps {
    name: string;
    label: string;
    value: ActFilterValue;
    defaultValue?: ActFilterValue;
    onChange: SetStateAction<any>;
    getOptions: () => Promise<any>;
    getOptionLabel: (val: UserDataShort) => string;
    className?: string;
}

export const ActFilterAutoComplete: FC<ActFilterAutoCompleteProps> = (props) => {
  const {
    label,
    defaultValue,
    onChange,
    getOptions,
    getOptionLabel,
    className,
  } = props;

  const [localLoading, setLocalLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setLocalLoading(true);
    getOptions()
      .then((res) => {
        if (res.data) setOptions(res.data.results);
        setLocalLoading(false);
      })
      .catch(() => {
        setOptions([]);
        setLocalLoading(false);
      });
  }, [getOptions]);

  return (
    <FormControl fullWidth className={className} data-testid="testActFilterAutoCompleteForm">
      <Autocomplete
        id={"idActFilterAutoComplete"}
        freeSolo={true}
        options={
            localLoading ? [] 
            : options
        }
        value={defaultValue}
        onChange={(event: OnChangeEvent, value ) => onChange(value)}
        getOptionLabel={(option: UserDataShort) => getOptionLabel(option)}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label={label}
            margin="normal"
            variant="standard"
            style={{ margin: 0 }}
            // InputProps={{ inputProps: {  
            //     "data-testid": "testActFilterAutoComplete" 
            // }}}
          />
        )}
      />
    </FormControl>
  );
};
