import { Grid, Paper } from "@material-ui/core";
import { FC, useState, useEffect } from "react";
import { useStyles } from "../styles";
import { HignDAShiftTable } from "../PerfomanceTables/HighDAShiftsTable";
import { HighDaShiftsChart } from "../PerformanceCharts/HighDaShiftsChart";
import { handleGetDrivers } from "pages/Scorecard/requests";
import { useDateWeekSelector } from "hooks/useDateWeekSelector";
import { useTypeSelectors } from "hooks/useTypeSelectors";

interface PropsType {
  station: string;
}

const HighDAShiftsContent: FC<PropsType> = ({ station }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loadingPast, setLoadingPast] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [requestPastData, setRequestPastData] = useState([]);
  const { perfomanceSettings } = useTypeSelectors((state) => state.perfomanceScores);

  const { date, selectType } = perfomanceSettings;

  const { pastMonthDate, pastWeekDate, pastDayDate } = useDateWeekSelector({
    defaultDate: date?.day,
    defaultWeek: date?.week,
    defaultMonth: date?.month,
  });

  const splitMonthDate = pastMonthDate.split(".");
  const splitWeekDate = pastWeekDate.split(".");
  const splitPastMonth = +splitMonthDate[0];
  const splitPastMonthYear = +splitMonthDate[1];
  const splitPastWeek = +splitWeekDate[0];
  const splitPastWeekYear = +splitWeekDate[1];

  useEffect(() => {
    const params = {
      station,
      ...(selectType === "month" && { month: +date?.month, year: +date?.year }),
      ...(selectType === "week" && { week: +date?.week, year: +date?.year }),
      ...(selectType === "daily" && { day: date?.day }),
    };
    const pastParams = {
      station,
      ...(selectType === "month" && { month: splitPastMonth, year: splitPastMonthYear }),
      ...(selectType === "week" && { week: splitPastWeek, year: splitPastWeekYear }),
      ...(selectType === "daily" && { day: pastDayDate }),
    };

    handleGetDrivers(params, setRequestData, setLoading);
    handleGetDrivers(pastParams, setRequestPastData, setLoadingPast);
  }, [
    station,
    date?.day,
    date?.year,
    date?.month,
    date?.week,
    selectType,
    pastDayDate,
    splitPastMonth,
    splitPastMonthYear,
    splitPastWeek,
    splitPastWeekYear,
  ]);

  const newArrayData =
    requestData.length > 20
      ? requestData.filter((el: { name: string }, index) =>
          index < 10 || index >= requestData.length - 11 ? el.name : null,
        )
      : requestData.filter((el: { name: string }, index) =>
          index < Math.round(requestData.length / 2) ||
          index > requestData.length - (Math.round(requestData.length / 2) + 1)
            ? el.name
            : null,
        );

  const newArrayPastData =
    requestPastData.length > 20
      ? requestPastData.filter((el: { name: string }, index) =>
          index < 10 || index >= requestPastData.length - 11 ? el.name : null,
        )
      : requestPastData.filter((el: { name: string }, index) =>
          index < Math.round(requestData.length / 2) ||
          index > requestPastData.length - (Math.round(requestData.length / 2) + 1)
            ? el.name
            : null,
        );

  return (
    <>
      <Grid className={classes.gridItem} item sm={12} lg={6} xl={12}>
        <Paper className={classes.graphPaper}>
          <HighDaShiftsChart
            loading={loading}
            loadingPast={loadingPast}
            currentData={newArrayData}
            pastData={newArrayPastData} />;
        </Paper>
      </Grid>
      <Grid className={classes.gridItem} item sm={12} lg={6} xl={12}>
        <Paper className={classes.graphPaper}>
          <HignDAShiftTable loading={loading} currentData={requestData} />
        </Paper>
      </Grid>
    </>
  );
};

export default HighDAShiftsContent;
