import api, { apiErrorHandler } from "api";
import { Dispatch } from "redux";
import { AuthConstants } from "constants/constants";
import { AuthFormState, AuthPasswordReset, AuthUserEditData } from "types/Auth";
import { RootState } from "reducers/rootReducer";
import { customToast } from "hooks/toast";
import { localUserService } from "utils/localStorage";

export const handleChangeAuthModalState = () => (dispatch: Dispatch) => {
  dispatch({ type: AuthConstants.AUTH_MODAL_STATE });
};

export const giveAccessToOldShifts = () => (dispatch: Dispatch) => {
  dispatch({ type: AuthConstants.GIVE_ACCESS_TO_OLD_SHIFTS });
}

export const checkAuthFromStorage = () => (dispatch: Dispatch) => {
  const token = localUserService.getAccess();
  if (token) {
    dispatch({
      type: AuthConstants.AUTH_LOGIN_SUCCESS,
      payload: token,
    });
  }
};

export const authLoginRequest = (data: AuthFormState) => (dispatch: Dispatch) => {
  dispatch({ type: AuthConstants.AUTH_LOGIN_PENDING });
  api
    .login(data)
    .then((res) => {
      const { access, refresh } = res.data;
      if (access && refresh) {
        localUserService.setAccess(access);
        localUserService.setRefresh(refresh);
        customToast("success", "Welcome!");
        dispatch({
          type: AuthConstants.AUTH_LOGIN_SUCCESS,
          payload: res.data,
        });
      } else {
        localUserService.logout();
      }
    })
    .catch(
      apiErrorHandler(() => {
        dispatch({ type: AuthConstants.AUTH_LOGIN_ERROR });
      }),
    );
};

export const handleChangeModalContainer = () => (dispatch: Dispatch) => {
  dispatch({
    type: AuthConstants.MODAL_CONTAINER,
  });
};

export const passwordReset = (data: AuthPasswordReset) => (dispatch: Dispatch) => {
  dispatch({ type: AuthConstants.PASSWORD_RESET_PENDING });
  api
    .passwordReset(data)
    .then(() => {
      customToast("success", "Password was reset!");
      dispatch({
        type: AuthConstants.PASSWORD_RESET_SUCCESS,
      });
    })
    .catch(
      apiErrorHandler(() => {
        dispatch({ type: AuthConstants.PASSWORD_RESET_ERROR });
      }),
    );
};

export const UserDataRequestEdit =
  (updatedUser: AuthUserEditData) => (dispatch: Dispatch, getStore: () => RootState) => {
    const { userData } = getStore()?.auth;
    if (userData?.id) {
      dispatch({ type: AuthConstants.USER_DATA_LOADING });
      api
        .updateUser(userData?.id, updatedUser)
        .then((res) => {
          if (res.data) {
            customToast("success", "Profile information was changed!");
            dispatch({
              type: AuthConstants.USER_DATA_SUCCESS,
              payload: res.data,
            });
          }
        })
        .catch(
          apiErrorHandler(() => {
            dispatch({ type: AuthConstants.USER_DATA_ERROR });
          }),
        );
    }
  };
