import { FC, useState } from "react";
import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { closeModal } from "actions/modalActions";
import { useDispatch } from "react-redux";
import { useDialogStyles } from "../styles";
import { DialogButton } from "components/buttons/DialogButton";
import { falconEditComment } from "actions/falconAction";
import { FalconCommentData } from "types/Falcon";

interface EditFalconCommentDialogProps {
  report_date: string | number | undefined;
  data: FalconCommentData | undefined;
}

export const EditFalconCommentDialog: FC<EditFalconCommentDialogProps> = ({ report_date, data }) => {
  const classes = useDialogStyles();
  const dispatch = useDispatch();
  const [comment, setComment] = useState<string>(data?.report_comment || "");
  const [matchStatus, setMatchStatus] = useState<number | undefined>(data?.is_match);

  const handleSubmit = () => {
    dispatch(falconEditComment(report_date, comment, matchStatus))
    dispatch(closeModal())
  };

  const closenModal = () => dispatch(closeModal());

  return (
    <>
      <DialogContent data-testid="testAddBudgetModalModal">
        <DialogContentText
          id="alert-dialog-description"
          className={classes.falconContent}
        >
          <FormControl style={{ marginBottom: 20 }}>
            <TextField
              name="edit_comment"
              placeholder="Edit your comment"
              multiline
              minRows={6}
              maxRows={8}
              defaultValue={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </FormControl>
        </DialogContentText>
        <FormControl className={classes.matchForm}>
          <Typography>Is Match?</Typography>
          <FormControlLabel
          control={
            <Checkbox
              checked={matchStatus === 1}
              onChange={()=> setMatchStatus(!matchStatus || matchStatus > 1 ? 1 : 0)}
              name="is_match_yes"
            />
          }
          labelPlacement={"top"}
          label="Yes"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={matchStatus === 2}
              onChange={() => setMatchStatus(!matchStatus || matchStatus === 1 ? 2 : 0)}
              name="is_match_no"
            />
          }
          labelPlacement={"top"}
          label="No"
        />
        </FormControl>
      </DialogContent>
      <DialogActions className={classes.dialogActions} data-testid="testDialogWrapperModal">
        <DialogButton
          testId={"cancelUploadFileDialogeButton"}
          buttonName={"Cancel"}
          onClick={closenModal}
          variant="contained"
          color="secondary"
        />
        <DialogButton
          testId={"agreeDialogWrapperButton"}
          buttonName={"Save"}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        />
      </DialogActions>
    </>
  );
};
