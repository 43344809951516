import { TableCell, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { FalconPlanData, FalconStandardData, FalconTableRow } from "types/Falcon";
import { formatNumberType } from "utils/formatNUmber";
import { FalconTablePlanField } from "./FalconTablePlanField";

export const RenderFalconCell = (
  classes: { [key: string]: string },
  tableRow: FalconTableRow,
  falconData: FalconStandardData[],
  falconPlanData: FalconPlanData[],
  columnDate?: string | number | undefined,
) => {
  switch (tableRow.id) {
    case "standard-cargo":
    case "standard-large":
    case "standard-cube":
    case "standard-medium":
    case "da-training":
    case "flex-cargo-9":
    case "flex-cargo-8":
    case "flex-cargo-7":
    case "flex-cargo-6":
    case "flex-cargo-5":
    case "flex-cargo-4":
    case "flex-cargo-3":
    case "flex-cargo-2":
    case "flex-cargo-1":
    case "flex-cube-9":
    case "flex-cube-8":
    case "flex-cube-7":
    case "flex-cube-6":
    case "flex-cube-5":
    case "flex-cube-4":
    case "flex-cube-3":
    case "flex-cube-2":
    case "flex-cube-1":
    case "flex-large-9":
    case "flex-large-8":
    case "flex-large-7":
    case "flex-large-6":
    case "flex-large-5":
    case "flex-large-4":
    case "flex-large-3":
    case "flex-large-2":
    case "flex-large-1":
    case "flex-medium-9":
    case "flex-medium-8":
    case "flex-medium-7":
    case "flex-medium-6":
    case "flex-medium-5":
    case "flex-medium-4":
    case "flex-medium-3":
    case "flex-medium-2":
    case "flex-medium-1":
      const routes = falconData
        .filter(
          (item: FalconStandardData) =>
            item?.role === tableRow.role &&
            tableRow?.vans?.includes(item?.van__fleet_size) &&
            item?.role_hours === tableRow.hour,
        )
        ?.reduce((a: number, b: FalconStandardData) => a + b.routes, 0);

      return (
        <TableCell
          key={`${tableRow.id}${columnDate}`}
          className={`${classes.tableCell}`}
          colSpan={2}
        >
          {routes > 0 ? routes : "---"}
        </TableCell>
      );

    case "planned-routes":
      const planRoutes = falconPlanData
        .reduce((a: number, b: FalconPlanData) => a + b.number_of_routes, 0);
      return (
        <TableCell
          key={`${tableRow.id}${columnDate}`}
          className={`${classes.tableCell} ${classes.frameBorderTop}`}
          colSpan={2}
        >
          <FalconTablePlanField
            planDate={columnDate}
            planRoutes={planRoutes}
          />
        </TableCell>
      );

    case "actual-routes":
      const routesSum = falconData
        .filter((item: FalconStandardData) => tableRow.roles?.includes(item.role))
        .reduce((a: number, b: FalconStandardData) => a + b.routes, 0);
      return (
        <TableCell
          key={`${tableRow.id}${columnDate}`}
          className={`${classes.tableCell}`}
          colSpan={2}
        >
          {routesSum > 0 ? routesSum : "0"}
        </TableCell>
      );

    case "difference-routes":
      const plan = falconPlanData.reduce(
        (a: number, b: FalconPlanData) => a + b.number_of_routes,
        0,
      );
      const actual = falconData
        ?.filter((item: FalconStandardData) => tableRow.roles?.includes(item.role))
        .reduce((a: number, b: FalconStandardData) => a + b.routes, 0);
      const diference = plan > 0 ? (100 * actual) / plan : (100 * actual) / 1;

      return (
        <TableCell
          key={`${tableRow.id}${columnDate}`}
          className={classes.frameBorderBottom}
          colSpan={2}
        >
          <Alert
            icon={false}
            severity={plan <= actual ? "success" : "error"}
            className={plan <= actual ? classes.greenAlert : classes.redAlert}
            style={{ padding: "0 5px", display: "flex", justifyContent: "center" }}
          >
            <Typography>{formatNumberType(Number(diference))}%</Typography>
          </Alert>
        </TableCell>
      );
    case "cargo-cancelled":
    case "large-cancelled":
    case "cube-cancelled":
    case "medium-cancelled":
      const cancelledRoutes = falconData
        ?.filter(
          (item: FalconStandardData) =>
            tableRow.role === item.role && 
              (tableRow?.vans?.includes(item?.cancelled_fleet_size) ||
                tableRow?.vans?.includes(item?.van__fleet_size)),
        )
        .reduce((a: number, b: FalconStandardData) => a + b.routes, 0);

      return (
        <TableCell
          key={`${tableRow.id}${columnDate}`}
          className={`${classes.tableCell}`}
          colSpan={2}
        >
          {cancelledRoutes > 0 ? cancelledRoutes : "---"}
        </TableCell>
      );

    default:
      return (
        <TableCell
          key={`${tableRow.id}${columnDate}`}
          className={`${classes.tableCell}`}
          colSpan={2}
        >
          ---
        </TableCell>
      );
  }
};
