export enum AuthConstants {
  AUTH_LOGIN_PENDING = "AUTH_LOGIN_PENDING",
  AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS",
  AUTH_LOGIN_ERROR = "AUTH_LOGIN_ERROR",

  AUTH_LOGOUT = "AUTH_LOGOUT",
  AUTH_REFRESH_PENDING = "AUTH_REFRESH_PENDING",
  AUTH_REFRESH_SUCCESS = "AUTH_REFRESH_SUCCESS",
  AUTH_REFRESH_ERROR = "AUTH_REFRESH_ERROR",

  PASSWORD_RESET_PENDING = "PASSWORD_RESET_PENDING",
  PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS",
  PASSWORD_RESET_ERROR = "PASSWORD_RESET_ERROR",

  AUTH_MODAL_STATE = "AUTH_MODAL_STATE",

  MODAL_CONTAINER = "MODAL_CONTAINER",

  USER_DATA_LOADING = "USER_DATA_LOADING",
  USER_DATA_SUCCESS = "USER_DATA_SUCCESS",
  USER_DATA_ERROR = "USER_DATA_ERROR",

  USER_DATA_UPDATE_LOADING = "USER_DATA_UPDATE_LOADING",
  USER_DATA_UPDATE_SUCCESS = "USER_DATA_UPDATE_SUCCESS",
  USER_DATA_UPDATE_ERROR = "USER_DATA_UPDATE_ERROR",

  UPDATE_FIELD = "UPDATE_FIELD",

  GIVE_ACCESS_TO_OLD_SHIFTS = "GIVE_ACCESS_TO_OLD_SHIFTS",
}

export enum DasBoardConstants {
  DAS_DAILY_REPORTS_PENDING = "DAS_DAILY_REPORTS_PENDING",
  DAS_DAILY_REPORTS_SUCCESS = "DAS_DAILY_REPORTS_SUCCESS",
  DAS_DAILY_REPORTS_ERROR = "DAS_DAILY_REPORTS_ERROR",

  DAS_DAILY_REPORTS_FILTERS_SET = "DAS_DAILY_REPORTS_FILTERS_SET",
  DAS_DAILY_REPORTS_FILTERS_RESET = "DAS_DAILY_REPORTS_FILTERS_RESET",
  DAS_DAILY_REPORTS_FILTERS_TAB_SET = "DAS_DAILY_REPORTS_FILTERS_TAB_SET",

  DAS_DAILY_REPORT_CREATE_PENDING = "DAS_DAILY_REPORT_CREATE_PENDING",
  DAS_DAILY_REPORT_CREATE_SUCCESS = "DAS_DAILY_REPORT_CREATE_SUCCESS",
  DAS_DAILY_REPORT_CREATE_ERROR = "DAS_DAILY_REPORT_CREATE_ERROR",

  DAS_DAILY_REPORT_GET_PENDING = "DAS_DAILY_REPORT_GET_PENDING",
  DAS_DAILY_REPORT_GET_SUCCESS = "DAS_DAILY_REPORT_GET_SUCCESS",
  DAS_DAILY_REPORT_GET_ERROR = "DAS_DAILY_REPORT_GET_ERROR",

  DAS_DAILY_ADD_REPORT_FIELD = "DAS_DAILY_ADD_REPORT_FIELD",
  DAS_DAILY_UPDATE_REPORT_FIELD = "DAS_DAILY_UPDATE_REPORT_FIELD",
  DAS_DAILY_DELETE_REPORT_FIELD = "DAS_DAILY_DELETE_REPORT_FIELD",

  DAS_DAILY_REPORT_DELETE_PENDING = "DAS_DAILY_REPORT_DELETE_PENDING",
  DAS_DAILY_REPORT_DELETE_SUCCESS = "DAS_DAILY_REPORT_DELETE_SUCCESS",
  DAS_DAILY_REPORT_DELETE_ERROR = "DAS_DAILY_REPORT_DELETE_ERROR",

  DAS_DAILY_DRIVERS_NOTIFY_SUCCESS = "DAS_DAILY_DRIVERS_NOTIFY_SUCCESS",
  DAS_DAILY_DRIVERS_NOTIFY_ERROR = "DAS_DAILY_DRIVERS_NOTIFY_ERROR",

  DAS_FLEET_SIZE_PENDING = "DAS_FLEET_SIZE_PENDING",
  DAS_FLEET_SIZE_SUCCESS = "DAS_FLEET_SIZE_SUCCESS",
  DAS_FLEET_SIZE_ERROR = "DAS_FLEET_SIZE_ERROR",

  DAS_TABLE_ROWS_PER_PAGE = "DAS_TABLE_ROWS_PER_PAGE",

  DAS_UPLOAD_FILE_INFO_PENDING = "DAS_UPLOAD_FILE_INFO_PENDING",
  DAS_UPLOAD_FILE_INFO_SUCCESS = "DAS_UPLOAD_FILE_INFO_SUCCESS",
  DAS_UPLOAD_FILE_INFO_ERROR = "DAS_UPLOAD_FILE_INFO_ERROR",

  CHANGE_PAGE_LIST_TYPE = "CHANGE_PAGE_LIST_TYPE",
}

export enum AmazonConstants {
  LOADING_AMAZON_FILE = "LOADING_AMAZON_FILE",
  SUBMIT_AMAZON_FILE = "SUBMIT_AMAZON_FILE",
  ERROR_AMAZON_FILE = "ERROR_AMAZON_FILE",

  AMAZON_HISTORY_LOADING = "AMAZON_HISTORY_LOADING",
  AMAZON_HISTORY_SUCCESS = "AMAZON_HISTORY_SUCCESS",
  AMAZON_HISTORY_ERROR = "AMAZON_HISTORY_ERROR",

  CSV_HISTORY_LOADING = "CSV_HISTORY_LOADING",
  CSV_HISTORY_SUCCESS = "CSV_HISTORY_SUCCESS",
  CSV_HISTORY_ERROR = "CSV_HISTORY_ERROR",

  IMPORT_TABLE_ROWS_PER_PAGE = "IMPORT_TABLE_ROWS_PER_PAGE",
  IMPORT_PAGE_TYPE = "IMPORT_PAGE_TYPE",
}

export enum DashboardConstants {
  DASHBOARD_LOADING = "DASHBOARD_LOADING",
  DASHBOARD_ERROR = "DASHBOARD_ERROR",
  DASHBOARD_DATA_SUCCESS = "DASHBOARD_DATA_SUCCESS",
  DASHBOARD_MESSAGE_SUCCESS = "DASHBOARD_MESSAGE_SUCCESS",
  DASHBOARD_DATE_LOADING = "DASHBOARD_DATE_LOADING",
  DASHBOARD_DATE_SUCCESS = "DASHBOARD_DATE_SUCCESS",
  DASHBOARD_DATE_ERROR = "DASHBOARD_DATE_ERROR",
  DASHBOARD_MODAL_STATE = "DASHBOARD_MODAL_STATE",

  DASHBOARD_UPDATE_SETTINGS = "DASHBOARD_UPDATE_SETTINGS",
  DASHBOARD_UPDATE_RANGE_DATES = "DASHBOARD_UPDATE_RANGE_DATES",
  DASHBOARD_CHANGE_ROWS_PER_PAGE = "DASHBOARD_CHANGE_ROWS_PER_PAGE",

  DASHBOARD_INITIALIZE_LABEL_DATE_TYPE = "DASHBOARD_INITIALIZE_LABEL_DATE_TYPE",

  DASHBOARD_UPDATE_STATIONS_FILTER = "DASHBOARD_UPDATE_STATIONS_FILTER",
}

export enum WeightDataConstants {
  WEIGHT_DATA_LOADING = "WEIGHT_DATA_LOADING",
  WEIGHT_DATA_SUCCESS = "WEIGHT_DATA_SUCCESS",
  WEIGHT_DATA_ERROR = "WEIGHT_DATA_ERROR",
}

export enum FleetSettingsDataConstants {
  FLEET_SETTINGS_LOADING = "FLEET_SETTINGS_LOADING",
  FLEET_SETTINGS_DATA = "FLEET_SETTINGS_DATA",
  FLEET_SETTINGS_CREATE = "FLEET_SETTINGS_CREATE",
  FLEET_SETTINGS_UPDATE_STATUS = "FLEET_SETTINGS_UPDATE_STATUS",
  FLEET_SETTINGS_DELETE = "FLEET_SETTINGS_DELETE",
  FLEET_SETTINGS_ERROR = "FLEET_SETTINGS_ERROR",
}

export enum ScorecardConstants {
  EXTERNAL_SETTINGS_ACTIVE = "EXTERNAL_SETTINGS_ACTIVE",
  EXTERNAL_SET_CURRENT_STATION = "EXTERNAL_SET_CURRENT_STATION",
  EXTERNAL_SET_SELECT_TYPE = "EXTERNAL_SET_SELECT_TYPE",
  EXTERNAL_SET_DATE = "EXTERNAL_SET_DATE",

  DA_SCORE_TREND_SET_CURRENT_STATION = "DA_SCORE_TREND_SET_CURRENT_STATION",
  DA_SCORE_TREND_SET_SELECT_TYPE = "DA_SCORE_TREND_SET_SELECT_TYPE",
  DA_SCORE_TREND_SET_DATE = "DA_SCORE_TREND_SET_DATE",

  TOTAL_SCOREBOARD_SET_CURRENT_STATION = "TOTAL_SCOREBOARD_SET_CURRENT_STATION",
  TOTAL_SCOREBOARD_SET_SELECT_TYPE = "TOTAL_SCOREBOARD_SET_SELECT_TYPE",
  TOTAL_SCOREBOARD_SET_DATE = "TOTAL_SCOREBOARD_SET_DATE",

  TOP_TEN_SHIFTS_SET_CURRENT_STATION = "TOP_TEN_SHIFTS_SET_CURRENT_STATION",
  TOP_TEN_SHIFTS_SET_SELECT_TYPE = "TOP_TEN_SHIFTS_SET_SELECT_TYPE",
  TOP_TEN_SHIFTS_SET_DATE = "TOP_TEN_SHIFTS_SET_DATE",

  TOP_TEN_DAS_SET_CURRENT_STATION = "TOP_TEN_DAS_SET_CURRENT_STATION",
  TOP_TEN_DAS_SET_SELECT_TYPE = "TOP_TEN_DAS_SET_SELECT_TYPE",
  TOP_TEN_DAS_SET_DATE = "TOP_TEN_DAS_SET_DATE",

  ROLES_SET_CURRENT_STATION = "ROLES_SET_CURRENT_STATION",
  ROLES_SET_SELECT_TYPE = "ROLES_SET_SELECT_TYPE",
  ROLES_SET_DATE = "ROLES_SET_DATE",

  RETURN_SET_CURRENT_STATION = "RETURN_SET_CURRENT_STATION",
  RETURN_SET_SELECT_TYPE = "RETURN_SET_SELECT_TYPE",
  RETURN_SET_DATE = "RETURN_SET_DATE",

  VANS_SET_CURRENT_STATION = "VANS_SET_CURRENT_STATION",
  VANS_SET_SELECT_TYPE = "VANS_SET_SELECT_TYPE",
  VANS_SET_DATE = "VANS_SET_DATE",

  MISSING_DELIVERIES_SET_CURRENT_STATION = "MISSING_DELIVERIES_SET_CURRENT_STATION",
  MISSING_DELIVERIES_SET_SELECT_TYPE = "MISSING_DELIVERIES_SET_SELECT_TYPE",
  MISSING_DELIVERIES_SET_DATE = "MISSING_DELIVERIES_SET_DATE",

  CASH_ON_DELIVERY_SET_CURRENT_STATION = "CASH_ON_DELIVERY_SET_CURRENT_STATION",
  CASH_ON_DELIVERY_SET_SELECT_TYPE = "CASH_ON_DELIVERY_SET_SELECT_TYPE",
  CASH_ON_DELIVERY_SET_DATE = "CASH_ON_DELIVERY_SET_DATE",

  DAR_SET_CURRENT_STATION = "DAR_SET_CURRENT_STATION",
  DAR_SET_SELECT_TYPE = "DAR_SET_SELECT_TYPE",
  DAR_SET_DATE = "DAR_SET_DATE",

  LATE_ATTENDANCE_STATION = "LATE_ATTENDANCE_STATION",
  LATE_ATTENDANCE_SELECT_TYPE = "LATE_ATTENDANCE_SELECT_TYPE",
  LATE_ATTENDANCE_DATE = "LATE_ATTENDANCE_DATE",

  LAUNCHPAD_SUPPORT_STATION = "LAUNCHPAD_SUPPORT_STATION",
  LAUNCHPAD_SUPPORT_SELECT_TYPE = "LAUNCHPAD_SUPPORT_SELECT_TYPE",
  LAUNCHPAD_SUPPORT_DATE = "LAUNCHPAD_SUPPORT_DATE",

  MORNING_CHECKLIST_STATION = "MORNING_CHECKLIST_STATION",
  MORNING_CHECKLIST_SELECT_TYPE = "MORNING_CHECKLIST_SELECT_TYPE",
  MORNING_CHECKLIST_DATE = "MORNING_CHECKLIST_DATE",

  RESCUE_STATION = "RESCUE_STATION",
  RESCUE_SELECT_TYPE = "RESCUE_SELECT_TYPE",
  RESCUE_DATE = "RESCUE_DATE",

  RETURNED_DATA_BY_DRIVER_STATION = "RETURNED_DATA_BY_DRIVER_STATION",
  RETURNED_DATA_BY_DRIVER_SELECT_TYPE = "RETURNED_DATA_BY_DRIVER_SELECT_TYPE",
  RETURNED_DATA_BY_DRIVER_DATE = "RETURNED_DATA_BY_DRIVER_DATE",
}

export enum CheckTableConstants {
  CHECK_TABLE_GET_WEEKLY_INFO_LOADING = "CHECK_TABLE_GET_WEEKLY_INFO_LOADING",
  CHECK_TABLE_GET_WEEKLY_INFO_SUCCESS = "CHECK_TABLE_GET_WEEKLY_INFO_SUCCESS",
  CHECK_TABLE_GET_WEEKLY_INFO_ERROR = "CHECK_TABLE_GET_WEEKLY_INFO_ERROR",

  CHECK_TABLE_CHANGE_ROWS_PER_PAGE = "CHECK_TABLE_CHANGE_ROWS_PER_PAGE",
  SET_CHECK_TABLE_FILTERS = "SET_CHECK_TABLE_FILTERS",

  CHECK_TABLE_GET_WEEK_FULL_INFO_LOADING = "CHECK_TABLE_GET_WEEK_FULL_INFO_LOADING",
  CHECK_TABLE_GET_WEEK_FULL_INFO_SUCCESS = "CHECK_TABLE_GET_WEEK_FULL_INFO_SUCCESS",
  CHECK_TABLE_GET_WEEK_FULL_INFO_ERROR = "CHECK_TABLE_GET_WEEK_FULL_INFO_ERROR",

  CHECK_TABLE_UPLOAD_FILE_WEEKLY_INFO_LOADING = "CHECK_TABLE_UPLOAD_FILE_WEEKLY_INFO_LOADING",
  CHECK_TABLE_UPLOAD_FILE_WEEKLY_INFO_SUCCESS = "CHECK_TABLE_UPLOAD_FILE_WEEKLY_INFO_SUCCESS",
  CHECK_TABLE_UPLOAD_FILE_WEEKLY_INFO_ERROR = "CHECK_TABLE_UPLOAD_FILE_WEEKLY_INFO_ERROR",

  CHECK_TABLE_UPDATE_WEEKLY_INFO_LOADING = "CHECK_TABLE_UPDATE_WEEKLY_INFO_LOADING",
  CHECK_TABLE_UPDATE_WEEKLY_INFO_SUCCESS = "CHECK_TABLE_UPDATE_WEEKLY_INFO_SUCCESS",
  CHECK_TABLE_UPDATE_WEEKLY_INFO_ERROR = "CHECK_TABLE_UPDATE_WEEKLY_INFO_ERROR",

  CHECK_TABLE_UPDATE_CAS_ES = "CHECK_TABLE_UPDATE_CAS_ES",

  UPDATE_CHECK_TABLE_AVERAGE_SCRORE_DATA = "UPDATE_CHECK_TABLE_AVERAGE_SCRORE_DATA",
  CHECK_TABLE_SET_CURRENT_STATION = "CHECK_TABLE_SET_CURRENT_STATION"
}

export enum CheckIkeaTableConstants {
  IKEA_CHECK_TABLE_GET_WEEKLY_INFO_ERROR = "IKEA_CHECK_TABLE_GET_WEEKLY_INFO_ERROR",
  IKEA_CHECK_TABLE_GET_WEEKLY_INFO_LOADING = "IKEA_CHECK_TABLE_GET_WEEKLY_INFO_LOADING",
  IKEA_CHECK_TABLE_GET_WEEKLY_INFO_SUCCESS = "IKEA_CHECK_TABLE_GET_WEEKLY_INFO_SUCCESS",

  IKEA_CHECK_TABLE_CHANGE_ROWS_PER_PAGE = "IKEA_CHECK_TABLE_CHANGE_ROWS_PER_PAGE",
  IKEA_SET_CHECK_TABLE_FILTERS = "IKEA_SET_CHECK_TABLE_FILTERS",

  IKEA_CHECK_TABLE_GET_WEEK_FULL_INFO_ERROR = "IKEA_CHECK_TABLE_GET_WEEK_FULL_INFO_ERROR",
  IKEA_CHECK_TABLE_GET_WEEK_FULL_INFO_LOADING = "IKEA_CHECK_TABLE_GET_WEEK_FULL_INFO_LOADING",
  IKEA_CHECK_TABLE_GET_WEEK_FULL_INFO_SUCCESS = "IKEA_CHECK_TABLE_GET_WEEK_FULL_INFO_SUCCESS",

  IKEA_CHECK_TABLE_UPDATE_WEEKLY_INFO_ERROR = "IKEA_CHECK_TABLE_UPDATE_WEEKLY_INFO_ERROR",
  IKEA_CHECK_TABLE_UPDATE_WEEKLY_INFO_LOADING = "IKEA_CHECK_TABLE_UPDATE_WEEKLY_INFO_LOADING",
  IKEA_CHECK_TABLE_UPDATE_WEEKLY_INFO_SUCCESS = "IKEA_CHECK_TABLE_UPDATE_WEEKLY_INFO_SUCCESS",
  IKEA_CHECK_TABLE_UPDATE_CAS_ES = "IKEA_CHECK_TABLE_UPDATE_CAS_ES",
  IKEA_UPDATE_CHECK_TABLE_AVERAGE_SCRORE_DATA = "IKEA_UPDATE_CHECK_TABLE_AVERAGE_SCRORE_DATA",
}

export enum ActTableConstants {
  ACT_TABLE_GET_INFO_LOADING = "ACT_TABLE_GET_INFO_LOADING",
  ACT_TABLE_GET_INFO_SUCCESS = "ACT_TABLE_GET_INFO_SUCCESS",
  ACT_TABLE_GET_INFO_ERROR = "ACT_TABLE_GET_INFO_ERROR",
  ACT_TABLE_CHANGE_ROWS_PER_PAGE = "ACT_TABLE_CHANGE_ROWS_PER_PAGE",
  ACT_TABLE_UPDATE_INFO_SUCCESS = "ACT_TABLE_UPDATE_SUCCESS",
  SET_ACT_TABLE_FILTERS = "SET_ACT_TABLE_FILTERS",
}

export enum IkeaActTableConstants {
  IKEA_ACT_TABLE_GET_INFO_LOADING = "ACT_IKEA_TABLE_GET_INFO_LOADING",
  IKEA_ACT_TABLE_GET_INFO_SUCCESS = "ACT_IKEA_TABLE_GET_INFO_SUCCESS",
  IKEA_ACT_TABLE_GET_INFO_ERROR = "ACT_IKEA_TABLE_GET_INFO_ERROR",
  IKEA_ACT_TABLE_CHANGE_ROWS_PER_PAGE = "ACT_IKEA_TABLE_CHANGE_ROWS_PER_PAGE",
  IKEA_ACT_TABLE_UPDATE_INFO_SUCCESS = "ACT_IKEA_TABLE_UPDATE_SUCCESS",
  SET_IKEA_ACT_TABLE_FILTERS = "SET_ACT_IKEA_TABLE_FILTERS",
}

export enum CustomGraphsConstants {
  CUSTOM_GRAPHS_CHANGE_PAGE = "CUSTOM_GRAPHS_CHANGE_PAGE",
  CUSTOM_GRAPHS_PAGE_DELETE_SUCCESS = "CUSTOM_GRAPHS_PAGE_DELETE_SUCCESS",
  CUSTOM_GRAPHS_CREATE_BOARD_SUCCESS = "CUSTOM_GRAPHS_CREATE_BOARD_SUCCESS",

  CUSTOM_GRAPHS_ACTION_SUCCESS = "CUSTOM_GRAPHS_ACTION_SUCCESS",
  CUSTOM_GRAPHS_ACTION_ERROR = "CUSTOM_GRAPHS_ACTION_ERROR",
  CUSTOM_GRAPHS_ACTION_LOADING = "CUSTOM_GRAPHS_ACTION_LOADING",

  GET_CUSTOM_GRAPHS_ENDPOINTS_SUCCESS = "GET_CUSTOM_GRAPHS_ENDPOINTS_SUCCESS",
}

export enum DaAndFleetConstants {
  DA_FLEET_GET_ALL_DRIVERS_SUCCESS = "DA_FLEET_GET_ALL_DRIVERS_SUCCESS",
  DA_FLEET_GET_ALL_FLEEETS_SUCCESS = "DA_FLEET_GET_ALL_FLEEETS_SUCCESS",
  DA_FLEET_LOADING = "DA_FLEET_LOADING",
  DA_FLEET_ERROR = "DA_FLEET_ERROR",
  DA_FLEET_DA_SET_FILTERS = "DA_FLEET_DA_SET_FILTERS",
  DA_FLEET_FLEET_SET_FILTERS = "DA_FLEET_FLEET_SET_FILTERS",
  DA_FLEET_CHANGE_PAGE_LIST_TYPE = "DA_FLEET_CHANGE_PAGE_LIST_TYPE",
  DA_FEET_SET_DA_ID_LIST = "DA_FEET_SET_DA_ID_LIST",
  DA_FEET_SET_FLEET_ID_LIST = "DA_FEET_SET_FLEET_ID_LIST",
  UPDATE_DA_SUCCESS = "UPDATE_DA_SUCCESS",
  UPDATE_FLEET_SUCCESS = "UPDATE_FLEET_SUCCESS",
}

export enum PerfomanceConstants {
  PERFORMANCE_ERROR = "PERFORMANCE_ERROR",
  PERFORMANCE_LOADING = "PERFORMANCE_LOADING",
  RELIABILITY_DATA_SUCCESS = "RELIABILITY_DATA_SUCCESS",
  PERFORMANCE_UPDATE_SETTINGS = "PERFORMANCE_UPDATE_SETTINGS",
  PERFORMANCE_SET_CURRENT_STATION = "PERFORMANCE_SET_CURRENT_STATION",
  PERFORMANCE_SET_DAY = "PERFORMANCE_SET_DAY",
  PERFORMANCE_SET_SELECT_TYPE = "PERFORMANCE_SET_SELECT_TYPE",
}

export enum BudgetConstants {
  BUDGET_CREATE_ACTION_SUCCESS = "BUDGET_CREATE_ACTION_SUCCESS",
  BUDGETS_GET_ALL_SUCSESS = "BUDGETS_GET_ALL_SUCSESS",
  BUDGETS_LOADING = "BUDGETS_LOADING",
  BUDGETS_ERROR = "BUDGETS_ERROR",
  BUDGETS_UPDATE_BUDGET_SUCSESS = "BUDGETS_UPDATE_BUDGET_SUCSESS",
  BUDGETS_DELETE_BUDGET_SUCSESS = "BUDGETS_BUDGETS_DELETE_BUDGET_SUCSESSGET_ALL_SUCSESS",
  BUDGETS_TABLE_ROWS_PER_PAGE = "BUDGETS_TABLE_ROWS_PER_PAGE",
}

export enum FalconConstants {
  FALCON_TABLE_GET_DATA_SUCCESS = "FALCON_TABLE_GET_DATA_SUCCESS",
  FALCON_TABLE_GET_DATA_LOADING = "FALCON_TABLE_GET_DATA_LOADING",
  FALCON_TABLE_GET_DATA_ERROR = "FALCON_TABLE_GET_DATA_ERROR",
  FALCON_PAGE_CHANGE_FILTERS = "FALCON_PAGE_CHANGE_FILTERS",
  FALCON_PAGE_CHANGE_TYPE = "FALCON_PAGE_CHANGE_TYPE",
  FALCON_PAGE_CHANGE_DATE_FILTERS = "FALCON_PAGE_CHANGE_DATE_FILTERS",
  FALCON_TABLE_UPDATE_PLANS = "FALCON_TABLE_UPDATE_PLANS",
  FALCON_TABLE_UPDATE_COMMENTS = "FALCON_TABLE_UPDATE_COMMENTS",
  FALCON_TABLE_DISABLE_LOADING = "FALCON_TABLE_DISABLE_LOADING",
}

export enum SettingsConstants {
  SETTINGS_EDIT_ACTIVE_NAVIGATION_MENU = "STTINGS_EDIT_ACTIVE_NAVIGATION_MENU",
}

export enum ModalConstants {
  OPEN_MODAL = "OPEN_MODAL",
  CLOSE_MODAL = "CLOSE_MODAL",
}

export const actOffTargetAreaConstants = ["DCR", "DNR", "DSB", "POD", "VIOLATION", "MC", "OA"];
export const ikeaActOffTargetAreaConstants = ["DCR", "CC", "MC", "OA"];

export const allMonthsShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const daStatusConstants = [
  { value: 1, option: "Active" },
  { value: 2, option: "Rehired" },
  { value: 3, option: "Blacklisted" },
  { value: 4, option: "Inactive" },
];

export const fleetStatusConstants = [
  { value: 1, option: "Available" },
  { value: 2, option: "Out of Service" },
  { value: 3, option: "Off Boarded" },
];

export const actStatusConstants = [
  { value: 0, option: "None" },
  { value: 1, option: "DONE" },
  { value: 2, option: "CNL" },
  { value: 3, option: "MISS" },
];

export const actActionConstants = [
  { value: 0, option: "None" },
  { value: 1, option: "Coach" },
  { value: 2, option: "Verbal Warning" },
  { value: 3, option: "Written Warning" },
  { value: 4, option: "Dismiss" },
];

export const daShiftStatusConstants = [
  { value: 0, option: "Pending" },
  { value: 1, option: "Confirmed" },
  { value: 2, option: "Rejected" },
];

export const trendsRangeFilters = [
  {conditionType: "day", type: "date"},
  {conditionType: "week", type: "week"},
  {conditionType: "month", type: "month"},
]

export const DISPATCHER = 1;
export const SUPERVISOR = 2;
export const MANAGER = 3;
export const SUPERADMIN = 4;

export const EMPTY = "EMPTY";
export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const HAVENTACCESS = 403;
export const NOT_FOUND = 404;
export const LARGE_REQUEST = 413;
export const INTERNAL_SERVER_ERROR = 500;
export const BASE_URL = "https://dpiscore.com/";

export const LOCAL_TOKEN = "courierUserToken";
export const LOCAL_REFRESH = "courierUserTokenRefresh";
export const LOCAL_PERMISSIONS = "courierUserPermissions";
export const LOCAL_PERSIST_SCORECARD = "persist:courierScorecard";

export enum Colors {
  MAIN = "#00467a",
  MAIN_LIGHT = "#025b99",
  MAIN_DARK = "#063f69",
  WHITE = "#ffffff",
  RED = "#c30010",
  GREEN = "#34840a",
  YELLOW = "#e47200",
  BLUE = "#25517e",
  BLACK = "#000",
  GRAY = "#e8e8e8",
  LIGHT_BLUE = "#f2f5f7",
  PASTEL_YELLOW = "#e2cb89",
  PASTEL_BLUE = "#92a8d1",
  PASTEL_GREEN = "#a4cb80",
  PASTEL_RED = "#FF7878",
  PASTEL_ORANGE = "#f86749",
  PASTEL_VIOLET = "#9966cc",
  PASTEL_PINK = "#d19fe8",
  PASTEL_TURQUOISE = "#76eec6",
  PASTEL_SAND = "#FFFBC1",
  PASTEL_PINE_TREE = "#218C8D",
  SUCCESS_TOAST_GREEN = "#4fba16",
}
