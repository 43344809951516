import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "10px",
    borderBottom: "none",
  },
  list: {
    display: "flex",
    flexDirection: "column",
  },
  subList: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(2),
  }
}));
