import { initialFalconState } from "store/initialStates";
import { ReducersInterfaceAction } from "types/Reducers";
import { FalconConstants } from "constants/constants";
import { FalconState } from "types/Falcon";

export const falconReducer = (
  state = initialFalconState,
  action: ReducersInterfaceAction,
): FalconState => {
  switch (action.type) {
    case FalconConstants.FALCON_TABLE_GET_DATA_SUCCESS:
      return {
        ...state,
        loadingData: false,
        falconData: {
          dateList: action.payload?.dateList,
          standardList: action.payload?.standartList,
          planList: action.payload?.planList,
          commentList: action.payload?.commentList
        },
      };
    case FalconConstants.FALCON_TABLE_GET_DATA_LOADING:
      return {
        ...state,
        loadingData: true,
      };
    case FalconConstants.FALCON_TABLE_DISABLE_LOADING:
    case FalconConstants.FALCON_TABLE_GET_DATA_ERROR:
      return {
        ...state,
        loadingData: false,
      };
    case FalconConstants.FALCON_PAGE_CHANGE_FILTERS:
      return {
        ...state,
        falconFilters: action.payload,
      };
    case FalconConstants.FALCON_PAGE_CHANGE_DATE_FILTERS:
      return {
        ...state,
        falconFilters: { ...state.falconFilters, date: action.payload },
      };
    case FalconConstants.FALCON_PAGE_CHANGE_TYPE:
      return {
        ...state,
        falconFilters: { ...state.falconFilters, selectType: action.payload },
      };
    case FalconConstants.FALCON_TABLE_UPDATE_COMMENTS:
      return {
        ...state,
        loadingData: false,
        falconData: {
          ...state.falconData,
          commentList: action.payload
        },
      };
    case FalconConstants.FALCON_TABLE_UPDATE_PLANS:
      return {
        ...state,
        loadingData: false,
        falconData: {
          ...state.falconData,
          planList: action.payload
        },
      };
    default:
      return state;
  }
};
