import { FC, useState } from "react";
import {
  DialogContent,
  DialogActions,
  Typography,
  Button,
  FormLabel,
  Box,
  IconButton,
} from "@material-ui/core";
import CsvIcon from "icon/csvIcon.png";
import ClearIcon from "@material-ui/icons/Clear";
import { ellipsis } from "utils/formats";
import { useDispatch } from "react-redux";
import { createCheckTableWeeklyFileUpload } from "actions/checkActions";
import { closeModal } from "actions/modalActions";
import { useDialogStyles } from "../styles";
import { DialogButton } from "components/buttons/DialogButton";
import api from "api";

interface UploadCsvFileDialogContentProps {}

export const UploadCheckCSVFilesDialog: FC<UploadCsvFileDialogContentProps> = () => {
  const classes = useDialogStyles();
  const dispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [file2, setFile2] = useState<File | null>(null);

  const onClose = () => dispatch(closeModal());

  const handleClose = () => {
    setFile(null);
    setFile2(null);
    onClose();
  };

  const handleSubmit = () => {
    const fileStation = file?.name.split("_")[4];
    if (file != null && file2 != null) {
      handleClose();
      switch (fileStation) {
        case "DON9":
          dispatch(createCheckTableWeeklyFileUpload(file, file2, api.createCheckTableByUploadFile));
          break;
        case "HYZ1":
          dispatch(createCheckTableWeeklyFileUpload(file, file2, api.createWeeklyCheckByUploadFileTwo));
          break;
      }
    }
  };

  return (
    <>
      <DialogContent
        data-testid="uploadCheckCSVFileModal"
        className={classes.modelContentDefaults}
        style={{gap: "10px"}}
      >
        <FormLabel htmlFor="btn-upload">
          <input
            data-testid="uploadCheckCSVFileInputOne"
            id="btn-upload"
            name="btn-upload"
            style={{ display: "none" }}
            type="file"
            accept=".csv"
            onChange={(e) => {
              if (e?.target?.files?.[0]) {
                setFile(e?.target?.files?.[0]);
              }
            }}
          />
          <Button
            data-testid="chooseCheckCSVButtonOne"
            fullWidth
            className="btn-choose"
            component="span"
            color="primary"
            variant="contained"
          >
            Choose First File
          </Button>
        </FormLabel>
        {file ? <FileCard file={file} onClear={() => setFile(null)} /> : null}
        <FormLabel htmlFor="btn-upload2">
          <input
            data-testid="uploadCheckCSVFileInputTwo"
            id="btn-upload2"
            name="btn-upload2"
            style={{ display: "none" }}
            type="file"
            accept=".csv"
            onChange={(e) => {
              if (e?.target?.files?.[0]) {
                setFile2(e?.target?.files?.[0]);
              }
            }}
          />
          <Button
            fullWidth
            data-testid="chooseCheckCSVButtonTwo"
            className="btn-choose"
            component="span"
            color="primary"
            variant="contained"
          >
            Choose Second File (Overview)
          </Button>
        </FormLabel>
        {file2 ? <FileCard file={file2} onClear={() => setFile2(null)} /> : null}
      </DialogContent>
      <DialogActions data-testid="uploadCheckCSVFileModalActions" className={classes.dialogActions}>
        <DialogButton
          testId={"cancelCheckCSVButton"}
          buttonName={"Cancel"}
          onClick={handleClose}
          variant="contained"
          color="secondary"
        />
        <DialogButton
          testId={"uploadCheckCSVButton"}
          buttonName={"Upload"}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={file === null || file2 === null}
        />
      </DialogActions>
    </>
  );
};

const FileCard = ({ file, onClear }: { file: File; onClear: () => void }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", my: 1 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img src={CsvIcon} height="40" alt="logo" />
        <Box sx={{ mx: 2 }}>
          <Typography>
            {ellipsis(file.name)} ({Number(file.size / 1000000).toFixed(2)} MB)
          </Typography>
        </Box>
      </Box>
      <IconButton onClick={onClear}>
        <ClearIcon />
      </IconButton>
    </Box>
  );
};
