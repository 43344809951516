import { ReducersInterfaceAction } from "types/Reducers";
import { SettingsConstants } from "../constants/constants";
import { SettingsState } from "../types/Settings";
import { initialSettingsState } from "store/initialStates";

export const settingsReducer = (
  state = initialSettingsState,
  action: ReducersInterfaceAction,
): SettingsState => {
  switch (action.type) {
    case SettingsConstants.SETTINGS_EDIT_ACTIVE_NAVIGATION_MENU:
      return {
        ...state,
        activeNavLinkList: action.payload
      };
    default:
      return state;
  }
};
