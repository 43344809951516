import { FC, useState, useEffect } from "react";
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@material-ui/core";
import api from "api";
import { customToast } from "hooks/toast";
import { useDispatch } from "react-redux";
import { useDialogStyles } from "../styles";
import { closeModal } from "actions/modalActions";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { Colors } from "constants/constants";
import { OnChangeEvent } from "types/general";
import { DialogButton } from "components/buttons/DialogButton";
import { giveAccessToOldShifts } from "actions/authAction";

interface AccessToCreateOldShiftsModalProps {}

export const AccessToCreateOldShiftsModalContent: FC<AccessToCreateOldShiftsModalProps> = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<number | null>(null);
  const [data, setData] = useState([]);
  const { isOpen } = useTypeSelectors((state) => state.modalState);
  const classes = useDialogStyles();
  const dispatch = useDispatch();

  const onClose = () => dispatch(closeModal());

  const handleGetUsersList = () => {
    setLoading(true);
    api
      .getUsersList()
      .then((res) => {
        if (res.data.results) {
          setData(res.data.results);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    if (user) {
      api
        .setUserAccess(user)
        .then((res) => {
          dispatch(closeModal());
          dispatch(giveAccessToOldShifts())
          customToast("success", `${res?.data?.ok}`);
        })
        .catch((err) => {
          console.error(err);
          customToast("error", "Server error");
        });
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setUser(null);
      setData([]);
    }
  }, [isOpen]);

  return (
    <>
      <DialogContent data-testid="testAccessToCreateOldShiftsModal">
        <DialogContentText
          id="alert-dialog-description"
          style={{
            minWidth: 300,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControl style={{ marginBottom: 20 }}>
            <InputLabel>Select user</InputLabel>
            <Select
              inputProps={{ "data-testid": "accessToCreateOldShiftsModalInput" }}
              label="Select user"
              name="user"
              value={user}
              onOpen={handleGetUsersList}
              onChange={(e: OnChangeEvent) => setUser(e.target.value as number)}
              placeholder="Choose the User"
            >
              {loading && <CircularProgress />}
              {data ? (
                data.map((el: { id: number; first_name: string; last_name: string }) => (
                  <MenuItem key={el.id} value={el.id}>
                    {el.first_name} {el.last_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key={0} value={0}></MenuItem>
              )}
            </Select>
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        className={classes.dialogActions}
        data-testid="testAccessToCreateOldShiftsModalActions"
      >
        <DialogButton
          testId={"cancelAccessToCreateOldShiftsModalButton"}
          buttonName={"Cancel"}
          onClick={onClose}
          customStyle={{ background: Colors.GRAY }}
        />
        <DialogButton
          testId={"createAccessToCreateOldShiftsModalButton"}
          buttonName={"Create"}
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={!user}
        />
      </DialogActions>
    </>
  );
};
