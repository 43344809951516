import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "./styles";
import { DaAndFleetConstants, daStatusConstants } from "constants/constants";
import { OnChangeEvent } from "types/general";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { addOrRemoveIdFromUpdateList, DriverDataRequestEdit } from "actions/daAndFleetActions";
import { DriverData } from "types/DaAndFleet";
import { StationSpot } from "types/DataTypes";
import moment from "moment";

interface DaTableRowProps {
  daData: DriverData;
}

export const DaTableRow: FC<DaTableRowProps> = ({ daData }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [statusValue, setStatusValue] = useState<number | null>(0);
  const { driverData } = useTypeSelectors((state) => state.daAndFleets);

  useEffect(() => {
    if (daData.is_active) {
      setStatusValue(1);
    }
    if (daData.is_rehired) {
      setStatusValue(2);
    }
    if (daData.is_blacklisted) {
      setStatusValue(3);
    }
    if (daData.is_inactive) {
      setStatusValue(4);
    }
  }, [daData.is_active, daData.is_rehired, daData.is_blacklisted, daData.is_inactive]);

  return (
    <TableRow data-testid="testDaRow" hover>
      <TableCell>
        <Checkbox
          key={daData?.id}
          name="blockNotify"
          checked={driverData?.idListForUpdate?.includes(daData?.id)}
          onChange={() =>
            dispatch(
              addOrRemoveIdFromUpdateList(
                DaAndFleetConstants.DA_FEET_SET_DA_ID_LIST,
                daData?.id,
                driverData,
              ),
            )
          }
        />
      </TableCell>
      <TableCell className={`${classes.tableCell}`}>
        <Typography>{daData?.id}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell}`}>
        <Typography>{`${daData?.first_name} ${daData?.last_name}`}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell}`}>
        <Typography>{daData?.transporter_id}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell}`}>
        <Typography>
          {daData?.driver_license !== "license" ? daData?.driver_license : "No Licence"}
        </Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell}`}>
        <Typography>{daData?.badge}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell}`}>
        <Typography>{daData?.email}</Typography>
      </TableCell>
      <TableCell style={{ minWidth: 60 }} className={`${classes.tableCell}`}>
        <FormControl style={{ display: "flex", alignContent: "center" }} fullWidth>
          <Select
            inputProps={{ "data-testid": "actRowStatusInput" }}
            onChange={(e: OnChangeEvent) => {
              setStatusValue(e?.target?.value);
              dispatch(
                DriverDataRequestEdit({
                  id: daData.id,
                  status: e?.target?.value,
                }),
              );
            }}
            name="status"
            value={statusValue}
            defaultValue={statusValue}
            placeholder="status"
          >
            {daStatusConstants?.map(({ value, option }: { value: number; option: string }) => (
              <MenuItem key={`action${option}`} value={value}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell className={`${classes.tableCell}`}>
        <Typography>
          {daData?.last_status_change
            ? moment(daData?.last_status_change).utcOffset(200).format("DD-MM-YYYY")
            : "---"}
        </Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell}`}>
        {daData?.station_spots?.map((item: StationSpot) => (
          <Typography>{item?.station?.title}</Typography>
        ))}
      </TableCell>
    </TableRow>
  );
};
