import { TextField, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useStyles } from "./styles";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { inputNumberValidateOnPaste } from "utils/formats";
import { OnBlurEvent } from "types/general";
import { falconEditPlan } from "actions/falconAction";
import { FC, useEffect, useState } from "react";
import moment from "moment";

export interface FalconTablePlanFieldType {
  planDate: string | number | undefined;
  planRoutes: number;
}

export const FalconTablePlanField: FC<FalconTablePlanFieldType> = ({planDate, planRoutes }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [routes, setRoutes] = useState<number>(planRoutes || 0);
  const { falconFilters } = useTypeSelectors((state) => state.falconTable);

  const editCondition = Number(falconFilters.date.week) >= +moment().format("W") && Number(falconFilters.date.year) === +moment().format("yyyy");
  
  useEffect(()=>{
    setRoutes(planRoutes)
  },[planRoutes])
  
  return (
    <>
      {falconFilters.selectType !== "month" && editCondition ? (
        <TextField
          key={`plan-${planDate}`}
          inputProps={{ style: { textAlign: "center" } }}
          className={classes.input}
          name="number_of_stops"
          value={routes}
          onChange={(e) => setRoutes(Number(e.target.value))}
          onBlur={(e: OnBlurEvent) => dispatch(falconEditPlan(planDate, routes ))}
          onPaste={inputNumberValidateOnPaste}
        />
      ):(
        <Typography>{routes}</Typography>
      )}
    </>
  );
};
