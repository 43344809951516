import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "constants/constants";

export const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDialog-paper": {
            borderRadius: "15px",
        }
    },
    iconBtn: {
        borderRadius: "5px",
    },
    title: {
        marginBottom: theme.spacing(2)
    },
    subTitle: {
        marginBottom: theme.spacing(1)
    },
    dialogTitle: {
        padding: theme.spacing(3, 3, 1),
        "& h2": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
        }
    },
    backDrop: {
        backdropFilter: "blur(3px)",
        backgroundColor:'#ffffff13'
    },
    modalIcon: {
        "& svg": {
            fontSize: 60,
        }
    },
    iconBox: {
        borderRadius: 50,
        padding: theme.spacing(1),
        margin: theme.spacing(2, 0),
        height: 70,
        width: 70,
        display: "flex",
        justifyContent: "center",
        "& .MuiAlert-message": {
            padding: theme.spacing(0),
            height: 60,
        },
    },
    button: {
        fontSize: 15,
        padding: theme.spacing(1, 3),
    },
    greenColor: {
        color: Colors.GREEN,
    },
    yellowColor: {
        color: Colors.YELLOW,
    },
    redColor: {
        color: Colors.RED,
    },
    blueColor: {
        color: Colors.BLUE,
    },
    grayColor: {
        color: "#545353",
    },
    greenBackground: {
        backgroundColor: "#cefad0",
        "&:hover": { backgroundColor: "#abf7b1" },
    },
    yellowBackground: {
        backgroundColor: "#f7f5bc",
        "&:hover": { backgroundColor: "#f7f4a3" },
    },
    redBackground: {
        backgroundColor: "#ffe3e6",
        "&:hover": { backgroundColor: "#ffcbd1" },
    },
    blueBackground: {
        backgroundColor: "#e2f1ff",
        "&:hover": { backgroundColor: "#bddfff" },
    },
    grayBackground: {
        backgroundColor: Colors.GRAY,
        "&:hover": { backgroundColor: "#dedede" },
    },
    deleteBtn: {
        background: Colors.RED,
        "&:hover": { backgroundColor: "#88000b" },
    }
}));
