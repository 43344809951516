import { useTypeSelectors } from "hooks/useTypeSelectors";
import { FC } from "react";
import { DateLabelType, FalconDateData } from "types/Falcon";
import { ModalWindow } from "components/templateComponents/modalWindow";
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import { EditFalconCommentDialog } from "components/modals/modalContent/FalconDialog";
import { useStyles } from "./styles";
import { Box, TableCell, Typography } from "@material-ui/core";
import moment from "moment";

interface PropsType {
  columnData: DateLabelType;
}

export const FalconTableHeadCell: FC<PropsType> = ({ columnData }) => {
  const classes = useStyles();
  const { falconFilters, falconData } = useTypeSelectors((state) => state.falconTable);

  const commentItem = falconData?.commentList?.find(
    (commentItem) => columnData.date === commentItem?.report_date,
  );

  return (
    <TableCell colSpan={2} className={classes.tableCell}>
      <Box className={classes.dateLabelBox}>
        <Typography style={{ fontWeight: 700 }}>
          {falconFilters.selectType === "week"
            ? `${String(columnData?.label)?.slice(0, 3)} ${getCheckDayDate(falconData?.dateList, String(columnData.label))}`
            : `Week ${columnData.date}`}
        </Typography>
        {falconFilters.selectType === "week" && (
          <ModalWindow
            modal_name={`edit-comment-${falconFilters.selectType}-${getCheckDayDate(falconData?.dateList, String(columnData.label))}-modal`}
            modalTitle={commentItem?.report_comment ? "Edit comment" : "Add comment"}
            theme={
              commentItem?.report_comment && !commentItem?.is_match
                ? "blue"
                : commentItem?.is_match === 1
                  ? "green"
                  : commentItem?.is_match === 2
                    ? "red"
                    : "gray"
            }
            icon={commentItem?.report_comment ? <Edit /> : <Add />}
            customIconClass={
              commentItem?.report_comment && !commentItem?.is_match
                ? classes.blueIcon
                : commentItem?.is_match === 1
                  ? classes.greenIcon
                  : commentItem?.is_match === 2
                    ? classes.redIcon
                    : classes.grayIcon
            }
            defaultTitleColor
            isIconButton
          >
            <EditFalconCommentDialog report_date={columnData?.date} data={commentItem} />
          </ModalWindow>
        )}
      </Box>
    </TableCell>
  );
};

const getCheckDayDate = (currentData: FalconDateData[] | null, dayName: string) => {
  const dt = currentData?.find((item: FalconDateData) => item?.day === dayName)?.date;
  if (dt) {
    return `, ${moment(dt).utc(true).format("DD.MM")}`;
  }
};
