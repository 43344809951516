import { Box, TablePagination } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { useStyles } from "./styles";
import { useEffect, useState } from "react";
import { getAllDriversData, setDaFilters } from "actions/daAndFleetActions";
import { ModalWindow } from "components/templateComponents/modalWindow";
import { ChangeDaStatusDialog } from "components/modals/modalContent/ChangeDaFleetStatusDialog";
import { SearchInput } from "components/elements/SearchInput";

export const DaHead = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchName, setSearchName] = useState<string>("");
  const [searchLicence, setSearchLicence] = useState<string>("");
  const { driverData } = useTypeSelectors((state) => state.daAndFleets);

  useEffect(() => {
    dispatch(
      getAllDriversData(
        driverData?.driverListFilters?.pageNumber + 1,
        driverData?.driverListFilters?.rowsPerPage,
        driverData?.driverListFilters?.ordering,
        searchName,
        searchLicence,
      ),
    );
  }, [
    driverData?.driverListFilters?.rowsPerPage,
    driverData?.driverListFilters?.pageNumber,
    driverData?.driverListFilters?.ordering,
    searchName,
    searchLicence,
    dispatch,
  ]);

  const handleChangePagination = (event: unknown, value: number) => {
    dispatch(setDaFilters({ pageNumber: Number(value) }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDaFilters({ rowsPerPage: Number(event.target.value) }));
  };

  const returnDriverName = (id: number) => {
    const driver = driverData?.driverList?.find((item) => item.id === id);
    return ` ${driver?.first_name} ${driver?.last_name}`;
  };

  const handleSearch = (value: string) => {
    setSearchName(value);
  };
  const handleSearchLicence = (value: string) => {
    setSearchLicence(value);
  };

  return (
    <Box className={classes.pageHead}>
      <Box className={classes.headBox}>
        <SearchInput placeholder="Driver Name" value={searchName} onChange={handleSearch} />
        <SearchInput
          placeholder="Driver Licence"
          value={searchLicence}
          onChange={handleSearchLicence}
        />
        <ModalWindow
          modal_name="da-status-change-modal"
          modalTitle="Change DA status"
          modalDescription={`Status will be changed for the following users: ${driverData?.idListForUpdate?.map((driverId) => returnDriverName(driverId))}`}
          isDisabled={!driverData?.idListForUpdate?.length}
        >
          <ChangeDaStatusDialog />
        </ModalWindow>
      </Box>
      <TablePagination
        page={driverData?.driverListFilters?.pageNumber}
        rowsPerPage={driverData?.driverListFilters?.rowsPerPage}
        count={driverData?.driverListCount}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePagination}
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
      />
    </Box>
  );
};
