import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";
import { EMPTY } from "constants/constants";
import { useStyles } from "./styles";
import { FC } from "react";
import { RABBIT_REPORTS_LIST } from "./tableColumns";
import { PerformaceSeiralNumber } from "types/Performance";

interface PropsType {
  loading: boolean;
  currentData: PerformaceSeiralNumber[];
}

export const RabbitReportsTable: FC<PropsType> = ({ loading, currentData }) => {
  const classes = useStyles();

  return (
    <Box className={classes.tableRoot}>
      {!currentData?.length || loading ? (
        <Typography variant="h1" className={classes.emptyTable}>
          {EMPTY}
        </Typography>
      ) : (
        <>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {RABBIT_REPORTS_LIST.map((el: any) => (
                    <TableCell
                      colSpan={el.colSpan}
                      className={`${classes.tableCell} ${classes.tableHeadWhiteCell}`}
                    >
                      <Typography className={classes.tableHeadTypography}>{el.label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody className={classes.tableBody}>
                {currentData
                  ?.sort((a, b) => b?.count - a?.count)
                  ?.map((el: any) => (
                    <TableRow hover>
                      <TableCell colSpan={1} className={`${classes.tableCell}`}>
                        {el.serial_number}
                      </TableCell>
                      <TableCell colSpan={1} className={`${classes.tableCell}`}>
                        {el.count}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};
