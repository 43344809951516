import { FC, SetStateAction, useEffect, useState } from "react";
import { Select, MenuItem, FormControl, InputLabel, CircularProgress } from "@material-ui/core";
import { OnChangeEvent } from "types/general";

interface AutoSelectProps {
  label: string;
  value: number | null;
  placeholder?: string;
  name?: string;
  onChange: SetStateAction<any>;
  getOptions: () => Promise<any>;
  renderOption: (val: any) => string;
  className?: string;
  filterCondition?: (el: any) => boolean;
  sortCondition?: (a: any, b: any) => number;
}

export const AutoSelect: FC<AutoSelectProps> = (props) => {
  const { getOptions } = props;

  const [localLoading, setLocalLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setLocalLoading(true);
    getOptions()
      .then((res) => {
        if (res.data) setOptions(res.data.results);
        setLocalLoading(false);
      })
      .catch(() => {
        setOptions([]);
        setLocalLoading(false);
      });
  }, [getOptions]);

  return (
    <FormControl data-testid="testAutoSelectForm" fullWidth className={props.className}>
      <InputLabel data-testid="testAutoSelectLabel">{props.label}</InputLabel>
      <Select
        id={"idAutoSelect"}
        data-testid="testAutoSelectField"
        label={props.label}
        name={props.name}
        value={props.value}
        onChange={(e: OnChangeEvent) => props.onChange(e.target.value as number)}
        placeholder={props.placeholder}
      >
        {!localLoading && (
          <MenuItem key={0} value={0}>
            No data
          </MenuItem>
        )}
        {localLoading ? (
          <CircularProgress />
        ) : options.length ? (
          options
            .sort((a,b) => (props.sortCondition ? props.sortCondition(a,b) : 0))
            .filter((el) => (props.filterCondition ? props.filterCondition(el) : true))
            .map((el: { first_name: string; id: number; last_name: string }) => (
              <MenuItem key={el.id} value={el.id}>
                {props.renderOption(el)}
              </MenuItem>
            ))
        ) : null}
      </Select>
    </FormControl>
  );
};
